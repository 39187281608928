import { useEffect, useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import { BsCollectionPlay, BsPeople } from "react-icons/bs";
import styles from './base.module.css';
import { Link } from 'react-router-dom';
import userServices from '../../Services/userServices';


const HomeOverview = ({ type }) => {

    const [totalFollowers, setTotalFollowers] = useState(0);
    const [totalPurchaseExperiences, setTotalPurchaseExperiences] = useState(0);

    useEffect(() => {
        async function fetchData() {
            try {
                const [followersResponse, purchaseExperiencesResponse] = await Promise.all([
                    userServices.fetchFollowersCount(),
                    userServices.fetchPurchasedExperiencesCount(),
                ]);
                
                // Process followers response
                if (followersResponse && followersResponse.statusCode === 200) {
                    if (followersResponse.data !== null && followersResponse.data !== "" && followersResponse.data[0].count !== null && followersResponse.data[0].count !== "") {
                        setTotalFollowers(followersResponse.data[0].count);
                    }
                } else {
                    // Handle error or empty response
                    setTotalFollowers(0);
                }

                // Process total purchase experiences response
                if (purchaseExperiencesResponse && purchaseExperiencesResponse.statusCode === 200) {
                    if (purchaseExperiencesResponse.data !== null && purchaseExperiencesResponse.data !== "" && purchaseExperiencesResponse.data[0].count !== null && followersResponse.data[0].count !== "") {
                        setTotalPurchaseExperiences(purchaseExperiencesResponse.data[0].count); // Assuming purchaseExperiencesResponse contains the count directly
                    }
                } else {
                    // Handle error or empty response
                    setTotalPurchaseExperiences(0);
                }
            } catch (error) {
                console.error("API Error:", error);
                // Handle error
                setTotalFollowers(0);
                setTotalPurchaseExperiences(0);
            }
        }

        fetchData();
    }, []);


  return (
    <Container className='noPadding'>
    <Row className={`boxBody`}>

       <Row className={`mt-1 ${styles.ActionHeaderdiv}`}>
            <h5 className={styles.ActionHeader}>Actions</h5>
        </Row>

        <Row className={styles.HomeActionsBox}>
            <Row className={`mb-2 ${styles.ActionClickRows}`}>
                <Link to="/engage/dashboard" className={`btn d-flex align-items-center justify-content-between ${styles.ActionClickBtns}`}>
                    <div className={`d-flex align-items-center`}>
                        <BsCollectionPlay />&nbsp; Purchased Workshops &nbsp;
                    </div>
                    <div className={styles.ActionClickBadgeRow}>
                        <span className={styles.ActionClickBadge}>{totalPurchaseExperiences}</span>
                    </div>
                </Link>
            </Row>
            
            {/* <Row className={`mb-2 ${styles.ActionClickRows} ${styles.activeAction}`}>
                <Link to="/seller-experiences/create-experience" className={`btn d-flex align-items-center justify-content-between ${styles.ActionClickBtns}`}>
                    <div className={`d-flex align-items-center ${styles.ActionClickTextActive}`}>
                        <BsBook />&nbsp; Create Workshop &nbsp;
                    </div>
                    <div className={styles.ActionClickBadgeRow}>
                        <span className={styles.ActionClickBadge}>New</span>
                    </div>
                </Link>
            </Row> */}

            <Row>
            {type === "followers" ? (
                <Link to="/followers">
                    <Row className={`mb-2 ${styles.ActionClickRows} ${styles.activeAction}`}>
                        <button className={`btn d-flex align-items-center justify-content-between ${styles.ActionClickBtns}`}>
                            <div className={`d-flex align-items-center ${styles.ActionClickTextActive}`}>
                                <BsPeople />&nbsp; Followers
                            </div>
                            <div className={styles.ActionClickBadgeRow}>
                                <span className={styles.ActionClickBadge}>{totalFollowers}</span>
                            </div>
                        </button>
                    </Row>
                </Link>
            ) : (
                <Link to="/followers">
                    <Row className={`mb-2 ${styles.ActionClickRows}`}>
                        <button className={`btn d-flex align-items-center justify-content-between ${styles.ActionClickBtns}`}>
                            <div className="d-flex align-items-center">
                                <BsPeople />&nbsp; Followers
                            </div>
                            <div className={styles.ActionClickBadgeRow}>
                                <span className={styles.ActionClickBadge}>{totalFollowers}</span>
                            </div>
                        </button>
                    </Row>
                </Link>
            )}
            </Row>

            <Row>
            {(type === "refferal-board" || type === "activity-board") ? (
                <Link to="/crp/refferal-board">
                    <Row className={`mb-2 ${styles.ActionClickRows} ${styles.activeAction}`}>
                        <button className={`btn ${styles.ActionClickBtns}`}>
                            <label className={styles.ActionClickTextActive}>
                                Community Referral Program
                            </label>
                        </button>
                    </Row>
                </Link>
            ) : (
                <Link to="/crp/refferal-board">
                    <Row className={`mb-2 ${styles.ActionClickRows}`}>
                        <button className={`btn ${styles.ActionClickBtns}`}>
                            <label>
                                Community Referral Program
                            </label>
                        </button>
                    </Row>
                </Link>
            )}
            </Row>

        </Row>
    </Row>
    </Container>
  );
};

export default HomeOverview;
