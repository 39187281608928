import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import config from '../../../config/config';
// import io from 'socket.io-client';
import ReactPlayer from 'react-player';
import { Spinner, Modal, Button } from 'react-bootstrap';
import styles from './watchParty.module.css';
import {
  BsFillSkipStartFill, BsFillSkipEndFill, BsPauseFill, BsPlayFill, BsHandIndexThumbFill
} from "react-icons/bs";
import {
  FiMic, FiMicOff, FiVideo, FiVideoOff, FiLogOut, FiMinimize,
  FiMaximize, FiMoreVertical, FiUsers, FiMenu, FiVolumeX
} from "react-icons/fi";
import hubServices from '../../../Services/hubServices';

// const socket = io(config.apiUrl);

const ViewWatchParty = () => {

  const { invitationId } = useParams();
  const [invitationDetails, setInvitationDetails] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  // const [videoTitle, setVideoTitle] = useState("Sample Video Title");
  // const [videoType, setVideoType] = useState("Movie");
  // const [membersJoined, setMembersJoined] = useState(5);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showOptionsPopup, setShowOptionsPopup] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isHandRaised, setIsHandRaised] = useState(false);
  const playerRef = useRef();
  const containerRef = useRef();

  // useEffect(() => {
  //   socket.emit('join-watch-party');

  //   socket.on('video-state', ({ isPlaying, currentTime }) => {
  //     setIsPlaying(isPlaying);
  //     playerRef.current.seekTo(currentTime, 'seconds');
  //     setIsLoading(false);
  //   });

  //   socket.on('sync-time', (currentTime) => {
  //     playerRef.current.seekTo(currentTime, 'seconds');
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    if (invitationId) {
      async function fetchInvitationDetailsbyId() {
        try {
          
          const response = await hubServices.fetchInvitationDetailsById(invitationId);
          if (response.statusCode === 200) {
            setInvitationDetails(response.data);
          }
        } catch (error) {
          console.error("Error fetching workshops:", error);
        }
      }
      fetchInvitationDetailsbyId();
    }
  }, [invitationId]);


  const handlePlayPause = () => {
    const newPlayState = !isPlaying;
    setIsPlaying(newPlayState);
    // socket.emit('video-state', {
    //   isPlaying: newPlayState,
    //   currentTime: playerRef.current.getCurrentTime(),
    // });
  };

  const handleMute = () => {
    setIsMuted(!isMuted);
  };

  const handleVideoToggle = () => {
    setIsVideoOn(!isVideoOn);
  };

  const handleVolumeChange = (e) => {
    setVolume(parseFloat(e.target.value));
  };

  const handleForward = () => {
    const current = playerRef.current.getCurrentTime();
    playerRef.current.seekTo(current + 10, 'seconds');
  };

  const handleBackward = () => {
    const current = playerRef.current.getCurrentTime();
    playerRef.current.seekTo(Math.max(current - 10, 0), 'seconds');
  };

  const toggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    } else if (containerRef.current) {
      containerRef.current.requestFullscreen();
      setIsFullscreen(true);
    }
  };

  const handleShowOptionsPopup = (e) => {
    e.stopPropagation();
    setShowOptionsPopup(!showOptionsPopup);
  };

  const handleLogout = (e) => {
    e.stopPropagation();
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    setShowLogoutModal(false);
    // Add logout logic here
  };

  const toggleHandRaise = () => {
    setIsHandRaised(!isHandRaised);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleTimeChange = (e) => {
    const newTime = parseFloat(e.target.value);
    setCurrentTime(newTime);
    playerRef.current.seekTo(newTime, 'seconds');
  };


  return (
    <div
      ref={containerRef}
      className={styles.viewWatchParty}
      onClick={(e) => e.stopPropagation()} // Prevent pausing video when clicking inside container
      onDoubleClick={toggleFullscreen}
    >
      <div className={styles.topInfo}>
        <div className={styles.videoTitle}>{invitationDetails.title}</div>
        <div className={styles.videoType}>Pre-recorded</div>
        {/* <div className={styles.membersJoined}>Members: {invitationDetails.membersJoined}</div> */}
      </div>

      {isLoading && (
        <div className={styles.loadingOverlay}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      <ReactPlayer
        ref={playerRef}
        url={invitationDetails.cover}
        playing={isPlaying}
        muted={isMuted}
        volume={volume}
        onProgress={({ playedSeconds }) => setCurrentTime(playedSeconds)}
        onDuration={setDuration}
        onReady={() => setIsLoading(false)}
        controls={false}
        width="100%"
        height="100%"
        className={styles.videoPlayer}
      />

      <div
        className={`${styles.controls} d-flex justify-content-between`}
        onClick={(e) => e.stopPropagation()} // Prevent pausing video when interacting with controls
      >
          <div className="d-flex">
          {/* Audio Mute/Unmute */}
          <div className={styles.controlsBackground}>
            <button className={styles.button1} style={{background: '#2E637D'}} onClick={handleMute} >{isMuted ? <FiMicOff /> : <FiMic />}</button>
            <button className={styles.button2} style={{background: '#2E637D'}} onClick={handleShowOptionsPopup} ><FiMoreVertical /></button>
          </div>

          {/* Video On/Off */}
          <div className={styles.controlsBackground}>
            <button className={styles.button1} onClick={handleVideoToggle} >{isVideoOn ? <FiVideo /> : <FiVideoOff />}</button>
            <button className={styles.button2} onClick={handleShowOptionsPopup} ><FiMoreVertical /></button>
          </div>
          
          <div className={styles.controlsBackground}>
            <button className={styles.buttonborderNone} onClick={handleBackward}><BsFillSkipStartFill /></button>
            <button className={styles.buttonborderNone} onClick={handlePlayPause}>{isPlaying ? <BsPauseFill /> : <BsPlayFill />}</button>
            <button className={styles.buttonborderNone} onClick={handleForward}><BsFillSkipEndFill /></button>
          </div>

          <input type="range" min="0" max="1" step="0.1" value={volume} onChange={handleVolumeChange} />

          <div className={styles.controlsBackground}>
            {/* Raise Hand */}
            <button onClick={toggleHandRaise} className={`${styles.buttonborderNone} ${isHandRaised ? styles.handRaised : ''}`}>
              <BsHandIndexThumbFill  />
            </button>
            <button className={styles.buttonborderNone} onClick={() => setIsMuted(!isMuted)}><FiVolumeX /></button>
          </div>

          <div className={styles.controlsBackground}>
            <button className={styles.button1} style={{background: '#C74E5B'}} onClick={handleLogout} ><FiLogOut /></button>
            <button className={styles.button2} style={{background: '#C74E5B'}} onClick={handleShowOptionsPopup} ><FiMoreVertical /></button>
          </div>

        </div>

        <div className="d-flex align-items-center">
          {/* Start Time */}
          <span className={styles.timeDisplay} onClick={() => playerRef.current.seekTo(0, 'seconds')}>0:00</span>

          {/* Minute Control Slider */}
          <input
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            onChange={handleTimeChange}
            className={styles.timeSlider}
          />

          {/* End Time */}
          <span className={styles.timeDisplay} onClick={() => playerRef.current.seekTo(duration, 'seconds')}>
            {formatTime(duration)}
          </span>
        </div>

        <div className="d-flex">
          <div className={styles.controlsBackground}>
            <button onClick={toggleFullscreen} className={styles.buttonborderNone} >{isFullscreen ? <FiMinimize /> : <FiMaximize />}</button>
          </div>
          <div className={styles.controlsBackground}>
            <button className={styles.buttonborderNone} ><FiUsers /> &nbsp; {invitationDetails.membersJoined}</button>
          </div>
          <div className={styles.controlsBackground}>
            <button className={styles.buttonborderNone} ><FiMenu /></button>
          </div>
        </div>
      </div>

      {/* Options Popup */}
      {showOptionsPopup && (
        <div className={styles.optionsPopup}>
          <button onClick={() => setShowOptionsPopup(false)}>Option 1</button>
          <button onClick={() => setShowOptionsPopup(false)}>Option 2</button>
        </div>
      )}

      {/* Logout Modal */}
      <Modal show={showLogoutModal} onHide={() => setShowLogoutModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to leave the watch party?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewWatchParty;
