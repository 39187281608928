import React, { useState, useEffect } from 'react';
import MessagingServices from '../../Services/MessagingServices';
import { Image, Button, Row, Col, Spinner } from 'react-bootstrap';
import baseStyles from './Chat.module.css';
import { Link } from 'react-router-dom';


const SearchUserstoChat = ({ chatwithUser, handleTabClick }) => {

  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userProfileData, setUserProfileData] = useState(null);

    useEffect(() => {
      // Retrieve data from localStorage
      const profileData = localStorage.getItem('userProfileData');
      if (profileData) {
        const parsedData = JSON.parse(profileData);
        setUserProfileData(parsedData);
      }
    }, []);
    
  const handleSearchInputChange = async (e) => {      
    let searchValue = e.target.value;
    
    setSearchResults([]);
    setErrorMessage("");
    setIsLoading(false);

    if (searchValue && searchValue.trim().length > 2) {
      setSearchValue(searchValue);
      // Call the API here
    } else {
      // Do not call the API, return or handle the invalid input as needed
      setSearchValue(searchValue);
      return;
    }
    
    try {
      setIsLoading(true);
      const response = await MessagingServices.searchMessagingUsers(searchValue, "message");
      if (response.statusCode === 200) {
        if(response.data !== null && response.data.length > 0 ){
          setSearchResults(response.data);
        } else {
          setSearchResults([]);
          setErrorMessage("No Users found with given search");
        }
      } else {
        setSearchResults([]);
        setErrorMessage(response.message || "An error occurred while searching for users. Please try again.");
      }

    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

    const handleJoinChannel = async (sellerId) => {
            setErrorMessage("")
            try {
              setIsLoading(true);
              const response = await MessagingServices.fetchRoomIdtoChat(sellerId);
              // console.log("API Response:", response);
              if (response.statusCode === 200) {
                if(response.message !== null && response.message !== undefined && 
                  response.message.channel_url !== null && response.message.channel_url !== undefined){
                    setTimeout(() => {
                      chatwithUser(response.message.channel_url);
                    }, 2000);
                  } else {
                    setErrorMessage(response.message || 'An error occurred during the chat. Please try again after some time.');
                  }
              } else {
                setErrorMessage(response.message || 'An error occurred during the chat. Please try again after some time.');
              }

            } catch (error) {
              console.error("API Error:", error);
            }  finally {
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
            }
    };

  
  return (
    <div className="search-box">

        {userProfileData ? (
          <div className={`mb-3 ${baseStyles.MessagingContainerCombo}`}>
            <Row className={`d-flex align-items-center justify-content-start gap-2`}>
              <Col xs="auto">
                <Link to='/profile/update_profile_picture'>
                  <Image src={userProfileData.imageUrl ? userProfileData.imageUrl : '/Content/Images/empty_user.jpg'}
                  alt="Profile" roundedCircle className={`roundedUser profileImage`}
                  />
                </Link>
              </Col>
              <Col xs="auto">
                  <h3 className="profileMessageText">Messaging </h3>
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                <Button
                  className={`${baseStyles.createGroupButton}`}
                  onClick={() => handleTabClick('create-group')}
                >
                  Create Group
                </Button>
                </Col>
            </Row>
          </div>
        ) : (
          <Row className={`d-flex gap-2 align-items-center justify-content-start mb-3`}>
              <Col xs="auto">
              <Image src='/Content/Images/empty_user.jpg'
                  alt="Profile" roundedCircle className={`roundedUser profileImage`}
              />
              </Col>
              <Col xs="auto">
                  <h3 className="profileMessageText">Messaging</h3>
              </Col>
          </Row>
        )}

      <input
        type="text"
        value={searchValue}
        placeholder="Search User..."
        onChange={handleSearchInputChange}
        className="search-text-box"
      />
      <i className="fas fa-search"></i>
      
      {isLoading && <div className="search-dropdown text-center" ><Spinner animation="grow" className='text-center' /></div>}
      
        {searchResults.length > 0 ? (
          <div className="search-dropdown">
              {searchResults.map((user, index) => (
                  <ul className="list-group">
      
                      <li key={index} className={`list-group-item search-item ${baseStyles.MsglistItem}`} onClick={() => { handleJoinChannel(user._id) }} >

                          <div className="d-flex align-items-center justify-content-beteen mb-2 mt-2">
                          <Image
                            src={user.image_url ? user.image_url : '/Content/Images/empty_user.jpg'}
                            roundedCircle alt="Profile" className={`roundedUser mr-3 ${baseStyles.MsgUserIcon}`}
                            />
                              &nbsp;&nbsp;
                          <div className="ml-3 p-1 d-flex flex-grow-1 g-2 align-items-center justify-content-between">
                              <div>
                                  <h6 className={baseStyles.liveUsername}>{user.name.split(' ')[0]}</h6>
                                  <p className={`liveUsercontent`}>{user.name}</p>
                              </div>
                              <div>
                                  {(user.isFollowing)
                                      ? (
                                      <Button variant="outline-primary"
                                          className={`ml-auto feedfollowBtn`}
                                          // onClick={() => { FollowFeedUser(user.fId) }}
                                          >
                                          Following
                                      </Button>
                                      ) : (
                                      <Button variant="outline-primary"
                                          className={`ml-auto feedfollowBtn`}
                                          // onClick={() => { FollowFeedUser(user.fId) }}
                                          >
                                          Follow
                                      </Button>
                                      )}
                              </div>
                          </div>
                          </div>

                      </li>
                  </ul>
                  
              ))}
          </div>
        ) : (
          <div>
            {errorMessage && (
              <div className="search-dropdown text-center">
                  <label className={baseStyles.nousersfoundfont}>{errorMessage}</label>
              </div>
            )}
          </div>
        )}

    </div>
  );
}

export default SearchUserstoChat;
