// import AuthHeader from './Auth/AuthHeader';
import AuthFullBody from './Auth/AuthFullBody';
import AuthFooter from './Auth/AuthFooter';


const AuthPage = () => {
  
    return (
      <>
        {/* <header>
          <AuthHeader />
        </header> */}
        <main>
          <AuthFullBody />
        </main>
        <footer>
          <AuthFooter />
        </footer>
      </>
  );
}

export default AuthPage;
