// import { useRef, useState } from 'react';
import { Container, Row, Col, Button, Image, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import authStyles from './auth.module.css';

const AuthFullBody = () => {

    
  return (
    <Container fluid className={authStyles.authFullBodyContainer}>

    <Navbar className={`${authStyles.authFullHeader}`} expand="lg" fixed="top" collapseOnSelect>
      <Container fluid>
        <Row className="w-100 align-items-center">
          <Col xs="6" lg="auto" className="d-flex justify-content-start">
            <Navbar.Brand href="/" className="p-0 m-0">
              <img src="/Content/Images/Svgs/header_logo.svg" alt="Logo" className={authStyles.authMainLogo} />
            </Navbar.Brand>
          </Col>
          <Col xs="6" className="d-flex justify-content-end d-lg-none">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </Col>
          <Col lg="auto" className="d-none d-lg-flex justify-content-end ms-auto">
            <Nav className={`ms-auto ${authStyles.HeaderNavLinks}`}>
              <Link to="/info/about-us">
                <Button variant="white" size="md" className={authStyles.authMainHeaderrowBtns}>
                  About Us
                </Button>
              </Link>
              <Link to="/signup/mandatory-details">
                <Button variant="white" size="md" className={authStyles.authMainHeaderrowBtns}>
                  Sign Up
                </Button>
              </Link>
              <Link to="/signin/form">
                <Button variant="white" size="md" className={authStyles.authMainHeaderrowBtns}>
                  Sign In
                </Button>
              </Link>
            </Nav>
          </Col>
        </Row>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={`ms-auto d-lg-none ${authStyles.HeaderNavLinks}`}>
            <Link to="/info/about-us">
                <Button variant="white" size="md" className={authStyles.authMainHeaderrowBtns}>
                  About Us
                </Button>
              </Link>
            <Link to="/signup/mandatory-details">
              <Button variant="white" size="md" className={authStyles.authMainHeaderrowBtns}>
                Sign Up
              </Button>
            </Link>
            <Link to="/signin/form">
              <Button variant="white" size="md" className={authStyles.authMainHeaderrowBtns}>
                Sign In
              </Button>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

      <Row className={`${authStyles.AuthBannerSetup}`}>
        <Row className={`${authStyles.authMainBaseRow}`}>
          <Col xs={12} md={5} className="mainPad">
            <Row className={authStyles.ContentRow}>
              <h2 className={`mb-3 ${authStyles.authBiglabel}`}>To <span className={authStyles.authHapifyrYellowLabel}>IGNITE HAPPINESS</span> in others you must first find what makes you happy</h2>
              <p className={`mb-5 ${authStyles.authContentLabel}`}>
                <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span> is a creative platform and a thriving community, where people connect and find joy through shared creative workshops.
              </p>
            </Row>
            <Row className={`d-flex ResponsiveCenteredAlignedButtons`}>
              <Col xs="auto">
                <Link to="/signup/mandatory-details">
                  <Button className={authStyles.authMainButtons}>Join Hapifyr</Button>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={7}>
              <img src="/Content/Images/Auth/authMain.PNG" alt="Banner" className={authStyles.authMainBanner} />
          </Col>
        </Row>
        <br />

        <Row className={`mt-2`}>
          <Col xs={12} md={6} lg={6} className={`mainPad`}>
            <div className={authStyles.authMainBanner2Row}>
              <img src="/Content/Images/Auth/authMain2.PNG" alt="Banner" className={authStyles.authMainBanner2} />
            </div>
          </Col>
          <Col xs={12} md={5} lg={5} className={authStyles.authmain2Pad}>
            <Row className={authStyles.ContentRow}>
              <h2 className={`mb-3 ${authStyles.authBig2label}`}>Ignite Your Creativity, Find Your <span className={authStyles.authHapifyrGrayLabel}>Community</span></h2>
              <p className={`mb-5 ${authStyles.authContent2Label}`}>
                In a fast-moving world, finding time to indulge your creativity can be a challenge.
                That's why we created <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span>; a welcoming, 
                inclusive place where people from all walks of life can come together through their hobbies, from anywhere, anytime.
                <br /><br /> 
                At <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span> you'll find:
                <br />

                  <ul>
                    <li>happiness is contagious, </li>
                    <li>you can enjoy your passions, explore new ones, and </li>
                    <li>boast about it (it's encouraged!) </li>
                  </ul>

              </p>
            </Row>
          </Col>
          <Col xs={0} md={1} lg={1}></Col>
        </Row>
        <br />

        <Row className={`mainPad`}>
            <Row className={authStyles.ContentRow}>
              <Col xs={12} md={4} lg={4} className={authStyles.authMiddleContentRow}>
                <div className={authStyles.authBanner3ImgDiv}>
                  <Image src="/Content/Images/Auth/Auth2/mini-3-1.jpg" alt="Banner3-1" 
                  roundedCircle
                  className={authStyles.authMiniBanner31} />
                </div>
                <h2 className={`mb-3 ${authStyles.authBigLabel3}`}>Your Community</h2>
                <p className={`mb-5 ${authStyles.authContentLabel3}`}>
                  In your community you’ll find a warm embrace of diverse individuals brought together through their shared passions. Here, friendships blossom, and you feel that you belong.             
                </p>
              </Col>

              <Col xs={12} md={4} lg={4} className={authStyles.authMiddleContentRow}>
                <div className={authStyles.authBanner3ImgDiv}>
                  <Image src="/Content/Images/Auth/Auth2/mini-3-2.png" alt="Banner3-2" 
                  roundedCircle
                  className={authStyles.authMiniBanner31} />
                </div>
                <h2 className={`mb-3 ${authStyles.authBigLabel3}`}>Your Workshops</h2>
                <p className={`mb-5 ${authStyles.authContentLabel3}`}>
                  The <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span> community offer interactive workshops to ignite your creative passions, in art, cooking, music, and more. Be guided by friendly expertise and infectious enthusiasm.
                </p>
              </Col>

              <Col xs={12} md={4} lg={4} className={authStyles.authMiddleContentRow}>
                <div className={authStyles.authBanner3ImgDiv}>
                  <Image src="/Content/Images/Auth/Auth2/mini-3-3.jpg" alt="Banner3-3" 
                  roundedCircle
                  className={authStyles.authMiniBanner31}
                  />
                </div>
                <h2 className={`mb-3 ${authStyles.authBigLabel3}`}>Your Connections</h2>
                <p className={`mb-5 ${authStyles.authContentLabel3}`}>
                  Shared experiences foster deeper connections; so invite your friends, and enjoy a workshop together in a private watch party setting where you can create with your people.  
                </p>
              </Col>
            </Row>
            
            <Row className={`text-center align-items-center justify-content-between ResponsiveCenteredAlignedButtons`}>
                <Link to="/signup/mandatory-details">
                  <Button className={authStyles.authMainButtons}>Join Hapifyr</Button>
                </Link>
            </Row>
        </Row>
        <br />

        <Row className={`mt-2`}>
          <div className={`mb-4 mainPad`}>
            <h2 className={`${authStyles.authBig2label}`}>Join the <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span> community</h2>
            <p className={`${authStyles.authContentLabel4}`} >At hapifyr we are on a mission to create a community who connect through shared creative interests. Join us, and invite your friends!
            <br /><br />
            We're different from other social media platforms, we're building a social community, who unite through creativity and fun.
            </p>
          </div>

          <Col xs={12} md={5} lg={5} className={`mb-3 mainPad`}>
            <Row className={`align-items-center justify-content-center ${authStyles.authMainBanner4Row}`}>
              <Col xs={6} md={6} lg={6} className={`text-end ${authStyles.authMiniBanner4Cols}`}>
                <img src="/Content/Images/Auth/Auth2/mini-4-1.jpg" alt="Banner4-1" className={authStyles.authMiniBanner4_1Image} />
                <img src="/Content/Images/Auth/Auth2/mini-4-2.jpg" alt="Banner4-2" className={authStyles.authMiniBanner4_1Image} />
              </Col>
              <Col xs={6} md={6} lg={6} className={`text-start ${authStyles.authMiniBanner4Cols}`}>
                <img src="/Content/Images/Auth/Auth2/mini-4-3.jpeg" alt="Banner4-3" className={authStyles.authMiniBanner4_2Image} />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} lg={6} className={authStyles.authmain2Pad}>
            <Row className="d-flex">
                <label className={`${authStyles.authBigLabel4}`}>Create your profile </label>
                <p className={`${authStyles.authContentLabel4}`} >Join <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span>, login and personalise your profile. Your profile is your canvas – let it reflect who you are. We'll keep your personal info safe, and we won't sell it on to advertisers.</p>
            </Row>

            <Row className="d-flex">
                <label className={`${authStyles.authBigLabel4}`}>Explore creations from our community </label>
                <p className={`${authStyles.authContentLabel4}`}>Once inside, explore workshops prepared by our hosts, and discover posts from our community about the workshops they've enjoyed on <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span>. Once you find something you like; buy it, and watch it, all in the <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span> platform. </p>
            </Row>

            <Row className="d-flex">
                <label className={`${authStyles.authBigLabel4}`}>Celebrate your creation </label>
                <p className={`${authStyles.authContentLabel4}`}>Create a <span className={authStyles.authHapifyrGrayLabel}>'boast'</span>. Yes, our community love to see photos of each others creations. So workshop participants are encouraged to take photos of their creations and write a review after they've finished the workshop. These <span className={authStyles.authHapifyrGrayLabel}>'boast'</span> appear in the feed for others to enjoy.</p>
            </Row>

            <Row className="d-flex">
                <label className={`${authStyles.authBigLabel4}`}>Start hosting </label>
                <p className={`${authStyles.authContentLabel4}`}>Why not give hosting a try? Our hosts are everyday people with a creative process to share. Discover our step-by-step tool for uploading pre-recorded videos to create an online workshop. Upload one video, or piece together a sequence of shorter videos, and publish it in the online store. Workshops for groups and other features will follow in later releases.</p>
            </Row>

            <Row className="d-flex">
                <label className={`${authStyles.authBigLabel4}`}>Invite your <span className={authStyles.authHapifyrGrayLabel}>community</span> </label>
                <p className={`${authStyles.authContentLabel4}`}>Once you've joined <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span>, you'll find a unique link in our community referral program so you can invite your network and spread the word, encouraging others to ignite happiness at <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span>.</p>
            </Row>

          </Col>
          <Col xs={0} md={1} lg={1}></Col>
        </Row>
        <br />

        <Row className={`mt-2 mainPad`}>
          <Col xs={12} md={5} lg={5}>
            <Row className={authStyles.ContentRow}>
              <h2 className={`mb-3 ${authStyles.authBig2label}`}>Become a host</h2>
              <p className={`mb-5 ${authStyles.authContent2Label}`}>
                Our hosts are everyday people with a creative process to share. 
                <br />
                Our step-by-step tool makes it easy to upload a pre-recorded video to create an online workshop. Upload one video, or piece together a sequence of shorter videos, and publish it in the online store. 
                <br />
                Workshops for groups and other features will follow in later releases.
                <br />
              </p>
            </Row>
          </Col>
          <Col xs={0} md={1} lg={1}></Col>
          <Col xs={12} md={6} lg={6} className={authStyles.authmain2Pad}>
              <img src="/Content/Images/Auth/Auth2/mini-4DD.jpeg" alt="Banner4" className={authStyles.authMainBanner4DD} />
          </Col>
          {/* <Col xs={0} md={1} lg={1}></Col> */}
        </Row>
        <br />

        <Row className={`mt-2`}>
          <Col xs={12} md={6} lg={6} className={`minPadding ${authStyles.authmain2Pad}`}>
              <img src="/Content/Images/Auth/Auth2/Collage5.jpg" alt="Banner5" className={authStyles.authMainBanner5} />
          </Col>
          <Col xs={12} md={5} lg={5} className={authStyles.authmain2Pad}>
            <Row className={authStyles.ContentRow}>
              <h2 className={`mb-3 ${authStyles.authBig2label}`}>Invite your <span className={authStyles.authHapifyrGrayLabel}>community</span>,  get rewarded</h2>
              <p className={`mb-5 ${authStyles.authContent2Label}`}>
                Once you've joined <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span>, explore our community referral program where you'll find details
                of how the referral rewards work, including a unique link so you can invite your network, encouraging others to ignite happiness
                at <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span>. 
              </p>
            </Row>
          </Col>
          <Col xs={0} md={1} lg={1}></Col>
        </Row>
        <br />

        <Row className={`mt-3 mb-1 mainPad`}>
          <div>
            <hr />
            <p className={`mb-4 ${authStyles.authContentTextLabel7}`} >Welcome to <span className={authStyles.authHapifyrYellowLabel}>hapifyr</span>
            <br />
            where happiness sparks connections</p>
          </div>
        </Row>

      </Row>
    
    </Container>
    
  );
};

export default AuthFullBody;
