import { Row, Col } from 'react-bootstrap';
import UserProfile from '../BaseComponents/UserProfile';
import SendBird from '../SendBird';
import EngageActions from '../BaseComponents/EngageActions';
import HubOverview from './HubOverview';
import PastWorkshops from './PastWorkshops';
import UpcomingWorkshops from './UpcomingWorkshops';
import InvitationsDashboard from './InvitationsDashboard';
import styles from './hub.module.css';


const HubDashboardDetails = ({ type }) => {


  return (
      <main className="MainpartSetup HomeViewportHt">
        <Row className="mainPad stickySidebar">
          <Col xs={12} lg={3} className={`stickySidebarOverview ${styles.responsiveDisplay}`}>
            <UserProfile />
            <br />
            <EngageActions type={type} />
          </Col>
          
          <Col xs={12} lg={6} >

            {type === 'dashboard' && <HubOverview />}
            {type === 'past-workshops' && <PastWorkshops />}
            {type === 'upcoming-workshops' && <UpcomingWorkshops />}
            {type === 'invitations' && <InvitationsDashboard />}

          </Col>
          
          <Col xs={12} lg={3} className={`${styles.responsiveDisplay}`}>
            {type === 'invitations' ? (
              <SendBird boardType='invitations' />
            ) : (
              <SendBird />
            )}
          </Col>
        </Row>
      </main>

  );
}

export default HubDashboardDetails;
