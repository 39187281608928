import { useState, useEffect, useContext, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import inviteStyles from './invite.module.css';
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import SearchAttendees from './SearchAttendees';
import AuthContext from '../../Context/AuthContext';
import hubServices from '../../../Services/hubServices';

const labelStyle = "original";
const timezones = {
  ...allTimezones,
  "Europe/Berlin": "Frankfurt",
};

const NewInvitation = () => {
  
  const navigate = useNavigate();
  const { setIsLoading } = useContext(AuthContext);
  const [groupWatchWorkshopsList, setGroupWatchWorkshopsList] = useState([]);
  const [workshopPartyCaption, setWorkshopPartyCaption] = useState('');
  const [workshopPartyId, setWorkshopPartyId] = useState('');
  const [partyStartDate, setPartyStartDate] = useState('');
  const [partyEndDate, setPartyEndDate] = useState('');
  const [partyStartTime, setPartyStartTime] = useState('');
  const [partyEndTime, setPartyEndTime] = useState('');
  const [attendeesList, setAttendeesList] = useState([]); // Initialize as an empty array
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});
  const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones });
  const [timezone, setTimezone] = useState(parseTimezone("Australia/Melbourne"));
  const location = useLocation();
  const { workshopId, workshopCaption, partyType } = location.state || {};

  useEffect(() => {
    if (partyType === 'all') {
      async function fetchGroupWatchWorkshops() {
        try {
          const response = await hubServices.fetchGroupWatchWorkshopsList();
          if (response.statusCode === 200) {
            setGroupWatchWorkshopsList(response.data);
          }
        } catch (error) {
          console.error("Error fetching workshops:", error);
        }
      }
      fetchGroupWatchWorkshops();
    } else {
      if (workshopId) setWorkshopPartyId(workshopId);
      if (workshopCaption) setWorkshopPartyCaption(workshopCaption);
    }
  }, [partyType, workshopId, workshopCaption]);
  

  const handleInterestChange = (e) => {
    const selectedId = e.target.value;
    setWorkshopPartyId(selectedId);
  
    // Find the selected workshop by feedId to get the caption
    const selectedWorkshop = groupWatchWorkshopsList.find(workshop => workshop.feedId === selectedId);
    if (selectedWorkshop) {
      setWorkshopPartyCaption(selectedWorkshop.caption);
    }
  };
  
  const handleTimezoneChange = (value) => {
    const parsedTimezone = parseTimezone(value);
    setTimezone(parsedTimezone);
  };

  const handleUserChange = useCallback((user) => {
    setAttendeesList((prevList) => 
      prevList.includes(user) ? prevList : [...prevList, user]
    );
  }, []);

  const validateForm = () => {
    const newErrors = {};
    const startDateTime = new Date(`${partyStartDate}T${partyStartTime}`);
    const endDateTime = new Date(`${partyEndDate}T${partyEndTime}`);

    if (!workshopPartyId) newErrors.workshopPartyCaption = "Please provide a workshop ID.";
    if (!workshopPartyCaption) newErrors.workshopPartyCaption = "Please provide the title of the party.";
    if (!partyStartDate) newErrors.partyStartDate = "Please select a start date.";
    if (!partyStartTime) newErrors.partyStartTime = "Please select a start time.";
    if (!partyEndDate) newErrors.partyEndDate = "Please select an end date.";
    if (!partyEndTime) newErrors.partyEndTime = "Please select an end time.";
    if (!timezone) newErrors.timezone = "Please select a timezone.";
    if (attendeesList.length === 0) newErrors.attendeesList = "Please add at least one attendee.";
    if (startDateTime >= endDateTime) newErrors.dateTime = "End date and time must be after start date and time.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const createNewInvitation = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (!validateForm()) return;

    try {
      setIsLoading(true);
      const response = await hubServices.createNewWorkshopParty(workshopPartyId, workshopPartyCaption, partyStartDate, partyStartTime, partyEndDate, partyEndTime, timezone, attendeesList);

      if (response?.statusCode === 200) {
        alert("Invitation created successfully!");
        navigate(`/experiencehub/experience/${workshopPartyId}`);
      } else {
        setErrorMessage(response?.message || "Error creating invitation.");
      }
    } catch (error) {
      console.error("API Error:", error);
      setErrorMessage("An error occurred while creating the invitation.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    navigate(-1); // Go back to the previous page
  };


  return (
    <Container fluid className="noPadding">
      <Row className={inviteStyles.newInvitationsBackdrop}>
        <Col xs={4} md={4} lg={4}></Col>

        <Col xs={4} md={4} lg={4}>
          <Row className={inviteStyles.newInvitationsBoxsetup}>
            
            <div className={inviteStyles.SchedulingHeaderDiv}>
              <h2 className={inviteStyles.SchedulingHeader}>Scheduling Details*</h2>
              <Link onClick={handleClose} style={{cursor: 'pointer'}}>
                <img className={inviteStyles.CloseBtnImg} src="/Content/Images/close.png" alt="close"></img>
              </Link>
            </div>

            {errorMessage ? <div className={'mb-2 text-danger text-center'} >{errorMessage}</div> : null}

            <div className={inviteStyles.formDataInvitations}>
              <Row>
              <Form.Group className="mb-3">
                {/* Select dropdown if partyType is 'all' */}
                {partyType === 'all' ? (
                <Form.Select
                    aria-label="Select Workshop Party Here"
                    value={workshopPartyId}
                    className={inviteStyles.inviteFormSelect}
                    onChange={handleInterestChange}
                    isInvalid={!!errors.workshopPartyCaption}
                  >
                    <option value="" disabled> Please Select Workshop Title  </option>
                    {groupWatchWorkshopsList.map((workshop, index) => (
                      <option key={index} value={workshop.feedId}>
                        {workshop.caption}
                      </option>
                    ))}
                    <option value="others">Others</option>
                  </Form.Select>
                ) : (
                  <Form.Control
                    type="text"
                    className={inviteStyles.inviteFormControl}
                    placeholder="Workshop title (Auto-fetched from workshop)"
                    value={workshopPartyCaption}
                    onChange={(e) => setWorkshopPartyCaption(e.target.value)}
                    isInvalid={!!errors.workshopPartyCaption}
                  />
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.workshopPartyCaption}
                </Form.Control.Feedback>
              </Form.Group>

              </Row>

              <Row className="mb-3">
                <label className={inviteStyles.inviteFormLabel}>Date & Time*</label>
                <Col xs='auto' className={inviteStyles.startingWidth}>
                  <Row>
                    <Col xs={6}>
                      <Form.Control
                        type="date"
                        className={inviteStyles.schedulingInputDateTime}
                        value={partyStartDate}
                        onChange={(e) => setPartyStartDate(e.target.value)}
                        isInvalid={!!errors.partyStartDate}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.partyStartDate}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={6}>
                      <Form.Control
                        type="time"
                        className={inviteStyles.schedulingInputDateTime}
                        value={partyStartTime}
                        onChange={(e) => setPartyStartTime(e.target.value)}
                        isInvalid={!!errors.partyStartTime}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.partyStartTime}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Col>
                <Col xs='auto' className={inviteStyles.middleWidth} >
                  <Row className='text-center'>
                    <label className={inviteStyles.inviteFormLabel}>To</label>
                  </Row>
                </Col>
                <Col xs='auto' className={inviteStyles.endingWidth}>
                  <Row>
                    <Col xs={6} className={inviteStyles.endContents}>
                      <Form.Control
                        type="date"
                        className={inviteStyles.schedulingInputDateTime}
                        value={partyEndDate}
                        onChange={(e) => setPartyEndDate(e.target.value)}
                        isInvalid={!!errors.partyEndDate}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.partyEndDate}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={6} className={inviteStyles.endContents}>
                      <Form.Control
                        type="time"
                        className={inviteStyles.schedulingInputDateTime}
                        value={partyEndTime}
                        onChange={(e) => setPartyEndTime(e.target.value)}
                        isInvalid={!!errors.partyEndTime}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.partyEndTime}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mb-3">
                <label className={inviteStyles.inviteFormLabel}>Timezone*</label>
                <Form.Select
                  aria-label="Select timezone"
                  className={inviteStyles.inviteFormSelect}
                  value={timezone.value}
                  onChange={(e) => handleTimezoneChange(e.currentTarget.value)}
                  isInvalid={!!errors.timezone}
                >
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.timezone}
                </Form.Control.Feedback>
              </Row>

              <Row className="mb-3">
                <label className={inviteStyles.inviteFormLabel}>Attendees* (Invite up to 10)</label>
                <Col xs={6} md={6} lg={6}>
                  <SearchAttendees filterWithUserId={handleUserChange} />
                  {errors.attendeesList && (
                    <div className="text-danger">{errors.attendeesList}</div>
                  )}
                </Col>
              </Row>
            </div>

              <Row>
                <Col xs={5} md={5} lg={5}></Col>
                <Col xs={2} md={2} lg={2}>
                  <Button className={inviteStyles.inviteButtonStyle} onClick={createNewInvitation}>
                    Invite
                  </Button>
                </Col>
                <Col xs={5} md={5} lg={5}></Col>
              </Row>
          </Row>
        </Col>

        <Col xs={4} md={4} lg={4}></Col>
      </Row>
      <br/><br/><br/>
    </Container>
  );
};

export default NewInvitation;
