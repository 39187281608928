import { useState, useEffect } from 'react';
import { BeatLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { Container, Row, Button, Form, Modal } from 'react-bootstrap';
import styles from './crp.module.css';
import CopyUrlButton from '../BaseComponents/CopyUrlButton';
import Tooltip from '../BaseComponents/Tooltip';
import { FaCopy } from 'react-icons/fa';
import cartServices from '../../Services/cartServices';


const RefferalProgram = () => {

    let refferalCode = null;
    let isStripe = false;
    const storedRefferalCode = localStorage.getItem('userRefferalCode');
    if (storedRefferalCode && storedRefferalCode !== 'undefined') {
      refferalCode = storedRefferalCode;
    }
    if(localStorage.getItem('stripeAccountExists')) {
      isStripe = localStorage.getItem('stripeAccountExists');
    }

    const [isLoading, setIsLoading] = useState(false);
    const [isPromoApplied, setIsPromoApplied] = useState(false);
    const [promoKey, setPromoKey] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Functions to handle modal visibility
    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    useEffect(() => {
        const fetchUserPromotions = async () => {
          try {
            setIsLoading(true);
            
            const response = await cartServices.fetchReferralPromotions();
            if (response !== null && response.statusCode === 200 && response.data !== null && response.data.length > 0 && response.data[0].promoKey) {
                setPromoKey(response.data[0].promoKey);
                setIsPromoApplied(true);
            } else {
                setIsPromoApplied(false);
            }
          } catch (error) {
            console.error('API Error:', error);
            setIsPromoApplied(false);
          } finally {
            setIsLoading(false);
          }
        };
      
        fetchUserPromotions();

      }, []);
    
      const ActivateReferrals = async () => {
        setErrorMessage('');
        setSuccessMessage('');

        if(promoKey === null || promoKey === undefined || promoKey === 'undefined' || promoKey === '') {
            setErrorMessage('Please enter a referral code to Activate');
            return;
        } 

        try {
            setIsLoading(true);
            const response = await cartServices.ActivateReferralPromotions(promoKey);
            // console.log("Response:", response);
            if (response !== null && response.statusCode === 200) {
                setPromoKey(response.data[0].promoKey);
                setIsPromoApplied(true);
                setSuccessMessage('Referral program activated successfully.');
                setTimeout(() => {
                    window.location.reload();
                }, 5000);

            } else {
                setIsPromoApplied(false);
                setErrorMessage(response.message || 'Failed to activate referral program. Please try again later.');
            }

        } catch (error) {
          console.error("API Error:", error);
          setIsPromoApplied(false);
          setErrorMessage( 'Failed to activate referral program. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      };


  return (
    <Container fluid className='minPadding'>
        
        <Row>
            <div className={styles.viewActivityDiv}>
                <Link to="/crp/activity-board" className={styles.viewActivitiesLabel}>
                    <label> View Your Activities </label>
                </Link>
            </div>

            <div className={`boxBody ${styles.crprogramDiv}`}>
                <Row>
                    <h2 className={styles.headerTextTemplate}>Community Referral program</h2>
                    <label className={`${styles.ProgramTextStyle}`} style={{display: 'none'}}>Join us on an incredible journey of creativity, connection, and personal growth. 
                        Whether you want to try a new hobby, connect with friends, or simply find inspiration, 
                        hapifyr is here to help you achieve your goals. Together, we can ignite happiness in ourselves and those around us, one meaningful workshop at a time.
                        Welcome to hapifyr - it's time to Ignite Happiness!
                    </label>
                    <label className={`mt-2 ${styles.ProgramTextStyle}`}>We're building a community who connect through common creative interests, 
                        and by referring hosts and workshop participants, our community is rewarded. 
                        We distribute 10% of the Hapifyr services fee to community growth initiatives, including referral of hosts and workshop purchases.
                    </label>
                    <label className={`mt-2 mb-3 ${styles.ProgramTextStyleBold}`}>
                        Copy your unique link below and share it with your family and friend so they can join you on hapifyr.
                    </label>
                    <div className={styles.ShareOptionDiv}>
                        <div className={styles.SocialMediaIconsDiv}>
                            <label className={styles.sharewithSocialText} onClick={handleModalOpen}>Share on social platforms</label>
                            {/* <br />
                            <Button className={styles.shareonSocialplots} >
                                Share
                            </Button> */}

                                <Modal show={showModal} onHide={handleModalClose} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title className={styles.shareCopyText}>Copy your invitation link to share</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="d-flex flex-column align-items-center justify-content-center text-center">
                                        <button
                                            type="button"
                                            className={`${styles.URLlinkshow} btn btn-link`}
                                            onClick={() => {
                                                navigator.clipboard.writeText(`${window.location.origin}/signup/mandatory-details?ref=${refferalCode}`);
                                                alert('URL copied to clipboard!');
                                            }}
                                        >
                                            {`${window.location.origin}/signup/mandatory-details?ref=${refferalCode}`}
                                        </button>
                                        <button className={styles.ClickCopyBtn}>
                                            <FaCopy className="ml-2" />
                                            <CopyUrlButton name="Copy" url={`${window.location.origin}/signup/mandatory-details?ref=${refferalCode}`} />
                                        </button>
                                    </Modal.Body>
                                </Modal>

                        </div>

                        <div className={styles.SocialMediaIconsDiv}>
                            {isStripe ? (
                                <Button className={styles.invitewithMailBtn}><CopyUrlButton name="Copy Your Invitation Link" url={`${window.location.origin}/signup/mandatory-details?ref=${refferalCode}`} /></Button>
                            ) : (
                                <Tooltip content="Signup with Stripe to use Refferal program services.">
                                    <Button className={styles.invitewithMailBtn} disabled> Copy Your Invitation Link </Button>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </Row>
            </div>

            <div className={styles.ActiviteIdDiv}>
                {isLoading ? (
                    <div className='BeatLoaderContainerBox'>
                        <BeatLoader color="#2E637D" loading={true} height={10} width={200} />
                        <label>Loading...</label>
                    </div>
                ) : (
                    <Row>
                        <h2 className={styles.headerTextTemplate}>Enter your Referral ID</h2>
                        <label className={styles.labelTextStyle}>Link your Referral ID to get extra rewards</label>
                        
                        <label className={`mt-2 mb-1 ${styles.ProgramTextStyleBold}`}>Host Referrals*</label>
                        <label className={styles.ProgramTextStyle}>To be rewarded for referring a host to the hapifyr platform, 
                            simply share your unique link with the prospective host. 
                            If they sign up to hapifyr using your shared link, and go on to create and sell workshops on hapifyr,
                            you will receive 5% of the workshop price for all future workshops.
                        </label>

                        <label className={`mt-3 mb-1 ${styles.ProgramTextStyleBold}`}>Buyer Referrals*</label>
                        <label className={`mb-3 ${styles.ProgramTextStyle}`}>Our community can be rewarded for generating interest in workshops they participate in.
                            Simply complete a ‘Boast' at the end of a workshop you buy. A boast includes a Rating and Review,
                            and a photo of what you created in your workshop. We'll post your ‘Boast' in the community feed, 
                            and if a hapifyr user clicks on your boast, and goes on to buy the workshop in that session, you will receive 5% of the workshop price. 
                            Share your ‘boast' on other social platforms, and if a post goes on to generate a sale for that workshop in the same user session, you will receive 5% of the workshop price.
                        <br />
                        <br />
                            *<Link to="/info/terms-and-conditions" className={styles.programTextStyleTCs}>Terms and Conditions </Link>apply.
                        </label>

                        <div className='text-center'>

                        {errorMessage ? <label style={{ color: 'red',textAlign: 'center',fontWeight: 'bold',fontSize: '1.0em' }}>{errorMessage}</label> : null}
                        {successMessage ? <label style={{ color: 'green',textAlign: 'center',fontWeight: 'bold',fontSize: '1.0em' }}>{successMessage}</label> : null}

                        <Form.Group className="mb-3 mt-3" controlId="promoInput">
                        <Form.Label style={{display: 'none', visibility: 'none'}}>Referral ID</Form.Label>
                        {isPromoApplied ? (
                            <Form.Control 
                                size="lg"
                                type="text" 
                                value={promoKey} 
                                readOnly 
                                disabled 
                            />
                        ) : (
                            <Form.Control 
                                size="lg"
                                type="text" 
                                placeholder="Enter Referral ID"
                                value={promoKey}  
                                onChange={(e) => setPromoKey(e.target.value)}  
                            />
                        )}
                        </Form.Group>
                        </div>
                        <div className={styles.ShareOptionDiv}>
                            <div className={styles.SocialMediaIconsDiv}></div>
                            <div className={styles.SocialMediaIconsDiv}>
                            {isPromoApplied ? (
                                <Button className={styles.invitewithMailBtn} disabled> Promo Code Applied </Button>
                            ) : (
                                <Button className={styles.invitewithMailBtn} onClick={ActivateReferrals}> Activate </Button>
                            )}
                            </div>
                        </div>

                    </Row>
                )}
            </div>
        </Row>
    </Container>            
  );
};

export default RefferalProgram;
