import { useState, useEffect, useContext } from 'react';
import { Container, Row, Tab, Tabs } from 'react-bootstrap';
import hubStyles from './hub.module.css';
import CreatedInvitations from './Invitations/CreatedInvitations';
import InvitedInvitations from './Invitations/InvitedInvitations';
import hubServices from '../../Services/hubServices';
import userServices from '../../Services/userServices'; // Assuming follow/unfollow APIs are here
import AuthContext from '../Context/AuthContext';

const InvitationsDashboard = () => {
    const { setIsLoading } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState('invites');
    const [invitedInvitations, setInvitedInvitations] = useState([]);
    const [createdInvitations, setCreatedInvitations] = useState([]);
    const [invitationsFetched, setInvitationsFetched] = useState(false);

    useEffect(() => {
        if (!invitationsFetched) {
            async function fetchAllInvitations() {
                try {
                    setIsLoading(true);

                    const invitedResponse = await hubServices.fetchInvitations('invited');
                    if (invitedResponse && invitedResponse.statusCode === 200) {
                        setInvitedInvitations(invitedResponse.data || []);
                    }

                    const createdResponse = await hubServices.fetchInvitations('created');
                    if (createdResponse && createdResponse.statusCode === 200) {
                        setCreatedInvitations(createdResponse.data || []);
                    }

                    setInvitationsFetched(true);
                } catch (error) {
                    console.error("Error fetching invitations:", error);
                    setInvitationsFetched(false);
                } finally {
                    setIsLoading(false);
                }
            }
            fetchAllInvitations();
        }
    }, [invitationsFetched, setIsLoading]);


    const handleFollowUser = async (userId) => {
      let invites = [...invitedInvitations];
      let currentFollowStatus = invites.find(invite => invite.postedByUserId === userId)?.isFollowing;
    
      // Update follow status for all invites with the same userId
      invites = invites.map(invite =>
        invite.postedByUserId === userId
          ? { ...invite, isFollowing: !currentFollowStatus }
          : invite
      );
      setInvitedInvitations(invites);
    
      try {
        setIsLoading(true);
        if (currentFollowStatus) {
          let revoke = true;
          await userServices.unfollowUser(userId, revoke);
        } else {
          await userServices.followUser(userId);
        }
      } catch (error) {
        console.error("API Error in follow/unfollow:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const handleInvitation = async (invitationId, responseType) => {
        try {
            setIsLoading(true);
            let invites = [...invitedInvitations];
            let foundInvite = invites.find(invite => invite.invitationId === invitationId);
            // let userId = foundInvite.postedByUserId;
            if(responseType) {
              foundInvite.acceptedInvitation = true;
              foundInvite.rejectedInvitation = false;
            } else {
              foundInvite.acceptedInvitation = false;
              foundInvite.rejectedInvitation = true;
            }
            setInvitedInvitations(invites);

            await hubServices.approvalInvitation(invitationId, responseType);
            
        } catch (error) {
            console.error("API Error in handleInvitation:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <Row className="d-flex flex-column align-items-right">
                <Row className="mt-4">
                    <Tabs
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                        className={`customTabHeaders ${hubStyles.customTabHeaders}`}
                    >
                        <Tab
                            eventKey="invites"
                            title={
                                <span
                                    className={`${hubStyles.customTabHeader} ${
                                        activeTab === 'invites' ? hubStyles.customeTabClickActive : ''
                                    }`}
                                >
                                    Invites ({invitedInvitations.length})
                                </span>
                            }
                        />
                        <Tab
                            eventKey="created"
                            title={
                                <span
                                    className={`${hubStyles.customTabHeader} ${
                                        activeTab === 'created' ? hubStyles.customeTabClickActive : ''
                                    }`}
                                >
                                    Created ({createdInvitations.length})
                                </span>
                            }
                        />
                    </Tabs>
                </Row>

                <Row className="mt-2 RightPadding">
                    {activeTab === 'created' && <CreatedInvitations invitations={createdInvitations} />}
                    {activeTab === 'invites' && (
                        <InvitedInvitations invitations={invitedInvitations} handleFollowUser={handleFollowUser} handleInvitation={handleInvitation} />
                    )}
                </Row>
            </Row>
        </Container>
    );
};

export default InvitationsDashboard;
