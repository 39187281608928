import { useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import inviteStyles from './invite.module.css';
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import SearchAttendees from './SearchAttendees';
import AuthContext from '../../Context/AuthContext';
import hubServices from '../../../Services/hubServices';

const UpdateInvitation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { invitationId } = location.state || {};
  const { setIsLoading } = useContext(AuthContext);

  const [invitationFeedId, setInvitationFeedId] = useState('');
  const [invitationCaption, setInvitationCaption] = useState('');
  const [invitationStartDate, setInvitationStartDate] = useState('');
  const [invitationEndDate, setInvitationEndDate] = useState('');
  const [invitationStartTime, setInvitationStartTime] = useState('');
  const [invitationEndTime, setInvitationEndTime] = useState('');
  const [attendees, setAttendees] = useState([]); // State for full participant details
  const [attendeesList, setAttendeesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});

  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle: "original",
    timezones: {
      ...allTimezones,
      "Europe/Berlin": "Frankfurt",
    },
  });
  const [timezone, setTimezone] = useState(parseTimezone("Australia/Melbourne"));

  // Fetch Invitation Details
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current && invitationId) {
      isFirstRun.current = false; // Mark as initialized
  
      async function fetchInvitationDetailsById(id) {
        try {
          setIsLoading(true);
          const response = await hubServices.fetchInvitationDetailsById(id);
          if (response.statusCode === 200) {
            const data = response.data;
            setInvitationFeedId(data.feedId || '');
            setInvitationCaption(data.caption || '');
            setInvitationStartDate(data.startDate || '');
            setInvitationEndDate(data.endDate || '');
            setInvitationStartTime(data.startTime || '');
            setInvitationEndTime(data.endTime || '');
            
            const matchingTimezone = options.find(option => option.label === data.tz);
            setTimezone(matchingTimezone || parseTimezone("Australia/Melbourne"));
            const participantDetails = data.participantDetails || [];
            // Set the full participant details
            setAttendees(participantDetails);
            // Extract only the user IDs and set them in attendeesList
            const userIds = participantDetails.map(participant => participant.userId);
            setAttendeesList(userIds);
          }
        } catch (error) {
          console.error("Error fetching invitation details:", error);
        } finally {
          setIsLoading(false);
        }
      }
  
      fetchInvitationDetailsById(invitationId);
    }
  }, [invitationId, setIsLoading, options, parseTimezone]); // Keep dependencies to ensure values are up-to-date on the first run


  // Handle Timezone Change
  const handleTimezoneChange = (value) => {
    const parsedTimezone = parseTimezone(value);
    setTimezone(parsedTimezone);
  };

  // Handle Adding Attendees
  const handleUserChange = async (userId) => {
    // Input validation
    if (!userId || userId.trim() === "") {
      alert("Please provide the User ID first.");
      return;
    }
    if (!invitationId || invitationId.trim() === "") {
      alert("Please provide the Invitation ID first.");
      return;
    }

    // Update local attendees list
    setAttendeesList((prevList) =>
      prevList.includes(userId) ? prevList : [...prevList, userId]
    );

    // Send data to backend
    try {
      await hubServices.addParticipantsToInvitationById(invitationId, userId);
      // console.log("Participant added successfully.");
    } catch (error) {
      console.error("API Error:", error);
    }
  };


  // Validate Form
  const validateForm = () => {
    const newErrors = {};
    const startDateTime = new Date(`${invitationStartDate}T${invitationStartTime}`);
    const endDateTime = new Date(`${invitationEndDate}T${invitationEndTime}`);
    // console.log("TTT::", timezone)

    if (!invitationId) newErrors.invitationCaption = "Please provide invitation id first.";
    if (!invitationFeedId) newErrors.invitationCaption = "Please provide invitation feed id first.";
    if (!invitationCaption) newErrors.invitationCaption = "Please enter the title of the party.";
    if (!invitationStartDate) newErrors.invitationStartDate = "Please select a start date.";
    if (!invitationStartTime) newErrors.invitationStartTime = "Please select a start time.";
    if (!invitationEndDate) newErrors.invitationEndDate = "Please select an end date.";
    if (!invitationEndTime) newErrors.invitationEndTime = "Please select an end time.";
    if (!timezone) newErrors.timezone = "Please select a timezone.";
    if (attendeesList.length === 0) newErrors.attendeesList = "Please add at least one attendee.";
    if (startDateTime >= endDateTime) newErrors.dateTime = "End date and time must be after start date and time.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Submit Updated Invitation
  const updateInvitation = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (!validateForm()) return;

    try {
      setIsLoading(true);
      const response = await hubServices.updateWorkshopPartyInvitation(
        invitationId,
        invitationCaption,
        invitationStartDate,
        invitationStartTime,
        invitationEndDate,
        invitationEndTime,
        timezone.label,
        attendeesList
      );

      if (response?.statusCode === 200) {
        alert("Invitation updated successfully!");
        navigate(`/experiencehub/experience/${invitationFeedId}`);
      } else {
        setErrorMessage(response?.message || "Error updating invitation.");
      }
    } catch (error) {
      console.error("API Error:", error);
      setErrorMessage("An error occurred while updating the invitation.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    navigate(-1); // Go back to the previous page
  };


  return (
    <Container fluid className="noPadding">
      <Row className={inviteStyles.newInvitationsBackdrop}>
        <Col xs={4}></Col>
        <Col xs={4}>
          <Row className={inviteStyles.newInvitationsBoxsetup}>
            
            <div className={inviteStyles.SchedulingHeaderDiv}>
              <h2 className={inviteStyles.SchedulingHeader}>Scheduling Details*</h2>
              <Link onClick={handleClose} style={{textDecoration: 'none', textAlign: 'end', cursor: 'pointer'}}>
                <img className={inviteStyles.CloseBtnImg} src="/Content/Images/close.png" alt="close"></img>
              </Link>
            </div>

            {errorMessage && <div className={'mb-2 text-danger text-center'}>{errorMessage}</div>}
            <div className={inviteStyles.formDataInvitations}>
              <Form>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      className={inviteStyles.inviteFormControl}
                      placeholder="Enter Workshop Title"
                      value={invitationCaption}
                      onChange={(e) => setInvitationCaption(e.target.value)}
                      isInvalid={!!errors.invitationCaption}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.invitationCaption}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <label className={inviteStyles.inviteFormLabel}>Date & Time*</label>
                  <Col xs='auto' className={inviteStyles.startingWidth}>
                    <Row>
                      <Col xs={6}>
                        <Form.Control
                          type="date"
                          value={invitationStartDate}
                          onChange={(e) => setInvitationStartDate(e.target.value)}
                          isInvalid={!!errors.invitationStartDate}
                          className={inviteStyles.schedulingInputDateTime}
                        />
                        <Form.Control.Feedback type="invalid">{errors.invitationStartDate}</Form.Control.Feedback>
                      </Col>
                      <Col xs={6}>
                        <Form.Control
                          type="time"
                          value={invitationStartTime}
                          onChange={(e) => setInvitationStartTime(e.target.value)}
                          isInvalid={!!errors.invitationStartTime}
                          className={inviteStyles.schedulingInputDateTime}
                        />
                        <Form.Control.Feedback type="invalid">{errors.invitationStartTime}</Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs='auto' className={inviteStyles.middleWidth} >
                    <Row className='text-center'>
                      <label className={inviteStyles.inviteFormLabel}>To</label>
                    </Row>
                  </Col>
                  <Col xs='auto' className={inviteStyles.endingWidth}>
                    <Row>
                      <Col xs={6} className={inviteStyles.endContents}>
                        <Form.Control
                          type="date"
                          value={invitationEndDate}
                          onChange={(e) => setInvitationEndDate(e.target.value)}
                          isInvalid={!!errors.invitationEndDate}
                          className={inviteStyles.schedulingInputDateTime}
                        />
                        <Form.Control.Feedback type="invalid">{errors.invitationEndDate}</Form.Control.Feedback>
                      </Col>
                      <Col xs={6} className={inviteStyles.endContents}>
                        <Form.Control
                          type="time"
                          value={invitationEndTime}
                          onChange={(e) => setInvitationEndTime(e.target.value)}
                          isInvalid={!!errors.invitationEndTime}
                          className={inviteStyles.schedulingInputDateTime}
                        />
                        <Form.Control.Feedback type="invalid">{errors.invitationEndTime}</Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <label className={inviteStyles.inviteFormLabel}>Timezone*</label>
                  <Form.Select
                    aria-label="Select timezone"
                    className={inviteStyles.inviteFormSelect}
                    value={timezone.value}
                    onChange={(e) => handleTimezoneChange(e.currentTarget.value)}
                    isInvalid={!!errors.timezone}
                  >
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.timezone}</Form.Control.Feedback>
                </Row>
                <Row className="mb-3">
                  <label className={inviteStyles.inviteFormLabel}>Attendees*</label>
                  <Col xs={12}>
                    <SearchAttendees filterWithUserId={handleUserChange} attendeesList={attendees} />
                  </Col>
                  {errors.attendeesList && (
                    <Col xs={12} className="text-danger">
                      {errors.attendeesList}
                    </Col>
                  )}
                </Row>
              </Form>
            </div>

            <Row>
              <Col xs={12} className="text-center mt-3">
                <Button type="submit" className={inviteStyles.inviteButtonStyle} onClick={updateInvitation}> Update </Button>
              </Col>
            </Row>
          </Row>
        </Col>
        <Col xs={4}></Col>
      </Row>
    </Container>
  );
};

export default UpdateInvitation;
