import React, { useState, useEffect, useRef } from 'react';
import "@sendbird/uikit-react/dist/index.css";
import './SBStyles.css';
import { BeatLoader } from 'react-spinners';
import { FaChevronLeft } from "react-icons/fa";
import SendbirdChat from "@sendbird/chat";
import { timestampToTime } from '../../Utils/messageUtils';
import { handleEnterPress } from '../../Utils/messageUtils';
import {
  GroupChannelModule,
  GroupChannelFilter,
  GroupChannelListOrder,
  MessageFilter,
  MessageCollectionInitPolicy
} from '@sendbird/chat/groupChannel';
import { Image } from 'react-bootstrap';
import baseStyles from './Chat.module.css';
import config from '../../config/config.js';
import MessagingServices from '../../Services/MessagingServices.js';

const APP_ID = config.sendbirdAppId;
let sb;

const OnetoOneChat = ({ channelUrl, chatwithUserId, changeType, changeForm }) => {
   
    const sendbirdId = localStorage.getItem('sendbirdUserId');
    const sendbirdName = localStorage.getItem('sendbirdNickname');
    // const [joinedMembers, setJoinedMembers] = useState([]); // Local state to store members
    const Profiles = new Array(5).fill("");
    const [start, setStart] = useState(true);
    const [isBlocked, setIsBlocked] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    
    const [state, updateState] = useState({
        applicationUsers: [],
        groupChannelMembers: [],
        currentlyJoinedChannel: null,
        messages: [],
        channels: [],
        messageInputValue: "",
        userNameInputValue: sendbirdName,
        userIdInputValue: sendbirdId,
        channelNameUpdateValue: "",
        settingUpUser: true,
        file: null,
        messageToUpdate: null,
        messageCollection: null,
        readMessages: [],
        loading: false,
        error: false,
        isReactions: false,
        currentMessage: {},
      });
      
      const stateRef = useRef();
      stateRef.current = state;
      
      const channelRef = useRef();
      
      useEffect(() => {
        if (start) {
          if (channelUrl && state.channels.length > 0) {
            handleJoinChannel(channelUrl);
          } else {
            setupUser();
          }
        }
        searchBlockedUsers();
      });
      
      const setupUser = async () => {
        const { userNameInputValue, userIdInputValue } = state;
        const sendbirdInit = await SendbirdChat.init({
          appId: APP_ID,
          localCacheEnabled: true,
          modules: [new GroupChannelModule()],
        });
      
        await sendbirdInit.connect(userIdInputValue);
        await sendbirdInit.setChannelInvitationPreference(true);
      
        const userUpdateParams = {
          nickname: userNameInputValue,
          userId: userIdInputValue,
        };
        await sendbirdInit.updateCurrentUserInfo(userUpdateParams);
      
        sb = sendbirdInit;
        updateState((prevState) => ({ ...prevState, loading: true }));
      
        const [channels, error] = await loadChannels(channelHandlers);
        if (error) {
          onError(error);
        } else {
          updateState((prevState) => ({ ...prevState, channels, loading: false, settingUpUser: false }));
        }
      };
      
      const searchBlockedUsers = async () => {
        try {
          const response = await MessagingServices.fetchBlockedUserslist();
          // console.log("API Response:", response);
          if(response !== null && response.statusCode === 200 && response.data !== null && response.data.length > 0){
            
            const blockedUserIds = response.data.map(user => user.userId); // Extract user IDs from response data
            // console.log("Members:: ", blockedUserIds);
            if (chatwithUserId && blockedUserIds.includes(chatwithUserId)) {
                setIsBlocked(true);
            } else {
                setIsBlocked(false);
            }

            // console.log("Members::", joinedMembers);
            // const filteredMembers = joinedMembers.filter(member => member.userId !== sendbirdId); // Filter members
            // const blockedUserIds = response.data.map(user => chatwithUserId); // Extract blocked user IDs
            
            // console.log("Filtered Members:", filteredMembers, "::", blockedUserIds);
            
            // if (filteredMembers.some(member => blockedUserIds.includes(member.userId))) {
            //   setIsBlocked(true); // Set block if there’s any match
            // }
          }

          } catch (error) {
            console.error("API Error:", error);
          }
      };

      const channelHandlers = {
        onChannelsAdded: (context, channels) => {
          const updatedChannels = [...channels, ...stateRef.current.channels];
          updateState((prevState) => ({ ...prevState, channels: updatedChannels, applicationUsers: [] }));
        },
        onChannelsDeleted: (context, channels) => {
          const updatedChannels = stateRef.current.channels.filter((channel) => {
            return !channels.includes(channel.url);
          });
          updateState((prevState) => ({ ...prevState, channels: updatedChannels }));
        },
        onChannelsUpdated: (context, channels) => {
          const { messages } = stateRef.current;
          const updatedChannels = stateRef.current.channels.map((channel) => {
            const updatedChannel = channels.find((incomingChannel) => incomingChannel.url === channel.url);
            return updatedChannel || channel;
          });
      
          const currentChannel = channels.find((channel) => {
            return stateRef.current.currentlyJoinedChannel?.url === channel.url;
          });
      
          const readMessages = messages.filter((message) => {
            const readMembers = currentChannel?.getReadMembers(message) || [];
            return readMembers.length > 0;
          });
      
          updateState((prevState) => ({ ...prevState, channels: updatedChannels, readMessages }));
        },
      };
      
      const messageHandlers = {
        onMessagesAdded: (context, channel, messages) => {
          const updatedMessages = [...stateRef.current.messages, ...messages];
          updateState((prevState) => ({ ...prevState, messages: updatedMessages }));
        },
        onMessagesUpdated: (context, channel, messages) => {
          stateRef.current.currentlyJoinedChannel?.markAsRead();
      
          const updatedMessages = [...stateRef.current.messages];
          messages.forEach((incomingMessage) => {
            const indexOfExisting = updatedMessages.findIndex((message) => incomingMessage.reqId === message.reqId);
      
            if (indexOfExisting !== -1) {
              updatedMessages[indexOfExisting] = incomingMessage;
            } else if (!incomingMessage.reqId) {
              updatedMessages.push(incomingMessage);
            }
          });
      
          updateState((prevState) => ({ ...prevState, messages: updatedMessages }));
        },
        onMessagesDeleted: (context, channel, messageIds) => {
          const updatedMessages = stateRef.current.messages.filter((message) => !messageIds.includes(message.messageId));
          updateState((prevState) => ({ ...prevState, messages: updatedMessages }));
        },
      };

      const scrollToBottom = (item, smooth) => {
        item?.scrollTo({
            top: item.scrollHeight,
            behavior: smooth
        })
      }

      useEffect(() => {
        scrollToBottom(channelRef.current)
      }, [state.currentlyJoinedChannel])

      useEffect(() => {
        scrollToBottom(channelRef.current, 'smooth')
      }, [state.messages])

      const onError = (error) => {
        updateState((prevState) => ({ ...prevState, error: error.message }));
        console.error(error);
      };
      
      const loadChannels = async (channelHandlers) => {
        const groupChannelFilter = new GroupChannelFilter();
        groupChannelFilter.includeEmpty = true;

        const collection = sb.groupChannel.createGroupChannelCollection({
          filter: groupChannelFilter,
          order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
        });
      
        collection.setGroupChannelCollectionHandler(channelHandlers);
      
        const channels = await collection.loadMore();
        return [channels, null];
      };
      
      const loadMessages = (channel, messageHandlers, onCacheResult, onApiResult) => {
    const messageFilter = new MessageFilter();
    
    const collection = channel.createMessageCollection({
        filter: messageFilter,
        startingPoint: Date.now(),
        limit: 100,
    });

    collection.setMessageCollectionHandler(messageHandlers);
    collection
        .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
        .onCacheResult((err, messages) => {
        if (!err) {
            onCacheResult(err, messages.sort((a, b) => a.createdAt - b.createdAt)); // Ensure messages are sorted by creation time
        } else {
            onCacheResult(err, []);
        }
        })
        .onApiResult((err, messages) => {
        if (!err) {
            onApiResult(err, messages.sort((a, b) => a.createdAt - b.createdAt)); // Ensure messages are sorted by creation time
        } else {
            onApiResult(err, []);
        }
        });
    return collection;
      };

    const handleJoinChannel = async (channelUrl) => {
        if (state.messageCollection?.dispose) {
            state.messageCollection.dispose();
        }
    
        if (state.currentlyJoinedChannel?.url === channelUrl) {
            return;
        }
    
        const { channels } = state;
        updateState({ ...state, loading: true });
        // console.log("Channels:", channels);
        // console.log("channelUrl:", channelUrl);

        let channel = channels.find((ch) => ch.url === channelUrl);
        // console.log("Channel:::", channel);

        // If the channel is not found in the local state, fetch it from Sendbird
        if (channel === undefined || channel === "undefined" || channel === null) {
            setErrorMessage("Could not connect to the channel. Please try again.");
            updateState({ ...state, loading: false });
            return;
        }

        // let members = channel.members || []; // Assuming `members` is an array in currentlyJoinedChannel
        // // let memberlist = members(member, {
        // //   nickname: member.nickname,
        // //   userId: member.userId,
        // //   state: member.state,
        // // }));
        // setJoinedMembers(members); // Set the members data in local state
        // console.log("Members::", joinedMembers);
    
        channel.markAsRead();
    
        const onCacheResult = (err, messages) => {
            updateState({ 
                ...stateRef.current, 
                currentlyJoinedChannel: channel, 
                messages: messages, 
                loading: false 
            });
        };
    
        const onApiResult = (err, messages) => {
            updateState({ 
                ...stateRef.current, 
                currentlyJoinedChannel: channel, 
                messages: messages, 
                loading: false 
            });
        };
    
        const collection = loadMessages(channel, messageHandlers, onCacheResult, onApiResult);
        updateState({ ...state, messageCollection: collection });
        setStart(false);
    };


    const handleLeaveChannel = async (form) => {
        changeType("conversations");
        changeForm(form);
    };
      
    const sendMessage = async () => {
        const { messageToUpdate, currentlyJoinedChannel, messages } = state;
        
        if (messageToUpdate) {
          const userMessageUpdateParams = { message: state.messageInputValue };
          const updatedMessage = await currentlyJoinedChannel.updateUserMessage(messageToUpdate.messageId, userMessageUpdateParams);
          const messageIndex = messages.findIndex((item => item.messageId === messageToUpdate.messageId));
          messages[messageIndex] = updatedMessage;
          updateState({ ...state, messages, messageInputValue: "", messageToUpdate: null });
        } else {
          const userMessageParams = { message: state.messageInputValue };
          currentlyJoinedChannel.sendUserMessage(userMessageParams)
            .onSucceeded((message) => {
              updateState({ ...stateRef.current, messageInputValue: "" });
            })
            .onFailed((error) => {
              console.error(error);
            });
        }
    };
    
    const updateMessageReactions = async (message) => {
        const { messages, currentlyJoinedChannel } = state;
      
        const userMessageUpdateParams = {};
        const updatedMessage = await currentlyJoinedChannel.updateUserMessage(message.messageId, userMessageUpdateParams);
        const messageIndex = messages.findIndex((item => item.messageId === message.messageId));
        messages[messageIndex] = updatedMessage;
      
        updateState({ ...state, messages, isReactions: false });
      };
      
      const toggleReactions = async (message) => {
        const { isReactions } = state;
        updateState({ ...state, isReactions: !isReactions, currentMessage: message });
      };
      
      const addMessageReaction = async (message, e) => {
        const { currentMessage, currentlyJoinedChannel } = state;
      
        const reactionKey = e.target.getAttribute("data-reaction");
        try {
          await currentlyJoinedChannel.addReaction(currentMessage, reactionKey);
          updateMessageReactions(message);
        } catch (error) {
          console.error(error);
        }
    };
      
    const onMessageInputChange = (e) => {
        const messageInputValue = e.currentTarget.value;
        updateState({ ...state, messageInputValue });
    };


    if (state.loading) {
        return (
          <div className={baseStyles.feedsContainerBox}>
            <BeatLoader color="#2E637D" loading={true} height={10} width={200} />
            <label>Loading...</label>
          </div>
        );
    }


  return (
    <div className="customized-app">
            {errorMessage && (
                <div className={baseStyles.emptyUsersDiv}>
                  <button className="leave-channel" onClick={() => handleLeaveChannel('users')}>
                    <FaChevronLeft />
                  </button>
                  <h3>{errorMessage}</h3>
                </div>
            )}
            <Channel
                currentlyJoinedChannel={state.currentlyJoinedChannel}
                sendbirdId={sendbirdId}
                handleLeaveChannel={handleLeaveChannel}
                Profiles={Profiles}
            >
              <MessagesList
                messages={state.messages}
                addMessageReaction={addMessageReaction}
                toggleReactions={toggleReactions}
                isReactions={state.isReactions}
                currentMessage={state.currentMessage}
                readMessages={state.readMessages}
                channelRef={channelRef}
                isBlocked={isBlocked}
              />
              <MessageInput
                value={state.messageInputValue}
                onChange={onMessageInputChange}
                sendMessage={sendMessage}
                fileSelected={state.file}
                isBlocked={isBlocked}
                   // onFileInputChange={onFileInputChange}
              />
            </Channel>
    </div>
  );
};

const Channel = ({ currentlyJoinedChannel, sendbirdId, children, handleLeaveChannel, channelRef, Profiles }) => {
    if (currentlyJoinedChannel) {
        return <div ref={channelRef}>
            <div className='channelProfilediv'>
            {currentlyJoinedChannel.customType === 'dm' ? (
                <button className="leave-channel" onClick={() => handleLeaveChannel('users')}><FaChevronLeft /></button>
                ) : (
                <button className="leave-channel" onClick={() => handleLeaveChannel('groups')}><FaChevronLeft /></button>
                )}

                {currentlyJoinedChannel.members !== null && currentlyJoinedChannel.members.length > 0 && (
                <ChannelHeader>
                    {currentlyJoinedChannel.customType === 'dm' ? (
                        currentlyJoinedChannel.members.filter(member => member.userId !== sendbirdId).map(filteredMember => (
                                <div key={filteredMember.userId} className={`flexGap mb-2 mt-2`}>
                                    {filteredMember.plainProfileUrl !== null && filteredMember.plainProfileUrl !== "" ? (
                                        <Image
                                            src={filteredMember.plainProfileUrl}
                                            roundedCircle
                                            alt="MsgUser"
                                            className="roundedUser mr-3 profileImage"
                                        />
                                    ) : (
                                        <div className="profile-image-fallback">
                                            {filteredMember.nickname.charAt(0)}
                                        </div>
                                    )}

                                    <div className="channel-list-item-details">
                                        <h4 className={baseStyles.liveUsername}>{filteredMember.nickname}</h4>
                                        <div className="last-message">{filteredMember.connectionStatus}</div>
                                    </div>
                                </div>
                            ))
                    ) : (
                        <div key={currentlyJoinedChannel.id} className={`flexGap mb-2 mt-2`}>
                          <Image
                            src="/Content/Images/icons/icons-users.png"
                            roundedCircle
                            alt="MsgUser"
                            className="roundedUser mr-3 profileImage"
                          />
                             
                          <div className="channel-list-item-details">
                            <h4 className={baseStyles.liveUsername}>{currentlyJoinedChannel.name}</h4>
                              <div className={baseStyles.circleoverlap}>
                                {currentlyJoinedChannel.members.filter(member => member.userId !== sendbirdId).map((member, idx) => (
                                  <img
                                    key={idx}
                                    alt="project member"
                                    src={member.plainProfileUrl}
                                    style={{ left: `-${idx * 15}px` }}
                                  />
                                  ))
                                  }

                                {Profiles.length > 15 && (
                                  <div
                                    className={baseStyles.circleoverlapText}
                                    style={{ left: `${(Profiles.length * 15) + 15}px` }}
                                  >
                                    +{Profiles.length - 15} more
                                  </div>
                                )}
                              </div>
                          </div>
                        </div>
                    )}
                </ChannelHeader>
                )}
            </div>
            <div>{children}</div>
        </div>;
    }
    return <div></div>;
}

const ChannelHeader = ({ children }) => {
    return <div className="channel-header">{children}</div>;
}

const Message = ({ message, updateMessage, handleDeleteMessage, messageSentByYou, addMessageReaction, toggleReactions, isReactions, currentMessage, removeMessageReaction, readMessages }) => {
    if (message.url) {
        return (
            <div className={`message  ${messageSentByYou ? 'message-from-you' : ''}`}>
              <div className="message-user-info">
                <div className="message-sender-name"> {message.sender.nickname.split(' ')[0]}{' '}</div>
                <div className="message-timeStamp" >{timestampToTime(message.createdAt)}</div>
              </div>
            </div>
        );
    }
    // const showReactions = isReactions && (currentMessage.messageId === message.messageId)
    // const messageSentByCurrentUser = message.sender.userId === sb.currentUser.userId;
    const isMessagesRead = readMessages.filter((readMessage) => readMessage.messageId === message.messageId).length > 0;
    return (
        <div className={`message ${messageSentByYou ? 'message-from-you' : ''}`}>
            <div className="message-info">
                <div className="message-user-info">
                    <div className="message-sender-name"> {message.sender.nickname.split(' ')[0]}{' '}</div>
                    <div className="message-timeStamp">{timestampToTime(message.createdAt)}</div>
                </div>
            </div>
            <div className="message-info">{message.message}</div>
            {messageSentByYou && (
              <div>
                <img className={`message-icon double_tick-icon ${isMessagesRead && "double_tick-icon-read"}`} src={isMessagesRead ? '/Content/Images/icons/double_tick_as_read.png' : '/Content/Images/icons/double_tick.png'} alt="tick" />
              </div>
            )}
        </div>
    );
}

const ProfileImage = ({ user }) => {
    if (user.plainProfileUrl) {
        return <img className="profile-image" src={user.plainProfileUrl} alt='empty' />
    } else {
        return <div className="profile-image-fallback">{user.nickname.charAt(0)}</div>;
    }
}

const MessagesList = ({ messages, handleDeleteMessage, updateMessage, addMessageReaction, toggleReactions, isReactions, currentMessage, removeMessageReaction, readMessages, channelRef, isBlocked }) => {
    return <div ref={channelRef} className={`message-list ${isBlocked ? 'Disabled' : ''}`}>
        {messages.map(message => {
            if (!message.sender) return null;
            const messageSentByYou = message.sender.userId === sb.currentUser.userId;
            return (
                <div key={message.messageId} className={`message-item ${messageSentByYou ? 'message-from-you' : ''}`}>
                    <Message
                      message={message}
                      addMessageReaction={addMessageReaction}
                      removeMessageReaction={removeMessageReaction}
                      handleDeleteMessage={handleDeleteMessage}
                      updateMessage={updateMessage}
                      toggleReactions={toggleReactions}
                      isReactions={isReactions}
                      currentMessage={currentMessage}
                      messageSentByYou={messageSentByYou}
                      readMessages={readMessages} 
                    />
                    <ProfileImage user={message.sender} />
                </div>
            );
        })}
    </div>
}

const MessageInput = ({ value, onChange, sendMessage, onFileInputChange, isBlocked }) => {
    return (
        <div className="message-input">
          {/* {!isBlocked && ( */}
            <div className={`messageInputdiv ${isBlocked ? 'Disabled' : ''}`}>
                <input
                    placeholder="Write a message"
                    value={value}
                    onChange={onChange}
                    onKeyDown={(event => handleEnterPress(event, sendMessage))}
                />
                <div className="message-input-buttons">
                    {/* <label className="file-upload-label" htmlFor="upload" ><IoMdAttach /></label> */}
                    <input
                        id="upload"
                        className="file-upload-button"
                        type='file'
                        hidden={true}
                        onChange={onFileInputChange}
                        onClick={() => {}}
                    />
                    <button className="send-message-button" onClick={sendMessage}>
                        <img src="/Content/Images/send.png" className="sendImg"  alt="send" />
                    </button>
                </div>
            </div>
          {/* )} */}
        </div>
    );
}


export default OnetoOneChat;
