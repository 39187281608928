import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Nav, Tab, Accordion, Button } from 'react-bootstrap';
import genStyles from './general.module.css';


const TermsandConditions = () => {

    useEffect(() => {
        const handleTabClick = () => {
            window.scrollTo(0, 0); // Scrolls to the top of the page when a tab is clicked
        };
        document.querySelectorAll('.nav-link').forEach((tab) => {
            tab.addEventListener('click', handleTabClick);
        });
        return () => {
            document.querySelectorAll('.nav-link').forEach((tab) => {
                tab.removeEventListener('click', handleTabClick);
            });
        };
    }, []);

    const navigate = useNavigate();

    const handleClose = () => {
        navigate(-1); // Go back to the previous page
    };


  return (

      <main>
        <div className={genStyles.generalBoxContainer}>
                
                <Row className='minPadding'>
                    <Link onClick={handleClose} style={{textDecoration: 'none', textAlign: 'end', cursor: 'pointer'}}>
                        <label className={genStyles.CloseLabel}>Close</label>
                    </Link>
                </Row>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={3} className={`minPadding`}>
                    <Nav variant="pills" className={`flex-column ${genStyles.MainBoxSetup}`}>
                        <Button className={genStyles.mainButtonPP}>Terms & Conditions</Button>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="first" className={genStyles.leftNavClickSetup} > Hapifyr Terms of Use </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="second" className={genStyles.leftNavClickSetup} >1. <label> Definitions </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="third" className={genStyles.leftNavClickSetup} >2. <label> General </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="fourth" className={genStyles.leftNavClickSetup} >3. <label> Licence to use the Website and Services </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="fifth" className={genStyles.leftNavClickSetup} >4. <label> Buyers </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="sixth" className={genStyles.leftNavClickSetup} >5. <label> Hosts </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="seventh" className={genStyles.leftNavClickSetup} >6. <label> Fees & Payment </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="eighth" className={genStyles.leftNavClickSetup} >7. <label> Conduct and General User Obligations </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="ninth" className={genStyles.leftNavClickSetup} >8. <label> Confidential Information </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="tenth" className={genStyles.leftNavClickSetup} >9. <label> Intellectual Property Rights </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="eleventh" className={genStyles.leftNavClickSetup} >10. <label> Term, Termination and Suspension </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="twelth" className={genStyles.leftNavClickSetup} >11. <label> Limitation of Liability </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="thirteen" className={genStyles.leftNavClickSetup} >12. <label> Disclaimer of Warranties </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="fourteen" className={genStyles.leftNavClickSetup} >13. <label> Assumption of Risk </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="fifteen" className={genStyles.leftNavClickSetup} >14. <label> Gift Cards </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="sixteen" className={genStyles.leftNavClickSetup} >15. <label> Third Party Services </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="seventeen" className={genStyles.leftNavClickSetup} >16. <label> Disputes </label></Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Col>

                    <Col sm={9} className={`minPadding`}>
                    <Tab.Content className={`${genStyles.MainBoxSetup}`}>
                        <h2 className={genStyles.mainHeaderTerms}>Hapifyr Term and Conditions</h2>
                        
                        <Tab.Pane eventKey="first">
                            <div>
                                <h4 className={genStyles.ContentHeading}> Hapifyr terms of use </h4>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                    <h4 className={genStyles.TermsTagHeading}>A. </h4>
                                    <label>
                                    Hapifyr operates a marketplace and social networking platform in the form of the 
                                    Website that enables Users to connect and engage directly with each other for the 
                                    provision of Workshops. Registered Users can advertise Workshops for other 
                                    Users to search, purchase, and watch via the Website.
                                    </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                    <h4 className={genStyles.TermsTagHeading}>B. </h4>
                                    <label>
                                    Hapifyr provides the Website and associated Services only and is not the 
                                    advertiser, promoter, or supplier of any Workshops appearing on the Website. No 
                                    agency, partnership, joint venture, employee-employer, or other similar or fiduciary 
                                    relationship is created or exists between Hapifyr and any User (whether as a Buyer 
                                    or Seller (<b>Host</b>) of a Workshop).
                                    </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                    <h4 className={genStyles.TermsTagHeading}>C. </h4>
                                    <label>
                                    Registered Users who Sell Workshops (<b>Hosts</b>) are wholly responsible for the 
                                    provision, delivery and performance of Workshops purchased by Users (<b>Buyers</b>) 
                                    including complying with all applicable laws, and for any relationship created with 
                                    Users for the provision of Workshops, whether via the Website or otherwise.
                                    </label>
                                    </div>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                    <h4 className={genStyles.TermsTagHeading}>D. </h4>
                                    <label>
                                    Users are wholly responsible for any relationship created with each other, including 
                                    all interaction, correspondence, activities undertaken with a User for the purpose of 
                                    Purchasing and using any Workshops purchased from another Registered User, 
                                    whether via the Website or otherwise.
                                    </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                    <h4 className={genStyles.TermsTagHeading}>E. </h4>
                                    <label>
                                    Hapifyr is not responsible or liable for the conduct of or between any Users of the 
                                    Website including the provision of Workshops between such Users in any way 
                                    whether via the Website or otherwise.
                                    </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                    <h4 className={genStyles.TermsTagHeading}>F. </h4>
                                    <label>
                                    These Terms govern your use of the Website and associated Services and form a 
                                    binding contractual agreement between you and Hapifyr.
                                    </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                    <h4 className={genStyles.TermsTagHeading}>G. </h4>
                                    <label>
                                    These Terms are important, and you should ensure that you read them carefully. 
                                    For any questions regarding use of the Website or Services please contact 
                                    support@hapifyr.com.
                                    </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                    <h4 className={genStyles.TermsTagHeading}>H. </h4>
                                    <label>
                                    By using the Website or Services you acknowledge and agree that you have read 
                                    and understand these Terms and you agree to be bound by them. If you do not 
                                    agree to the Terms, please do not use the Website or Services.
                                    </label>
                                    </div>

                                <br />
                                <h5 className={genStyles.TermsSmallHeader}>Operative Provisions </h5>
                                <br />

                                <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 1. Definitions </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                        “<b>Account</b>” means an account created by Hapifyr for a User to access and use the 
                                        Services whether as a Buyer or Host which includes a Username and password.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Agreement</b>” means the terms of the agreement between Users and Hapifyr as defined in clause 2.1 below.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Australian Consumer Law</b>” means Schedule 2 of the Competition and Consumer Act 2010 (Cth).
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Authorised Users</b>” means a User's agents, directors, other officers, employees, or 
                                        contractors who are allowed by the User to use the Website on the User’s behalf.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Business Day</b>” means a day that is not a Saturday, Sunday, or public holiday in 
                                        Victoria, Australia.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Buyer</b>”  means a User seeking to purchase and watch a Workshop.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Confidential Information</b>” means any information of Hapifyr, or of another User, which 
                                        is designated, marked, or denoted as confidential, or which is of a confidential or 
                                        sensitive nature or which a reasonable person to whom that information is disclosed or 
                                        to whose knowledge the information comes would consider confidential.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Content</b>” means anything (including but not limited to all material, information, images, 
                                        videos, data) that is uploaded, published, submitted, supplied, posted or otherwise 
                                        transmitted, provided or made available via the Website or Services (whether by Hapifyr
                                        or a third party). When such content is uploaded, published, submitted, supplied, posted 
                                        or otherwise transmitted, provided or made available by you (or your Authorised User) in 
                                        using the Website or Services including your Registration Data, it is "<b>Your Content</b>".
                                        Content that forms part of a Workshop is referred to as “<b>Workshop Content</b>”.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Hapifyr</b>” is a trading name of MingleIn Pty Ltd ABN 79 128 714 849. Hapifyr may alsobe referred to as "<b>we</b>", "<b>us</b>", "<b>our</b>".
                                        <hr className={genStyles.TermslineHR}/>
                                        
                                        “<b>Host</b>” means a User advertising, promoting, selling, or otherwise providing Workshops 
                                        or Workshop Content via the Website.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Host Funds</b>” means the Workshop Price less the Services Fee.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Intellectual Property</b>” means all intellectual property rights (including, without 
                                        limitation, all registered and unregistered copyright, designs, trademarks and patents) of 
                                        any nature including in any technology, trade secrets, Hapifyr Content, User Content, 
                                        User lists, User details (including, but not limited to, User emails, telephone numbers 
                                        and addresses) or other User-related information, software, program, inventions, 
                                        designs, works, or in any other subject matter belonging to Hapifyr or in which 
                                        intellectual property rights exist.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Money-Back Guarantee</b>” means the money-back guarantee which Hapifyr may 
                                        provide to Buyers as part of providing its Website and Services, as determined in its 
                                        absolute and sole discretion and subject to any conditions as further detailed on the 
                                        Website.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Moral Rights</b>” means moral rights as conferred by the Copyright Act 1968 (Cth) and 
                                        rights of a similar nature anywhere else in the world.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Non-excludable Condition</b>” means any implied condition, warranty or guarantee in a 
                                        contract, the exclusion of which would contravene the law or cause any part of the 
                                        contract to be void.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Personal Information</b>” has the same meaning as defined in the Privacy Act 1988 (Cth).
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Pre-recorded Workshop</b>” or “<b>Pre-recorded Experience</b>” means any Workshop or 
                                        Experience that is not streamed live, but pre-recorded by the Host and viewed by a User 
                                        afterward.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Price</b>” means the price prescribed by a Host for a Workshop on the Website to be paid 
                                        by the Buyer at the time of purchase (but may not include any additional costs incurred 
                                        by the Host which the Buyer separately agrees to reimburse).
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Referral Incentive</b>” means the referral incentives set out in clause 6 .
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Registration Data</b>” means the information provided by you in order to register an 
                                        Account on the Website or otherwise.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Services</b>” means Hapifyr providing access to the Website to Users.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Services Fees</b>” means the portion of the Price to be paid as a fee by the Host to Hapifyr 
                                        in consideration for use of the Services at either: (i) the default rate specified at 
                                        hapifyr.com; or (ii) at the rate otherwise agreed with Hapifyr in writing.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Stored Value</b>” means the physical or virtual card, coupon, voucher, or code containing 
                                        credit or a discount or refund provided as credit, including gift cards, for use on the 
                                        Website.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Terms</b>” means these terms of use.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Third Party</b>” means an individual or entity who is not a party to this Agreement, such as 
                                        Users other than you, but does not include Hapifyr’s subsidiaries.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Third Party Service</b>” means services provided by a Third Party that may be featured 
                                        on or otherwise made available via the Website or as part of the Services from time to 
                                        time (whether by Hapifyr or by other Users such as Sellers providing Online Workshops 
                                        to Buyers).
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>User</b>” means any individual or entity (whether as a Buyer, Host, or any other person) 
                                        who accesses or uses the Website or Services, regardless of the nature of that access
                                        or use, including if such person is or is not identified to Hapifyr. Also referred to as "<b>you</b>", "<b>your</b>".
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Website</b>” means Hapifyr's marketplace platform at <Link to="https://ui.hapifyr.com" className={genStyles.LinkTagDirect}>https://Hapifyr.com</Link> (or any new site) 
                                        including all associated application(s) (including mobile applications), webpages, 
                                        software (including software-as-a-service) or any other web services or media managed 
                                        by us.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Workshop</b>” or “<b>Workshops</b>” as well as “<b>Experience</b>” or “<b>Experiences”</b>” means the Online Workshops, including 
                                        all associated goods and/or services, advertised by Users via the Website and provided by Hosts to Buyers 
                                        (whether via online access or in-person).
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Workshop Contract</b>” means a separate contract created between a Host and a Buyer for the provision of an 
                                        Online Workshop, which may include Third Party terms and conditions of use or access.
                                        <br/>

                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 2. General </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.1.</h4>
                                        <label>
                                        These Terms, together with Hapifyr's Privacy Policy located at 
                                        &nbsp;<Link to="https://Hapifyr.com/terms-and-conditions" className={genStyles.LinkTagDirect}>https://Hapifyr.com/terms-and-conditions</Link>, constitute the agreement between a User 
                                        and Hapifyr (Agreement) and supersede any prior versions of such terms 
                                        including all other communications whether oral or written, express or implied 
                                        relating to such terms.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.2.</h4>
                                        <label>
                                        This Agreement prevails in the event that any other information on the Website is 
                                        inconsistent with the terms of this Agreement.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.3.</h4>
                                        <label>
                                        If a provision of the terms of this Agreement is invalid or unenforceable it is to be 
                                        read down or severed to the extent necessary without affecting the validity or 
                                        enforceability of the remaining provisions.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.4.</h4>
                                        <label>
                                        This Agreement shall be governed by the laws of Victoria, Australia and is subject 
                                        to the exclusive jurisdiction of the courts of Victoria, Australia.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.5.</h4>
                                        <label>
                                        The failure by Hapifyr to enforce any provision of the terms of this Agreement shall 
                                        not be treated as a waiver of that provision, nor shall it affect Hapifyr’s right to 
                                        subsequently enforce that provision.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.6.</h4>
                                        <label>
                                        Hapifyr may vary the terms of this Agreement in its sole discretion by giving written 
                                        notice to Users and the varied terms shall take effect on the date specified on the 
                                        notice for when the varied terms will become effective, or if no such date is 
                                        specified, then on the next occasion the User accesses the Website.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.7.</h4>
                                        <label>
                                        Your continued use of the Website or Services following any variation made to this 
                                        Agreement in accordance with its terms will be deemed to constitute your 
                                        acceptance of such variation. If you do not accept a variation to this Agreement, 
                                        you must discontinue your use of the Website and Services including your 
                                        Account.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.8.</h4>
                                        <label>
                                        This Agreement may be assigned or novated by Hapifyr to a Third Party with 
                                        written notice to Users (<b>Transfer Notice</b>). In the event of an assignment or 
                                        novation:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                the User automatically consents to the assignment or novation of this 
                                                Agreement to the Third Party and on the date specified on the Transfer 
                                                Notice;

                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                the rights and obligations being assigned or novated (as the case may be) 
                                                will be identical or to those in this Agreement; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                in the event of novation, from the date of the novation, Hapifyr will cease to 
                                                be obligated under this Agreement and the Third Party will become 
                                                responsible for those obligations, and the User’s rights under this Agreement 
                                                shall become enforceable against the Third Party and cease to be 
                                                enforceable against Hapifyr (except such obligations and rights that arose 
                                                from before the date of novation).

                                                </label>
                                            </div>

                                            </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.9.</h4>
                                        <label>
                                        Hapifyr may provide any notice required under this Agreement by publishing the 
                                        notice on the Website, except in respect of 2.6, 2.7, and 2.8 which must be 
                                        provided by email or post.
                                        </label>
                                    </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 3. Licence to use the Website and Services </h4></Accordion.Header>
                                    <Accordion.Body>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>3.1.</h4>
                                        <label>
                                        Hapifyr grants you a non-exclusive, non-transferable, revocable licence to use the 
                                        Website and Services subject to your compliance with your obligations as set out 
                                        in this Agreement. Other than as explicitly stated in this clause, the licence does 
                                        not grant you the right to provide access to the Services to any other person and 
                                        you must not:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                distribute, rent, loan, lease, sell, sublicense, transfer or otherwise exploit all 
                                                or any part of the Services, or any rights granted under this Agreement, to 
                                                any other person including your Account; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                modify, translate, adapt, arrange (or create any adaptation or modification) of 
                                                the Website or Services without our written consent.
                                                </label>
                                            </div>

                                            Any rights not expressly granted in this Agreement are reserved by us and our licensors.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>3.2.</h4>
                                        <label>
                                        The Website may contain links to Third Party websites or files, and the use of such 
                                        websites or files may be governed by terms and conditions set by the Third Party. 
                                        Hapifyr does not endorse, sponsor, approve, represent, or warrant the accuracy, 
                                        correctness, truth, or quality of any Content made available by a Third Party via the 
                                        Website or Services including on or in any linked Third-Party website or file. 
                                        Hapifyr expressly disclaims all responsibility and liability for any such Third-Party 
                                        Content including but not limited to damage, loss, costs, and expenses arising from 
                                        your reliance on or acceptance of such Third-Party Content or the terms and 
                                        conditions imposed by the Third Party.
                                        </label>
                                    </div>


                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>3.3.</h4>
                                        <label>
                                        In using the Website or Services, you acknowledge and agree that:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                Hapifyr may alter, amend, or cease the operation of the Website or Services 
                                                at any time in its sole discretion;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                the Website and Services may not operate on a continuous basis, and may 
                                                be unavailable from time to time (for reasons including but not limited to 
                                                scheduled or unscheduled maintenance, error, network or power outage);
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                registering an Account is free and Hapifyr does not charge a fee to create an 
                                                Account, or for Buyers to search for Workshops on the Website, or for Users 
                                                to otherwise view Content on the Website, subject to any fees set by Hosts;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                in its absolute discretion, Hapifyr may refuse to allow any person to create or 
                                                register for an Account and may cancel, suspend, or modify any existing 
                                                Account, for reasons including but not limited to Hapifyr reasonably forming
                                                the view that a User's conduct (including a breach of this Agreement) is 
                                                detrimental to the operation of the Website, Services, or other Users;

                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                when a Buyer purchases a Workshop via the Website, a Workshop Contract 
                                                is created between the Buyer and the Host at the time of purchase, without 
                                                imposing any liability or obligation on Hapifyr;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(f).</h4>
                                                <label>
                                                the Workshop Contract may also be subject to, or include additional Third-Party terms and conditions such as those set by the Host for the provision of their Workshop;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(g).</h4>
                                                <label>
                                                upon the purchase of a Workshop by a Buyer, Hapifyr shall be taken to have
                                                rendered Services for which the Services Fee is due and payable by the 
                                                Host;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(h).</h4>
                                                <label>
                                                all Workshops and Workshop Content promoted on the Website are provided 
                                                and delivered solely by the relevant Host and to the extent permitted by law, 
                                                Hapifyr shall not be responsible or liable for any loss or damage incurred by a 
                                                User in any manner due to the provision, performance, delivery (including the 
                                                non-provision, non-performance, non-delivery, cancellation or rescheduling) 
                                                of such Workshops;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(i).</h4>
                                                <label>
                                                any Third Party’s services are conditional upon, and governed by, the Third-Party service provider's terms and conditions; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(j).</h4>
                                                <label>
                                                no agency, partnership, joint venture, employee-employer, principal-and-contractor, fiduciary, or other similar relationship is created by this Agreement 
                                                including from your registration of an Account with us and you have no 
                                                authority to bind Hapifyr, its related entities or affiliates in any way whatsoever.
                                                </label>
                                            </div>

                                        </label>
                                    </div>


                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>3.4.</h4>
                                        <label>
                                        Hapifyr accepts no responsibility or liability and makes no representation or 
                                        warranty with respect to:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                any interaction between Hosts and Buyers, or your interaction with other 
                                                Users, including but not limited to the description, performance, or delivery of 
                                                Workshops, Workshop Content, or any aspect of a Workshop Contract; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                the truth or accuracy of any aspect of any information provided by other 
                                                Users including but not limited to the honesty, accuracy, or quality of any 
                                                information provided by a Host or Buyer or the Host's ability to provide the 
                                                Workshops or Workshop Content as promoted or as otherwise requested.
                                                </label>
                                            </div>
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>3.5.</h4>
                                        <label>
                                        The Website and Services (including any related documentation) is prepared on a 
                                        general basis which may not be appropriate or meet your, or your business’
                                        specific circumstances, requirements, or expectations, including that relating to 
                                        quality, products, services, information, or other material obtained through your use 
                                        of the Website and Services (including Third Party’s services). It remains your 
                                        responsibility to ensure that the Services (including any Third-Party’s services) 
                                        meet your or your business' needs.
                                        
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>3.6.</h4>
                                        <label>
                                        Marketing: You agree that Hapifyr and its related bodies corporate may contact 
                                        you with marketing and information about their products and services, discounts, 
                                        special offers, competitions, and invitations to special events, for an indefinite 
                                        period unless and until you opt out of receiving these communications. You agree 
                                        that any contact details provided by you can be used for these communications, 
                                        including to contact you by post, phone, email, and SMS. If at any time you wish to 
                                        opt out from receiving these communications, please let Hapifyr know by using the 
                                        “Opt Out” instructions in the Hapifyr Privacy Policy (available at www.hapifyr.com) 
                                        or any opt out means provided in the communications.
                                        
                                        </label>
                                    </div>


                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 4. Buyers </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>4.1.</h4>
                                        <label>
                                        It is solely the obligation of the Buyer to select an appropriate Host, Workshop, and 
                                        Workshop Content according to their needs including satisfying themselves of the 
                                        Host’s self-reported skills, qualifications, education or experience, reviews from 
                                        other Buyers, and the terms and conditions of a Workshop Contract. You 
                                        acknowledge and agree that Hapifyr has no responsibility or liability, and does not 
                                        make any representations or warranties with respect to:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                the quality, experience, professional licence/registration, or qualifications of any Host;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                the quality, standard, delivery, or provision of Workshops including the Host's 
                                                ability to provide Workshops, or supply or deliver products or services that 
                                                the Host has listed on the Website, or that they will be fit for purpose;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                Buyers finding a suitable Host or Workshop via the Website; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                the availability of Hosts and Workshops including such availability in any 
                                                given geographical location, time, or date.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>4.2.</h4>
                                        <label>
                                        Buyers warrant and represent that they are over 18 years of age and it is solely the 
                                        responsibility of the Buyer, if he or she is bringing or enrolling anyone in a 
                                        Workshop, or enrolling as someone in a Workshop under the age of 18, to ensure 
                                        that such minor (including if the Buyer is a minor) is supervised by and has the 
                                        consent of the minor’s Responsible parent or guardian.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>4.3.</h4>
                                        <label>
                                        It is solely the responsibility of the Buyer to exercise caution and consider personal 
                                        safety when interacting with and meeting other Users of the Website including not 
                                        disclosing personal details such as the Buyer’s full name, street number, phone 
                                        number, or email address in any public communication on the Website.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>4.4.</h4>
                                        <label>
                                        Buyers acknowledge and agree that the Workshops are provided on the terms and 
                                        conditions set by the Host under the Workshop Contract. In this respect, Hapifyr 
                                        shall not (to the extent permitted by law) be held responsible or liable for any loss, 
                                        damage, or injury to the Buyer, Host, or any of their property or successors as a 
                                        result of a User's participation in a Workshop or use of Workshop Content.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>4.5.</h4>
                                        <label>
                                        You acknowledge and agree that Pre-recorded Workshops are available for a 
                                        period of no more than 90 days following the purchase of a Pre-recorded 
                                        Workshop after which time the Workshop will expire and no longer be available to 
                                        you to watch. You acknowledge and agree that it is your sole responsibility to 
                                        watch any Workshops that you purchase within the 90-day period and that if you 
                                        do not watch the Pre-recorded Workshop within 90 days, you forego all rights to 
                                        access the Pre-recorded Workshop after this time.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>4.6.</h4>
                                        <label>
                                        You acknowledge and agree that cancellation and refunds for Workshops and 
                                        Workshop Content remains subject to the Host's cancellation or refund terms 
                                        under the Workshop Contract, which may include:

                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                no refunds will be given if you change your mind; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                refunds will occur within a reasonable time if your request for a refund is accepted by the Host.
                                                </label>
                                            </div>

                                            For clarity, Buyers acknowledge and agree that Hapifyr has no obligation to 
                                            provide any refund of the Services Fee associated with the purchase of a 
                                            Workshop, unless the refund is sought on the basis of Hapifyr’s failure to provide 
                                            the Services under the Australian Consumer law.
                                        </label>
                                    </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 5. Hosts </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.1.</h4>
                                        <label>
                                        This clause 5 applies to you if and whenever you use the Website as a Host.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.2.</h4>
                                        <label>
                                        Hosts acknowledge and agree that they must comply with this Agreement as a 
                                        User of the Website and Services generally (as such terms and conditions apply to them).
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.3.</h4>
                                        <label>
                                        Hapifyr does not make any representation or guarantee that Hosts will secure 
                                        business from Buyers via the Website. Hapifyr provides no assurance that a Host 
                                        will enjoy any financial outcome or result from using the Website or providing 
                                        Workshops via the Website.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.4.</h4>
                                        <label>
                                        It is solely the obligation of the Host to:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                acquire any skills, knowledge, qualifications, professional licence/registration,
                                                or experience required to perform the Workshops at the Host’s sole expense;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                exercise caution and consider personal safety when interacting and meeting with Buyers; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                control and supply the methods, materials, and all aspects required to 
                                                perform the Workshops and provide the Buyers with instructions, material 
                                                lists, and information for them to be able to participate in the Workshop.

                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.5.</h4>
                                        <label>
                                        At the sole discretion of Hapifyr, a Host of a Workshop may be removed from the 
                                        Website for reasons including but not limited to complaints made by Buyers or if 
                                        the Host breaches these Terms or any applicable law.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.6.</h4>
                                        <label>
                                        A Host must not encourage, solicit, or request a Buyer to pay for any Workshop 
                                        outside of the Website or in any manner which would deprive Hapifyr of the 
                                        Services Fee. This is not intended to exclude circumstances where a Buyer 
                                        chooses, of their own volition without encouragement or request from the Host, to 
                                        purchase the same or similar Workshops or materials to those advertised on the 
                                        Website directly from the Host through other means, such as for example via the 
                                        Host's website or a Third-Party site.

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.7.</h4>
                                        <label>
                                        A Host must not publish the reviews that they obtain through Hapifyr on any other 
                                        website, platform, or medium, without the prior written consent of Hapifyr. This 
                                        condition survives termination or expiry of the Host's agreement with Hapifyr.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.8.</h4>
                                        <label>
                                        Hosts may not permit other Users to use their Account and must not authorise any 
                                        other person to use the Services without our express written consent.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.9.</h4>
                                        <label>
                                        Hosts acknowledge and agree that they remain responsible and liable for:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                obtaining all necessary authorisations, licenses, permits, qualifications, 
                                                permissions, consents (including from Buyers) in order to provide Workshops 
                                                and Workshop Content in accordance with their Workshop Contract;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                collecting, using, disclosing, or otherwise handling any Personal Information 
                                                of Buyers in compliance with and the Host’s your privacy policy, the Privacy 
                                                Act 1988 (Cth), and any other applicable law;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                administering and properly entering into all relevant separate agreements 
                                                with Buyers (including their Workshop Contract);
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                maintaining and obtaining any relevant insurance required for the purpose of 
                                                providing the Workshops and Workshop Content; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                corresponding with and resolving disputes with Buyers including authorising 
                                                and confirming any refunds, cancellations, rescheduling and replacements of 
                                                Workshops and Workshop Content with Buyers under a Workshop Contract, 
                                                and complying fully with the Host’s obligations as the “<b>supplier</b>” of the 
                                                Workshops and Workshop Content under the Australian Consumer Law.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.10.</h4>
                                        <label>
                                        Hosts represent and warrant that:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                they have the right to enter into the Host’s Workshop Contract including the 
                                                right and ability to provide the Workshops and Workshop Content;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                have all necessary authorisations, licenses, permits, qualifications, 
                                                permissions, consents, approvals required by applicable law including in 
                                                order to provide the Workshops, Workshop Content and Workshop Contracts 
                                                in the jurisdiction of delivery and handle any Personal Information obtained 
                                                from Buyers;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                they and their provision of Workshops, Workshop Content and the Workshop 
                                                Contract complies with all applicable laws (including privacy, tax, employment 
                                                and other regulatory obligations relating to them and their business) and does 
                                                not involve any illegal or immoral activities; and

                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                that all Workshop Content and any other information provided by them is true and accurate.
                                                </label>
                                            </div>

                                        </label>
                                    </div>
                                
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 6. Fees & Payment </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.1.</h4>
                                        <label>
                                        Buyers are responsible for payment of the Price which shall be processed by 
                                        Hapifyr at the time of purchasing a Workshop or Workshop Content via the Website.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.2.</h4>
                                        <label>
                                        Upon a Buyer purchasing a Workshop or Workshop Content, the Host owes 
                                        Hapifyr the respective Services Fee which will be deducted and retained by Hapifyr 
                                        from the Workshop Price and the remaining Host Funds (subject to any reductions
                                        under this Agreement) will be available to be remitted to the Host's nominated bank 
                                        account for payment of the relevant Workshop or Workshop Content by the Buyer 
                                        as follows:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                for a Workshop (or Workshop Content) provided online (live), on the next 
                                                Business Day after such Workshop (or Workshop Content) has been 
                                                completed;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                for a Workshop (or Workshop Content) provided online (pre-recorded), 7 
                                                Business Days following the purchase of such Workshop (or Workshop 
                                                Content) made by the Buyer; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                as otherwise agreed with Hapifyr in writing.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.3.</h4>
                                        <label>
                                        The Host Funds due to be remitted to the Host under clause 6.2 will be remitted 
                                        when the Host elects to do so via their Account on the Website.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.4.</h4>
                                        <label>
                                        Until such time that Host Funds are paid to the Host under clause 6.2, those 
                                        unremitted funds will be treated as forming part of the Host’s Stored Value, which 
                                        can be used by the Host to purchase Workshops and Workshop Content provided 
                                        by other Hosts. If the Host uses the Stored Value for such purchases, the Host 
                                        Funds due to the remitted to the Host, will be reduced by the corresponding Price 
                                        for the Workshop and Workshop Content purchased by the Host.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.5.</h4>
                                        <label>
                                        Unless otherwise approved by Hapifyr (in its sole discretion), all Services Fees 
                                        payable to Hapifyr are non-cancellable and non-refundable, save for your rights 
                                        under any Non-excludable Conditions.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.6.</h4>
                                        <label>
                                        Hapifyr may restrict a User's Account until all Services Fees have been paid.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.7.</h4>
                                        <label>
                                        Hosts remain responsible for handling any cancellations of Workshops or orders of 
                                        Workshop Content (whether by a Buyer or the Host) as well as payment of any 
                                        refunds, returns, exchanges, or replacements made in relation to Workshops or 
                                        Workshop Content.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.8.</h4>
                                        <label>
                                        To the extent permitted by law or unless otherwise agreed, Hapifyr has no 
                                        obligation to provide, nor shall it be responsible or liable for, any cancellation of 
                                        Workshops or orders of Workshop Content (whether by a Buyer or the Host) or the 
                                        handling or payment of any refunds, returns, exchanges, re-delivery or 
                                        replacements made in relation to Workshops or Workshop Content. However, 
                                        Hapifyr may process refunds to Buyers as authorised in writing by Hosts (such as 
                                        due to a cancellation made by a Buyer prior to when the Workshop was delivered). 
                                        When refunding a transaction that used or partially used Stored Value, Hapifyr will 
                                        at its discretion refund you in any combination of Stored Value and/or money 
                                        equivalent to the refund amount, with the credit component being up to the value of 
                                        the Stored Value used in the transaction.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.9.</h4>
                                        <label>
                                        Users acknowledge and agree that any refunds processed by Hapifyr to Buyers 
                                        (whether in the form of Stored Value or as part of the Money-Back Guarantee) 
                                        does not in any way affect, limit, diminish, or otherwise vary or change the roles, 
                                        responsibilities and liabilities of Hapifyr and other Users (whether as a Buyer or 
                                        Host) as set out in this Agreement (unless otherwise required by law).
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.10.</h4>
                                        <label>
                                        Services Fees do not include any fees that may be due to Third Party Service 
                                        providers and unless otherwise agreed with Hapifyr are paid pursuant to a User's 
                                        separate agreement with that Third Party Service provider.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.11.</h4>
                                        <label>
                                        If a User refers a Workshop or Workshop Content to another User (whether an
                                        existing User or a new User), the referring User (Referrer) shall be entitled to be
                                        paid 5% of the Services Fee resulting from the purchase of the referred
                                        Workshop or Workshop Content (Referral Incentive). In this regard the Referral
                                        Incentives:
                                        <br />

                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                    will accrue and be available for remittance to the Referrer’s nominated bank
                                                    account at time periods set out in as clause 6.2 in respect of the Workshop or
                                                    Workshop Content that was referred; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                    will be remitted to the Referrer, and may accrue and be used as Stored
                                                    Value, in the same manner as under clauses 6.3 and 6.4 (where “Host
                                                    Funds” is replaced with “Referral Incentives”).
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.12.</h4>
                                        <label>
                                        If a User refers a new User (Referred User) using a unique link, and the Referred 
                                        User subsequently creates and sells a workshop on hapifyr.com, then the 
                                        Referral User (Referrer) shall be entitled to be paid 5% of the Services Fee resulting
                                        from all future purchases of the Referred User (Referral Incentive). 
                                        In this regard the Referral Incentives:

                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                    will accrue and be available for remittance to the Referrer’s nominated bank 
                                                    account at time periods set out in clause 6.2 in respect of the Workshop or 
                                                    Workshop Content created by the Referred User; and 
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                    will be remitted to the Referrer, and may accrue and be used as Stored Value,
                                                    in the same manner as under clauses 6.3 and 6.4 (where ‘'Host Funds” is replaced by “Referral Incentives”). 
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 7. Conduct and General User Obligations </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.1.</h4>
                                        <label>
                                        In using the Website and Services, you represent and warrant that you:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                have the full capacity, legal right, power and authority to accept and comply 
                                                with this Agreement, Workshop Contracts, and all applicable laws, and to do 
                                                all acts and things and execute and deliver all other documents as required 
                                                hereunder to be done, observed or performed by you in accordance with its terms;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                will maintain the security and confidentiality of your Account login details, 
                                                password and associated identification;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                in registering an Account, you will provide, or have provided, and will 
                                                maintain and promptly update, your Registration Data and ensure that all 
                                                such information provided is accurate, correct, current, and complete;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                all information provided by you including your Content is accurate, correct, 
                                                current and complete and does not violate applicable law or infringe any 
                                                person's Intellectual Property rights; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                that you have the right to use and provide the licenses under this Agreement 
                                                including obtaining all relevant authorisations, permissions, licenses, and 
                                                consents to supply and use your Content as contemplated in this Agreement 
                                                and that our use of your Content does not violate any applicable laws or 
                                                rights (including Intellectual Property rights) of any third party.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.2.</h4>
                                        <label>
                                        You are solely responsible for all information including your Content supplied or 
                                        otherwise made available by you in using the Website or Services and all activities, 
                                        interactions, and correspondence (including disputes) you engage in with other 
                                        Users including with respect to Workshops, Workshop Content, and Workshop
                                        Contracts.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.3.</h4>
                                        <label>
                                        You agree not to advertise, supply, upload, post, publish or otherwise transmit, 
                                        provide, or make available Content on the Website or in using the Services that:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                is inaccurate, false, misleading, deceptive, harmful, obscene, pornographic, 
                                                defamatory, threatening, violent, offensive, abusive, indecent, unlawful, 
                                                harassing, or otherwise objectionable to Hapifyr or other Users of the 
                                                Website or Services, including any Content which is likely to discriminate, 
                                                offend, insult, or humiliate others based on race, religion, ethnicity, gender, 
                                                age, sexual orientation, or any physical or mental disability;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                includes unauthorised disclosure of personal information;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                advertises services including for non-individuals or for any other reason 
                                                which is not aligned to the purposes for which the Services are intended;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                infringes the Intellectual Property rights of any person; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                contains malicious code, software viruses, or any other computer code, files,
                                                or programs designed to interrupt, destroy, intercept, interfere with, or limit 
                                                the functionality of any computer software or hardware or 
                                                telecommunications equipment.
                                                </label>
                                            </div>

                                            Hapifyr may remove from the Website any Content that is in breach of any of the 
                                            subclauses (a) to (e) above, at Hapifyr’s sole discretion, without incurring any 
                                            liability to you or any other User (including Buyers of the offending Workshop or 
                                            Workshop Content). If any Buyer requests a refund of the Price or part thereof, for 
                                            the purchase of an offending Workshop or Workshop Content, such refund must 
                                            be claimed against the Host and the Host shall be solely liable to pay the refund.

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.1.</h4>
                                        <label>
                                        You must:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                immediately notify Hapifyr of any unauthorised use or access to your 
                                                Account, or the unauthorised disclosure or use of your Account’s login 
                                                credentials;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                completely sign out of your Account at the end of every session;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                maintain control of your Account at all times including not allowing others to 
                                                use your Account, or by transferring or selling your account or any of its 
                                                content to another person;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                comply with this Agreement and all applicable laws and regulations;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                only supply and submit correct, accurate, and true information when using the Website and Services; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(f).</h4>
                                                <label>
                                                promptly provide and cooperate with our reasonable requests including 
                                                requests for information in order to provide the Services.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.5.</h4>
                                        <label>
                                        Hapifyr may, but is not obliged to, at any time request a form of identification to 
                                        verify your identity and/or your compliance with this Agreement.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.6.</h4>
                                        <label>
                                        Hapifyr reserves the right in its sole discretion to remove, or request you to edit or 
                                        remove, your Content that violates this Agreement or applicable law or infringes a 
                                        person's Intellectual Property rights or for any other reason it deems is necessary 
                                        or is otherwise unlawful or inappropriate in its absolute discretion.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.7.</h4>
                                        <label>
                                        Hapifyr may record and store information relating to your use of the Website or 
                                        Services, including any complaint made against you. Hapifyr reserves the right to 
                                        disclose any information (including your Personal Information) as necessary to 
                                        satisfy any applicable law, legal requirement, police investigation of a crime that 
                                        may have been committed by you or for which you may be an accessory, or 
                                        request from a Government authority. For further information regarding Hapifyr's 
                                        use of your personal Information, please see Hapifyr’s Privacy Policy at
                                        <Link to="https://Hapifyr.com/terms-and-conditions" className={genStyles.LinkTagDirect}>https://Hapifyr.com/terms-and-conditions</Link>.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.8.</h4>
                                        <label>
                                        You must not:

                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                use the Website or Services for any illegal or immoral purposes including 
                                                pretending that you are or represent someone else, or impersonate another person;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                copy, download, store or otherwise disseminate any Content (whether 
                                                provided by Hapifyr, another User or a third party) on the Website or 
                                                otherwise made available via the Services including on third party sites or for 
                                                other business purposes without Hapifyr's prior permission;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                attempt to gain access to the Services or related systems or networks other 
                                                than via the public-facing Website, or in any other manner which is not 
                                                permitted by this Agreement;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                post, transmit, or otherwise make available through or in connection with the 
                                                Website or Services any virus, spyware or other harmful computer code, files, 
                                                scripts agents or programs;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                restrict or inhibit any other person or entity from using the Services;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(f).</h4>
                                                <label>
                                                reverse engineer, decompile, dissemble, frame, or mirror any portion of the 
                                                Website or Services, or otherwise incorporate any portion of the Website or 
                                                Services into any product or service including using any robot, spider, site 
                                                search/retrieval application or other manual or automatic device to retrieve, 
                                                index, “scrape,” “data mine” or otherwise gather the Website or Services 
                                                content or data, reproduce or circumvent the navigational structure or 
                                                presentation of the Services or systematically download and/or store 
                                                Services content or data;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(g).</h4>
                                                <label>
                                                conduct any port scanning, denial-of-service attack, security testing, or 
                                                otherwise search for or exploit any vulnerability that exists on the Website or 
                                                any of its associated servers or network infrastructure, without our express 
                                                prior written consent;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(h).</h4>
                                                <label>
                                                share any Confidential Information, trade secrets or any other Intellectual 
                                                Property belonging to Hapifyr with any Third Party; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(i).</h4>
                                                <label>
                                                imply any affiliation or endorsement of you, or any organization, by us except 
                                                with our express written consent.
                                                </label>
                                            </div>
                                           
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.9.</h4>
                                        <label>
                                        You agree you remain responsible and liable for:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                all activity occurring under your Account, including preventing unauthorised 
                                                access to, or use of, your Account;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                obtaining, maintaining, and paying for any hardware, telecommunications, 
                                                Internet and other services or equipment needed to use the Services, attend 
                                                or administer Workshops or otherwise provide or use Workshop Content (as 
                                                the case may be); and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                the provision, maintenance, truth, accuracy, quality, security, and protection 
                                                (including back-ups) of any Content provided by you when using the Website.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 8. Confidential Information </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>8.1.</h4>
                                        <label>
                                        You agree to keep the Confidential Information of Hapifyr and any other User 
                                        confidential and to only use or disclose such information:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                for the purposes and to the extent necessary to use the Services, if the 
                                                Confidential Information belongs or pertains to Hapifyr;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                for the purposes of providing the Workshop or Workshop Content to a User, if 
                                                the Confidential Information belongs or pertains to a User;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                in accordance with the prior written consent of Hapifyr or the User to whom 
                                                the Confidential Information belongs or pertains; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                to comply with any lawful order of a court or tribunal of competent jurisdiction, 
                                                or lawful direction or warrant issued by a government regulator or law 
                                                enforcement agency, in which case you must provide written notice to Hapifyr 
                                                of the order, direction, or warrant as soon as you become aware of the 
                                                obligation (if it is lawful for you to do so).
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>8.2.</h4>
                                        <label>
                                        The obligations of confidentiality in clause 8.1 will not apply to information which:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                is generally available in the public domain except where such availability is as 
                                                a result of a breach of these Terms or any other confidential obligation;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                was already lawfully known, received, or in your possession prior to the 
                                                disclosure of the information to you by Hapifyr or a User;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                is required to be disclosed by an applicable law or court order; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                was independently developed by you.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 9. Intellectual Property Rights </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.1.</h4>
                                        <label>
                                        Nothing in this Agreement constitutes a transfer of any of Hapifyr's Intellectual 
                                        Property rights to you. You acknowledge and agree that, as between you and 
                                        Hapifyr, and subject to the Intellectual Property rights owned by you or a Third 
                                        Party in your Content, Hapifyr owns all Intellectual Property rights existing in or 
                                        otherwise created as part of providing the Website and Services (including any 
                                        modification, developments or enhancements to such Intellectual Property).
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.2.</h4>
                                        <label>
                                        By using the Services and the Website, you grant Hapifyr an unrestricted, 
                                        perpetual, non-exclusive, royalty-free, irrevocable, worldwide, and transferable 
                                        right and licence to use, modify, copy, distribute, or incorporate your Content (with 
                                        a right to sublicense on the same terms to our affiliates, employees, contractors 
                                        and other third parties that may provide services to us) for any purpose in 
                                        connection with providing the Website and Services under this Agreement 
                                        (including, without limitation, reproducing, changing, modifying, adapting and 
                                        communicating your Content to the public as required to provide the Website and 
                                        Services and for the general promotion of the Website and Services).
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.3.</h4>
                                        <label>
                                        You represent and warrant to Hapifyr that you have all necessary rights to grant 
                                        the licences and consents set out in clause 9.2 and that Hapifyr's use of your 
                                        Content as contemplated in this Agreement shall not infringe the Intellectual 
                                        Property or Moral Rights of any person.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.4.</h4>
                                        <label>
                                        All Hosts represent and warrant that they have the right or have obtained the 
                                        necessary permission to utilise, supply or sell any goods, material, content, or 
                                        other proprietary material used for the purposes of providing their Workshops 
                                        including Workshop Content. Hapifyr will not be held responsible or liable for a 
                                        Host’s infringement or violation of any other person's proprietary rights including 
                                        Intellectual Property rights (and Moral Rights).

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.5.</h4>
                                        <label>
                                        You must obtain express written permission from Hapifyr if you wish to copy, 
                                        reproduce, publish, exploit, or otherwise disseminate any aspect of the Website, 
                                        Services or other Intellectual Property owned by Hapifyr or another User or Third Party.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.6.</h4>
                                        <label>
                                        In addition to clause 9.5, if you wish to copy, reproduce, publish, exploit, or 
                                        otherwise disseminate any Workshop or Workshop Content of another User, you 
                                        must liaise and obtain consent from that User. Your licence or transfer of 
                                        Intellectual Property rights to or from that User shall be a matter between you and 
                                        the User, save that such licence or transfer shall not limit or cease any licence and 
                                        rights granted to Hapifyr under clause 9.2.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.7.</h4>
                                        <label>
                                        All Intellectual Property rights in a Third-Party Service remains with and is provided 
                                        on the terms of that Third Party Service provider.
                                        </label>
                                    </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 10. Terms, Termination and Suspension </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.1.</h4>
                                        <label>
                                        This Agreement will continue in force until such time that it is varied or replaced.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.2.</h4>
                                        <label>
                                        Subject to any other termination rights agreed between you and Hapifyr with 
                                        respect to the Services that may apply:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                you or Hapifyr may terminate this Agreement at any time by giving written 
                                                notice to the other, or by using the Account cancellation feature provided on 
                                                the Website (if any); and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                Hapifyr may limit or suspend any User from using their Account or the 
                                                Services at any time and without prior notice.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.3.</h4>
                                        <label>
                                        Hapifyr reserves the right to change or discontinue any Service or feature on the 
                                        Website in whole or in part at any time in its sole discretion.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.4.</h4>
                                        <label>
                                        Hapifyr reserves the right to change pricing for any chargeable Service or feature 
                                        on the Website at any time, and will provide notice in accordance with this 
                                        Agreement where such price is varied.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.5.</h4>
                                        <label>
                                        Termination or suspension of this Agreement does not affect any Workshop 
                                        Contract that has been formed between Users.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.6.</h4>
                                        <label>
                                        If your Account, the Services, or this Agreement are terminated or suspended for 
                                        any reason then you may not without Hapifyr's consent (in its absolute discretion) 
                                        create any further accounts with Hapifyr and we may terminate any other accounts 
                                        you operate.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.7.</h4>
                                        <label>
                                        Upon termination of this Agreement:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                you must stop using your Account, the Services, and our Confidential Information;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                you must immediately pay any unpaid Services Fees; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                any other terms which by their nature should continue to apply, will survive 
                                                any termination or expiration of this Agreement.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.8.</h4>
                                        <label>
                                        You acknowledge and agree that we are not responsible or liable for any damages, 
                                        liabilities, losses incurred by you as a result of termination, suspension, or 
                                        modification of this Agreement, the Services, or your Account by Hapifyr on the 
                                        terms of this Agreement, including in relation to loss or damage to your Content 
                                        and Hapifyr is not obliged to provide access to, return, or destroy your Content.
                                        </label>
                                    </div>
                                    
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 11. Limitation of Liability </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.1.</h4>
                                        <label>
                                        You will be responsible for and will indemnify Hapifyr against all demand, claim, 
                                        loss, damage, cost, or expense (including legal costs and expenses on a lawyer-and-client basis) arising from your breach of any provision of this Agreement. For 
                                        clarity and to the extent permitted by law, your indemnity in favour of Hapifyr 
                                        extends to consequential and indirect damages suffered by Hapifyr where:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                you breach your obligations or infringe Hapifyr’s rights under clauses 7.3
                                                (obligations regarding your Content), 8 (confidentiality), 9 (intellectual 
                                                property); or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                you breach any law when using the Website or Services, or are grossly 
                                                negligent in the production or delivery of Workshops or Workshop Content, or 
                                                cause personal injury or death to any User or Third Party relying upon your 
                                                Workshop or Workshop Content.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.2.</h4>
                                        <label>
                                        Hapifyr reserves the right, but has no obligation, to intervene in disputes between 
                                        Users of the Website. You agree to hold Hapifyr harmless in connection with any 
                                        dispute or claim that you make against any other User.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.3.</h4>
                                        <label>
                                        Without limitation to the foregoing, you agree that, except for liability in relation to a 
                                        breach of any Non-excludable Condition, Hapifyr’s total maximum aggregate 
                                        liability, if any, arising out of any kind of legal claim or action under this Agreement, 
                                        or in any way connected to the Services or the Website, to you (whether in 
                                        contract, tort (including negligence), equity, statute or otherwise) will be limited to 
                                        the greater of:

                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                    AU$50.00; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                the total Services Fees received by Hapifyr from you during the 12 months 
                                                immediately prior to Hapifyr’s liability arising.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.4.</h4>
                                        <label>
                                        Except for liability in relation to any Non-excludable Condition, the Website and 
                                        Services is provided on an "as is" basis, and without any warranty or condition, 
                                        express or implied. To the extent permitted by law, Hapifyr specifically disclaims 
                                        any implied warranties of title, merchantability, fitness for purpose (whether 
                                        purpose is expressly made known or not), quality, and non-infringement.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.5.</h4>
                                        <label>
                                        Hapifyr's liability to you for a breach of any Non-excludable Condition (other than a 
                                        Non-excludable Condition that by law cannot be limited) is limited, at our option to 
                                        any one of resupplying the Services or refunding you the Services Fee paid in 
                                        respect of which the breach occurred, or supplying again or paying the cost of 
                                        supplying again, services in respect of which the breach occurred.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.6.</h4>
                                        <label>
                                        Except for liability in relation to breach of any Non-excludable Condition, Hapifyr 
                                        excludes all liability for loss of revenue, savings, business, opportunity, reputation, 
                                        data or any other special, consequential or indirect loss arising out of or in 
                                        connection with this Agreement or your use of the Services or Website including 
                                        that arising out of or in any way connected with any transaction between you and 
                                        any Third Party Service provider.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.7.</h4>
                                        <label>
                                        Except for liability in relation to breach of Non-excludable Condition, to the extent 
                                        permitted by law, Hapifyr specifically disclaims all liability for any loss or damage 
                                        (actual, special, direct, indirect and consequential) of every kind and nature, known 
                                        and unknown, suspected and unsuspected, disclosed and undisclosed (including, 
                                        without limitation, loss or damage relating to any inaccuracy of information or 
                                        content provided, or the lack of fitness for purpose of any goods or service 
                                        supplied), arising out of or in any way connected with any transaction between you 
                                        and another Third Party including another User.
                                        </label>
                                    </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 12. Disclaimer of Warranties </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>12.1.</h4>
                                        <label>
                                        You acknowledge and agree the Website and Services are provided over the 
                                        internet and that Hapifyr makes no representation or warranty:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                that your access to the Website (including any Third-Party applications or 
                                                payment platforms on the Website) will be timely, secure, uninterrupted, or free from defects;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                that any information supplied on the Website or via the Services by another 
                                                User will be accurate, up to date, complete, or useful; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                that the Website and Services (including the server which stores and 
                                                transmits the Website and Services to you) are free from viruses or other 
                                                harmful components, however we will use reasonable commercial efforts to 
                                                enable you to use the Website and Services in accordance with this 
                                                Agreement and to protect the security and integrity of the Website and 
                                                Services.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 13. Assumption of Risk </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>13.1.</h4>
                                        <label>
                                        You agree and understand that you assume all risks in using the Website or 
                                        Services, including without limitation any and all of the risks associated with any 
                                        online or offline interactions with other Users (including disputes and reviews or 
                                        comments made by other Users), Workshops, Workshop Content, the Workshop 
                                        Contract and all risk for any damage to your computer system, property or loss of 
                                        data.
                                        </label>
                                    </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 14. Gift Cards </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>14.1.</h4>
                                        <label>
                                        Users may purchase gift cards in the form of Stored Value or otherwise be 
                                        provided credit in the form of Stored Value. Stored Value:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                can be used by the credited User to purchase Workshops or Workshop 
                                                Content via the Website;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                are not refundable or redeemable for cash (other than Stored Value equalling the accumulated 
                                                Host Funds and Referral Incentives that have not been paid to you in monetary form);
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                cannot be replaced, exchanged, or reloaded, or transferred to another card or 
                                                Account or any Third Party;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                are valid for 3 years from the date on which the Stored Value is purchased 
                                                (or any longer period prescribed by law);
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                must not be reproduced, copied, distributed, or published directly or indirectly 
                                                in any form or by any means for use by an entity other than the credited User 
                                                without Hapifyr's prior written permission.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>14.2.</h4>
                                        <label>
                                        The User credited with Stored Value is solely responsible for the security of any 
                                        Stored Value. Save for the Non-excludable Conditions, Hapifyr will have no liability 
                                        for any loss or damage to the Stored Value and does not have any obligation to 
                                        replace Stored Value.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>14.3.</h4>
                                        <label>
                                        Hapifyr will not accept, and may refuse or cancel, any Stored Value, which it 
                                        reasonably determines in its discretion, has been used in breach of this Agreement 
                                        or has been forged, tampered with, or is otherwise fraudulent and Hapifyr reserves 
                                        the right to refer any suspected fraudulent activity to relevant law enforcement 
                                        authorities. In particular, Stored Value, such as promotional coupons, vouchers, 
                                        codes, gift cards distributed or circulated without our approval, for example on an 
                                        internet message board or on a "bargains" website, are not valid for use and may 
                                        be refused or cancelled.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>14.4.</h4>
                                        <label>
                                        Hapifyr is entitled to any value of Stored Value which is not redeemed before the 
                                        Stored Value expires or is cancelled by Hapifyr.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>14.5.</h4>
                                        <label>
                                        Users acknowledge and agree that use of Stored Value to purchase Workshops or 
                                        Workshop Content via the Website does not in any way affect, limit, diminish or 
                                        otherwise vary or change the roles, responsibilities and liabilities of Hapifyr and 
                                        other Users (whether as a Buyer or Host) as set out in this Agreement.
                                        </label>
                                    </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 15. Third Party Services </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>15.1.</h4>
                                        <label>
                                        Hapifyr may from time to time include, feature or otherwise make available Third-Party Services via the Website or Services. Such Third-Party Services are not 
                                        provided by Hapifyr and may be featured or otherwise made available via the 
                                        Website or Services as a convenience to our Users who may find the Third-Party 
                                        Services of interest or of use.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>15.2.</h4>
                                        <label>
                                        Third Party Services are provided to Users pursuant to the Third-Party Service 
                                        provider's terms and conditions (including their privacy policy).
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>15.3.</h4>
                                        <label>
                                        If a User uses a Third-Party Service, or otherwise engages with any Third-Party 
                                        Service provider, then such agreement will be directly between the User and that 
                                        Third Party provider. You remain responsible for ensuring you review, comply with 
                                        and agree to such Third-Party Service provider's terms.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>15.4.</h4>
                                        <label>
                                        Hapifyr makes no representation or warranty as to the Third-Party Services. 
                                        However, to help us continue to improve the Website or Services, Users may 
                                        inform Hapifyr of their Third-Party Service experience.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>15.5.</h4>
                                        <label>
                                        To the extent permitted by law, Hapifyr specifically disclaims all liability for any loss 
                                        or damage incurred by you in any manner due to the performance or non-performance of such Third Party Service and disputes with any Third Party Service 
                                        provider must proceed pursuant to any dispute resolution process set out in the 
                                        terms of service of the Third Party Service provider.
                                        </label>
                                    </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <Accordion className={genStyles.TermsAccordian}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 16. Disputes </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>16.1.</h4>
                                        <label>
                                        f a dispute arises under this Agreement, the parties will attempt to resolve the 
                                        dispute in good faith, prior to commencing any form of litigation or legal 
                                        proceedings.
                                        </label>
                                    </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                            </div>
                        </Tab.Pane>


                        <Tab.Pane eventKey="second">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 1. Definitions </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                        “<b>Account</b>” means an account created by Hapifyr for a User to access and use the 
                                        Services whether as a Buyer or Host which includes a Username and password.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Agreement</b>” means the terms of the agreement between Users and Hapifyr as defined in clause 2.1 below.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Australian Consumer Law</b>” means Schedule 2 of the Competition and Consumer Act 2010 (Cth).
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Authorised Users</b>” means a User's agents, directors, other officers, employees, or 
                                        contractors who are allowed by the User to use the Website on the User’s behalf.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Business Day</b>” means a day that is not a Saturday, Sunday, or public holiday in 
                                        Victoria, Australia.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Buyer</b>”  means a User seeking to purchase and watch a Workshop.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Confidential Information</b>” means any information of Hapifyr, or of another User, which 
                                        is designated, marked, or denoted as confidential, or which is of a confidential or 
                                        sensitive nature or which a reasonable person to whom that information is disclosed or 
                                        to whose knowledge the information comes would consider confidential.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Content</b>” means anything (including but not limited to all material, information, images, 
                                        videos, data) that is uploaded, published, submitted, supplied, posted or otherwise 
                                        transmitted, provided or made available via the Website or Services (whether by Hapifyr
                                        or a third party). When such content is uploaded, published, submitted, supplied, posted 
                                        or otherwise transmitted, provided or made available by you (or your Authorised User) in 
                                        using the Website or Services including your Registration Data, it is "<b>Your Content</b>".
                                        Content that forms part of a Workshop is referred to as “<b>Workshop Content</b>”.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Hapifyr</b>” is a trading name of MingleIn Pty Ltd ABN 79 128 714 849. Hapifyr may alsobe referred to as "<b>we</b>", "<b>us</b>", "<b>our</b>".
                                        <hr className={genStyles.TermslineHR}/>
                                        
                                        “<b>Host</b>” means a User advertising, promoting, selling, or otherwise providing Workshops 
                                        or Workshop Content via the Website.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Host Funds</b>” means the Workshop Price less the Services Fee.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Intellectual Property</b>” means all intellectual property rights (including, without 
                                        limitation, all registered and unregistered copyright, designs, trademarks and patents) of 
                                        any nature including in any technology, trade secrets, Hapifyr Content, User Content, 
                                        User lists, User details (including, but not limited to, User emails, telephone numbers 
                                        and addresses) or other User-related information, software, program, inventions, 
                                        designs, works, or in any other subject matter belonging to Hapifyr or in which 
                                        intellectual property rights exist.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Money-Back Guarantee</b>” means the money-back guarantee which Hapifyr may 
                                        provide to Buyers as part of providing its Website and Services, as determined in its 
                                        absolute and sole discretion and subject to any conditions as further detailed on the 
                                        Website.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Moral Rights</b>” means moral rights as conferred by the Copyright Act 1968 (Cth) and 
                                        rights of a similar nature anywhere else in the world.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Non-excludable Condition</b>” means any implied condition, warranty or guarantee in a 
                                        contract, the exclusion of which would contravene the law or cause any part of the 
                                        contract to be void.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Personal Information</b>” has the same meaning as defined in the Privacy Act 1988 (Cth).
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Pre-recorded Workshop</b>” or “<b>Pre-recorded Experience</b>” means any Workshop or 
                                        Experience that is not streamed live, but pre-recorded by the Host and viewed by a User 
                                        afterward.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Price</b>” means the price prescribed by a Host for a Workshop on the Website to be paid 
                                        by the Buyer at the time of purchase (but may not include any additional costs incurred 
                                        by the Host which the Buyer separately agrees to reimburse).
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Referral Incentive</b>” means the referral incentives set out in clause 6 .
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Registration Data</b>” means the information provided by you in order to register an 
                                        Account on the Website or otherwise.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Services</b>” means Hapifyr providing access to the Website to Users.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Services Fees</b>” means the portion of the Price to be paid as a fee by the Host to Hapifyr 
                                        in consideration for use of the Services at either: (i) the default rate specified at 
                                        hapifyr.com; or (ii) at the rate otherwise agreed with Hapifyr in writing.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Stored Value</b>” means the physical or virtual card, coupon, voucher, or code containing 
                                        credit or a discount or refund provided as credit, including gift cards, for use on the 
                                        Website.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Terms</b>” means these terms of use.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Third Party</b>” means an individual or entity who is not a party to this Agreement, such as 
                                        Users other than you, but does not include Hapifyr’s subsidiaries.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Third Party Service</b>” means services provided by a Third Party that may be featured 
                                        on or otherwise made available via the Website or as part of the Services from time to 
                                        time (whether by Hapifyr or by other Users such as Sellers providing Online Workshops 
                                        to Buyers).
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>User</b>” means any individual or entity (whether as a Buyer, Host, or any other person) 
                                        who accesses or uses the Website or Services, regardless of the nature of that access
                                        or use, including if such person is or is not identified to Hapifyr. Also referred to as "<b>you</b>", "<b>your</b>".
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Website</b>” means Hapifyr's marketplace platform at <Link to="https://ui.hapifyr.com" className={genStyles.LinkTagDirect}>https://Hapifyr.com</Link> (or any new site) 
                                        including all associated application(s) (including mobile applications), webpages, 
                                        software (including software-as-a-service) or any other web services or media managed 
                                        by us.
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Workshop</b>” or “<b>Workshops</b>” as well as “<b>Experience</b>” or “<b>Experiences”</b>” means the Online Workshops, including 
                                        all associated goods and/or services, advertised by Users via the Website and provided by Hosts to Buyers 
                                        (whether via online access or in-person).
                                        <hr className={genStyles.TermslineHR}/>

                                        “<b>Workshop Contract</b>” means a separate contract created between a Host and a Buyer for the provision of an 
                                        Online Workshop, which may include Third Party terms and conditions of use or access.
                                        <br/>

                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>


                        <Tab.Pane eventKey="third">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 2. General </h4></Accordion.Header>
                                <Accordion.Body className={genStyles.TermsAccordianBody}>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.1.</h4>
                                        <label>
                                        These Terms, together with Hapifyr's Privacy Policy located at
                                        &nbsp;<Link to="https://Hapifyr.com/terms-and-conditions" className={genStyles.LinkTagDirect}>https://Hapifyr.com/terms-and-conditions</Link>, constitute the agreement between a User 
                                        and Hapifyr (Agreement) and supersede any prior versions of such terms 
                                        including all other communications whether oral or written, express or implied 
                                        relating to such terms.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.2.</h4>
                                        <label>
                                        This Agreement prevails in the event that any other information on the Website is 
                                        inconsistent with the terms of this Agreement.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.3.</h4>
                                        <label>
                                        If a provision of the terms of this Agreement is invalid or unenforceable it is to be 
                                        read down or severed to the extent necessary without affecting the validity or 
                                        enforceability of the remaining provisions.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.4.</h4>
                                        <label>
                                        This Agreement shall be governed by the laws of Victoria, Australia and is subject 
                                        to the exclusive jurisdiction of the courts of Victoria, Australia.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.5.</h4>
                                        <label>
                                        The failure by Hapifyr to enforce any provision of the terms of this Agreement shall 
                                        not be treated as a waiver of that provision, nor shall it affect Hapifyr’s right to 
                                        subsequently enforce that provision.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.6.</h4>
                                        <label>
                                        Hapifyr may vary the terms of this Agreement in its sole discretion by giving written 
                                        notice to Users and the varied terms shall take effect on the date specified on the 
                                        notice for when the varied terms will become effective, or if no such date is 
                                        specified, then on the next occasion the User accesses the Website.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.7.</h4>
                                        <label>
                                        Your continued use of the Website or Services following any variation made to this 
                                        Agreement in accordance with its terms will be deemed to constitute your 
                                        acceptance of such variation. If you do not accept a variation to this Agreement, 
                                        you must discontinue your use of the Website and Services including your 
                                        Account.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.8.</h4>
                                        <label>
                                        This Agreement may be assigned or novated by Hapifyr to a Third Party with 
                                        written notice to Users (<b>Transfer Notice</b>). In the event of an assignment or 
                                        novation:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                the User automatically consents to the assignment or novation of this 
                                                Agreement to the Third Party and on the date specified on the Transfer 
                                                Notice;

                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                the rights and obligations being assigned or novated (as the case may be) 
                                                will be identical or to those in this Agreement; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                in the event of novation, from the date of the novation, Hapifyr will cease to 
                                                be obligated under this Agreement and the Third Party will become 
                                                responsible for those obligations, and the User’s rights under this Agreement 
                                                shall become enforceable against the Third Party and cease to be 
                                                enforceable against Hapifyr (except such obligations and rights that arose 
                                                from before the date of novation).

                                                </label>
                                            </div>

                                            </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>2.9.</h4>
                                        <label>
                                        Hapifyr may provide any notice required under this Agreement by publishing the 
                                        notice on the Website, except in respect of 2.6, 2.7, and 2.8 which must be 
                                        provided by email or post.
                                        </label>
                                    </div>

                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="fourth">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 3. Licence to use the Website and Services </h4></Accordion.Header>
                                <Accordion.Body>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>3.1.</h4>
                                        <label>
                                        Hapifyr grants you a non-exclusive, non-transferable, revocable licence to use the 
                                        Website and Services subject to your compliance with your obligations as set out 
                                        in this Agreement. Other than as explicitly stated in this clause, the licence does 
                                        not grant you the right to provide access to the Services to any other person and 
                                        you must not:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                distribute, rent, loan, lease, sell, sublicense, transfer or otherwise exploit all 
                                                or any part of the Services, or any rights granted under this Agreement, to 
                                                any other person including your Account; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                modify, translate, adapt, arrange (or create any adaptation or modification) of 
                                                the Website or Services without our written consent.
                                                </label>
                                            </div>

                                            Any rights not expressly granted in this Agreement are reserved by us and our licensors.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>3.2.</h4>
                                        <label>
                                        The Website may contain links to Third Party websites or files, and the use of such 
                                        websites or files may be governed by terms and conditions set by the Third Party. 
                                        Hapifyr does not endorse, sponsor, approve, represent, or warrant the accuracy, 
                                        correctness, truth, or quality of any Content made available by a Third Party via the 
                                        Website or Services including on or in any linked Third-Party website or file. 
                                        Hapifyr expressly disclaims all responsibility and liability for any such Third-Party 
                                        Content including but not limited to damage, loss, costs, and expenses arising from 
                                        your reliance on or acceptance of such Third-Party Content or the terms and 
                                        conditions imposed by the Third Party.
                                        </label>
                                    </div>


                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>3.3.</h4>
                                        <label>
                                        In using the Website or Services, you acknowledge and agree that:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                Hapifyr may alter, amend, or cease the operation of the Website or Services 
                                                at any time in its sole discretion;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                the Website and Services may not operate on a continuous basis, and may 
                                                be unavailable from time to time (for reasons including but not limited to 
                                                scheduled or unscheduled maintenance, error, network or power outage);
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                registering an Account is free and Hapifyr does not charge a fee to create an 
                                                Account, or for Buyers to search for Workshops on the Website, or for Users 
                                                to otherwise view Content on the Website, subject to any fees set by Hosts;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                in its absolute discretion, Hapifyr may refuse to allow any person to create or 
                                                register for an Account and may cancel, suspend, or modify any existing 
                                                Account, for reasons including but not limited to Hapifyr reasonably forming
                                                the view that a User's conduct (including a breach of this Agreement) is 
                                                detrimental to the operation of the Website, Services, or other Users;

                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                when a Buyer purchases a Workshop via the Website, a Workshop Contract 
                                                is created between the Buyer and the Host at the time of purchase, without 
                                                imposing any liability or obligation on Hapifyr;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(f).</h4>
                                                <label>
                                                the Workshop Contract may also be subject to, or include additional Third-Party terms and conditions such as those set by the Host for the provision of their Workshop;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(g).</h4>
                                                <label>
                                                upon the purchase of a Workshop by a Buyer, Hapifyr shall be taken to have
                                                rendered Services for which the Services Fee is due and payable by the 
                                                Host;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(h).</h4>
                                                <label>
                                                all Workshops and Workshop Content promoted on the Website are provided 
                                                and delivered solely by the relevant Host and to the extent permitted by law, 
                                                Hapifyr shall not be responsible or liable for any loss or damage incurred by a 
                                                User in any manner due to the provision, performance, delivery (including the 
                                                non-provision, non-performance, non-delivery, cancellation or rescheduling) 
                                                of such Workshops;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(i).</h4>
                                                <label>
                                                any Third Party’s services are conditional upon, and governed by, the Third-Party service provider's terms and conditions; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(j).</h4>
                                                <label>
                                                no agency, partnership, joint venture, employee-employer, principal-and-contractor, fiduciary, or other similar relationship is created by this Agreement 
                                                including from your registration of an Account with us and you have no 
                                                authority to bind Hapifyr, its related entities or affiliates in any way whatsoever.
                                                </label>
                                            </div>

                                        </label>
                                    </div>


                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>3.4.</h4>
                                        <label>
                                        Hapifyr accepts no responsibility or liability and makes no representation or 
                                        warranty with respect to:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                any interaction between Hosts and Buyers, or your interaction with other 
                                                Users, including but not limited to the description, performance, or delivery of 
                                                Workshops, Workshop Content, or any aspect of a Workshop Contract; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                the truth or accuracy of any aspect of any information provided by other 
                                                Users including but not limited to the honesty, accuracy, or quality of any 
                                                information provided by a Host or Buyer or the Host's ability to provide the 
                                                Workshops or Workshop Content as promoted or as otherwise requested.
                                                </label>
                                            </div>
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>3.5.</h4>
                                        <label>
                                        The Website and Services (including any related documentation) is prepared on a 
                                        general basis which may not be appropriate or meet your, or your business’
                                        specific circumstances, requirements, or expectations, including that relating to 
                                        quality, products, services, information, or other material obtained through your use 
                                        of the Website and Services (including Third Party’s services). It remains your 
                                        responsibility to ensure that the Services (including any Third-Party’s services) 
                                        meet your or your business' needs.
                                        
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>3.6.</h4>
                                        <label>
                                        Marketing: You agree that Hapifyr and its related bodies corporate may contact 
                                        you with marketing and information about their products and services, discounts, 
                                        special offers, competitions, and invitations to special events, for an indefinite 
                                        period unless and until you opt out of receiving these communications. You agree 
                                        that any contact details provided by you can be used for these communications, 
                                        including to contact you by post, phone, email, and SMS. If at any time you wish to 
                                        opt out from receiving these communications, please let Hapifyr know by using the 
                                        “Opt Out” instructions in the Hapifyr Privacy Policy (available at www.hapifyr.com) 
                                        or any opt out means provided in the communications.
                                        
                                        </label>
                                    </div>

                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="fifth">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 4. Buyers </h4></Accordion.Header>
                                <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>4.1.</h4>
                                        <label>
                                        It is solely the obligation of the Buyer to select an appropriate Host, Workshop, and 
                                        Workshop Content according to their needs including satisfying themselves of the 
                                        Host’s self-reported skills, qualifications, education or experience, reviews from 
                                        other Buyers, and the terms and conditions of a Workshop Contract. You 
                                        acknowledge and agree that Hapifyr has no responsibility or liability, and does not 
                                        make any representations or warranties with respect to:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                the quality, experience, professional licence/registration, or qualifications of any Host;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                the quality, standard, delivery, or provision of Workshops including the Host's 
                                                ability to provide Workshops, or supply or deliver products or services that 
                                                the Host has listed on the Website, or that they will be fit for purpose;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                Buyers finding a suitable Host or Workshop via the Website; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                the availability of Hosts and Workshops including such availability in any 
                                                given geographical location, time, or date.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>4.2.</h4>
                                        <label>
                                        Buyers warrant and represent that they are over 18 years of age and it is solely the 
                                        responsibility of the Buyer, if he or she is bringing or enrolling anyone in a 
                                        Workshop, or enrolling as someone in a Workshop under the age of 18, to ensure 
                                        that such minor (including if the Buyer is a minor) is supervised by and has the 
                                        consent of the minor’s Responsible parent or guardian.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>4.3.</h4>
                                        <label>
                                        It is solely the responsibility of the Buyer to exercise caution and consider personal 
                                        safety when interacting with and meeting other Users of the Website including not 
                                        disclosing personal details such as the Buyer’s full name, street number, phone 
                                        number, or email address in any public communication on the Website.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>4.4.</h4>
                                        <label>
                                        Buyers acknowledge and agree that the Workshops are provided on the terms and 
                                        conditions set by the Host under the Workshop Contract. In this respect, Hapifyr 
                                        shall not (to the extent permitted by law) be held responsible or liable for any loss, 
                                        damage, or injury to the Buyer, Host, or any of their property or successors as a 
                                        result of a User's participation in a Workshop or use of Workshop Content.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>4.5.</h4>
                                        <label>
                                        You acknowledge and agree that Pre-recorded Workshops are available for a 
                                        period of no more than 90 days following the purchase of a Pre-recorded 
                                        Workshop after which time the Workshop will expire and no longer be available to 
                                        you to watch. You acknowledge and agree that it is your sole responsibility to 
                                        watch any Workshops that you purchase within the 90-day period and that if you 
                                        do not watch the Pre-recorded Workshop within 90 days, you forego all rights to 
                                        access the Pre-recorded Workshop after this time.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>4.6.</h4>
                                        <label>
                                        You acknowledge and agree that cancellation and refunds for Workshops and 
                                        Workshop Content remains subject to the Host's cancellation or refund terms 
                                        under the Workshop Contract, which may include:

                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                no refunds will be given if you change your mind; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                refunds will occur within a reasonable time if your request for a refund is accepted by the Host.
                                                </label>
                                            </div>

                                            For clarity, Buyers acknowledge and agree that Hapifyr has no obligation to 
                                            provide any refund of the Services Fee associated with the purchase of a 
                                            Workshop, unless the refund is sought on the basis of Hapifyr’s failure to provide 
                                            the Services under the Australian Consumer law.
                                        </label>
                                    </div>

                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="sixth">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 5. Hosts </h4></Accordion.Header>
                                <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.1.</h4>
                                        <label>
                                        This clause 5 applies to you if and whenever you use the Website as a Host.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.2.</h4>
                                        <label>
                                        Hosts acknowledge and agree that they must comply with this Agreement as a 
                                        User of the Website and Services generally (as such terms and conditions apply to them).
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.3.</h4>
                                        <label>
                                        Hapifyr does not make any representation or guarantee that Hosts will secure 
                                        business from Buyers via the Website. Hapifyr provides no assurance that a Host 
                                        will enjoy any financial outcome or result from using the Website or providing 
                                        Workshops via the Website.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.4.</h4>
                                        <label>
                                        It is solely the obligation of the Host to:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                acquire any skills, knowledge, qualifications, professional licence/registration,
                                                or experience required to perform the Workshops at the Host’s sole expense;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                exercise caution and consider personal safety when interacting and meeting with Buyers; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                control and supply the methods, materials, and all aspects required to 
                                                perform the Workshops and provide the Buyers with instructions, material 
                                                lists, and information for them to be able to participate in the Workshop.

                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.5.</h4>
                                        <label>
                                        At the sole discretion of Hapifyr, a Host of a Workshop may be removed from the 
                                        Website for reasons including but not limited to complaints made by Buyers or if 
                                        the Host breaches these Terms or any applicable law.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.6.</h4>
                                        <label>
                                        A Host must not encourage, solicit, or request a Buyer to pay for any Workshop 
                                        outside of the Website or in any manner which would deprive Hapifyr of the 
                                        Services Fee. This is not intended to exclude circumstances where a Buyer 
                                        chooses, of their own volition without encouragement or request from the Host, to 
                                        purchase the same or similar Workshops or materials to those advertised on the 
                                        Website directly from the Host through other means, such as for example via the 
                                        Host's website or a Third-Party site.

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.7.</h4>
                                        <label>
                                        A Host must not publish the reviews that they obtain through Hapifyr on any other 
                                        website, platform, or medium, without the prior written consent of Hapifyr. This 
                                        condition survives termination or expiry of the Host's agreement with Hapifyr.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.8.</h4>
                                        <label>
                                        Hosts may not permit other Users to use their Account and must not authorise any 
                                        other person to use the Services without our express written consent.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.9.</h4>
                                        <label>
                                        Hosts acknowledge and agree that they remain responsible and liable for:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                obtaining all necessary authorisations, licenses, permits, qualifications, 
                                                permissions, consents (including from Buyers) in order to provide Workshops 
                                                and Workshop Content in accordance with their Workshop Contract;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                collecting, using, disclosing, or otherwise handling any Personal Information 
                                                of Buyers in compliance with and the Host’s your privacy policy, the Privacy 
                                                Act 1988 (Cth), and any other applicable law;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                administering and properly entering into all relevant separate agreements 
                                                with Buyers (including their Workshop Contract);
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                maintaining and obtaining any relevant insurance required for the purpose of 
                                                providing the Workshops and Workshop Content; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                corresponding with and resolving disputes with Buyers including authorising 
                                                and confirming any refunds, cancellations, rescheduling and replacements of 
                                                Workshops and Workshop Content with Buyers under a Workshop Contract, 
                                                and complying fully with the Host’s obligations as the “<b>supplier</b>” of the 
                                                Workshops and Workshop Content under the Australian Consumer Law.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>5.10.</h4>
                                        <label>
                                        Hosts represent and warrant that:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                they have the right to enter into the Host’s Workshop Contract including the 
                                                right and ability to provide the Workshops and Workshop Content;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                have all necessary authorisations, licenses, permits, qualifications, 
                                                permissions, consents, approvals required by applicable law including in 
                                                order to provide the Workshops, Workshop Content and Workshop Contracts 
                                                in the jurisdiction of delivery and handle any Personal Information obtained 
                                                from Buyers;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                they and their provision of Workshops, Workshop Content and the Workshop 
                                                Contract complies with all applicable laws (including privacy, tax, employment 
                                                and other regulatory obligations relating to them and their business) and does 
                                                not involve any illegal or immoral activities; and

                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                that all Workshop Content and any other information provided by them is true and accurate.
                                                </label>
                                            </div>

                                        </label>
                                    </div>
                                
                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="seventh">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 6. Fees & Payment </h4></Accordion.Header>
                                <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.1.</h4>
                                        <label>
                                        Buyers are responsible for payment of the Price which shall be processed by 
                                        Hapifyr at the time of purchasing a Workshop or Workshop Content via the Website.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.2.</h4>
                                        <label>
                                        Upon a Buyer purchasing a Workshop or Workshop Content, the Host owes 
                                        Hapifyr the respective Services Fee which will be deducted and retained by Hapifyr 
                                        from the Workshop Price and the remaining Host Funds (subject to any reductions
                                        under this Agreement) will be available to be remitted to the Host's nominated bank 
                                        account for payment of the relevant Workshop or Workshop Content by the Buyer 
                                        as follows:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                for a Workshop (or Workshop Content) provided online (live), on the next 
                                                Business Day after such Workshop (or Workshop Content) has been 
                                                completed;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                for a Workshop (or Workshop Content) provided online (pre-recorded), 7 
                                                Business Days following the purchase of such Workshop (or Workshop 
                                                Content) made by the Buyer; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                as otherwise agreed with Hapifyr in writing.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.3.</h4>
                                        <label>
                                        The Host Funds due to be remitted to the Host under clause 6.2 will be remitted 
                                        when the Host elects to do so via their Account on the Website.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.4.</h4>
                                        <label>
                                        Until such time that Host Funds are paid to the Host under clause 6.2, those 
                                        unremitted funds will be treated as forming part of the Host’s Stored Value, which 
                                        can be used by the Host to purchase Workshops and Workshop Content provided 
                                        by other Hosts. If the Host uses the Stored Value for such purchases, the Host 
                                        Funds due to the remitted to the Host, will be reduced by the corresponding Price 
                                        for the Workshop and Workshop Content purchased by the Host.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.5.</h4>
                                        <label>
                                        Unless otherwise approved by Hapifyr (in its sole discretion), all Services Fees 
                                        payable to Hapifyr are non-cancellable and non-refundable, save for your rights 
                                        under any Non-excludable Conditions.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.6.</h4>
                                        <label>
                                        Hapifyr may restrict a User's Account until all Services Fees have been paid.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.7.</h4>
                                        <label>
                                        Hosts remain responsible for handling any cancellations of Workshops or orders of 
                                        Workshop Content (whether by a Buyer or the Host) as well as payment of any 
                                        refunds, returns, exchanges, or replacements made in relation to Workshops or 
                                        Workshop Content.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.8.</h4>
                                        <label>
                                        To the extent permitted by law or unless otherwise agreed, Hapifyr has no 
                                        obligation to provide, nor shall it be responsible or liable for, any cancellation of 
                                        Workshops or orders of Workshop Content (whether by a Buyer or the Host) or the 
                                        handling or payment of any refunds, returns, exchanges, re-delivery or 
                                        replacements made in relation to Workshops or Workshop Content. However, 
                                        Hapifyr may process refunds to Buyers as authorised in writing by Hosts (such as 
                                        due to a cancellation made by a Buyer prior to when the Workshop was delivered). 
                                        When refunding a transaction that used or partially used Stored Value, Hapifyr will 
                                        at its discretion refund you in any combination of Stored Value and/or money 
                                        equivalent to the refund amount, with the credit component being up to the value of 
                                        the Stored Value used in the transaction.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.9.</h4>
                                        <label>
                                        Users acknowledge and agree that any refunds processed by Hapifyr to Buyers 
                                        (whether in the form of Stored Value or as part of the Money-Back Guarantee) 
                                        does not in any way affect, limit, diminish, or otherwise vary or change the roles, 
                                        responsibilities and liabilities of Hapifyr and other Users (whether as a Buyer or 
                                        Host) as set out in this Agreement (unless otherwise required by law).
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.10.</h4>
                                        <label>
                                        Services Fees do not include any fees that may be due to Third Party Service 
                                        providers and unless otherwise agreed with Hapifyr are paid pursuant to a User's 
                                        separate agreement with that Third Party Service provider.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.11.</h4>
                                        <label>
                                        If a User refers a Workshop or Workshop Content to another User (whether an
                                        existing User or a new User), the referring User (Referrer) shall be entitled to be
                                        paid 5% of the Services Fee resulting from the purchase of the referred
                                        Workshop or Workshop Content (Referral Incentive). In this regard the Referral
                                        Incentives:
                                        <br />

                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                    will accrue and be available for remittance to the Referrer’s nominated bank
                                                    account at time periods set out in as clause 6.2 in respect of the Workshop or
                                                    Workshop Content that was referred; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                    will be remitted to the Referrer, and may accrue and be used as Stored
                                                    Value, in the same manner as under clauses 6.3 and 6.4 (where “Host
                                                    Funds” is replaced with “Referral Incentives”).
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>6.12.</h4>
                                        <label>
                                        If a User refers a new User (Referred User) using a unique link, and the Referred 
                                        User subsequently creates and sells a workshop on hapifyr.com, then the 
                                        Referral User (Referrer) shall be entitled to be paid 5% of the Services Fee resulting
                                        from all future purchases of the Referred User (Referral Incentive). 
                                        In this regard the Referral Incentives:

                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                    will accrue and be available for remittance to the Referrer’s nominated bank 
                                                    account at time periods set out in clause 6.2 in respect of the Workshop or 
                                                    Workshop Content created by the Referred User; and 
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                    will be remitted to the Referrer, and may accrue and be used as Stored Value,
                                                    in the same manner as under clauses 6.3 and 6.4 (where ‘'Host Funds” is replaced by “Referral Incentives”). 
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="eighth">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 7. Conduct and General User Obligations </h4></Accordion.Header>
                                <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.1.</h4>
                                        <label>
                                        In using the Website and Services, you represent and warrant that you:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                have the full capacity, legal right, power and authority to accept and comply 
                                                with this Agreement, Workshop Contracts, and all applicable laws, and to do 
                                                all acts and things and execute and deliver all other documents as required 
                                                hereunder to be done, observed or performed by you in accordance with its terms;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                will maintain the security and confidentiality of your Account login details, 
                                                password and associated identification;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                in registering an Account, you will provide, or have provided, and will 
                                                maintain and promptly update, your Registration Data and ensure that all 
                                                such information provided is accurate, correct, current, and complete;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                all information provided by you including your Content is accurate, correct, 
                                                current and complete and does not violate applicable law or infringe any 
                                                person's Intellectual Property rights; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                that you have the right to use and provide the licenses under this Agreement 
                                                including obtaining all relevant authorisations, permissions, licenses, and 
                                                consents to supply and use your Content as contemplated in this Agreement 
                                                and that our use of your Content does not violate any applicable laws or 
                                                rights (including Intellectual Property rights) of any third party.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.2.</h4>
                                        <label>
                                        You are solely responsible for all information including your Content supplied or 
                                        otherwise made available by you in using the Website or Services and all activities, 
                                        interactions, and correspondence (including disputes) you engage in with other 
                                        Users including with respect to Workshops, Workshop Content, and Workshop
                                        Contracts.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.3.</h4>
                                        <label>
                                        You agree not to advertise, supply, upload, post, publish or otherwise transmit, 
                                        provide, or make available Content on the Website or in using the Services that:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                is inaccurate, false, misleading, deceptive, harmful, obscene, pornographic, 
                                                defamatory, threatening, violent, offensive, abusive, indecent, unlawful, 
                                                harassing, or otherwise objectionable to Hapifyr or other Users of the 
                                                Website or Services, including any Content which is likely to discriminate, 
                                                offend, insult, or humiliate others based on race, religion, ethnicity, gender, 
                                                age, sexual orientation, or any physical or mental disability;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                includes unauthorised disclosure of personal information;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                advertises services including for non-individuals or for any other reason 
                                                which is not aligned to the purposes for which the Services are intended;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                infringes the Intellectual Property rights of any person; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                contains malicious code, software viruses, or any other computer code, files,
                                                or programs designed to interrupt, destroy, intercept, interfere with, or limit 
                                                the functionality of any computer software or hardware or 
                                                telecommunications equipment.
                                                </label>
                                            </div>

                                            Hapifyr may remove from the Website any Content that is in breach of any of the 
                                            subclauses (a) to (e) above, at Hapifyr’s sole discretion, without incurring any 
                                            liability to you or any other User (including Buyers of the offending Workshop or 
                                            Workshop Content). If any Buyer requests a refund of the Price or part thereof, for 
                                            the purchase of an offending Workshop or Workshop Content, such refund must 
                                            be claimed against the Host and the Host shall be solely liable to pay the refund.

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.1.</h4>
                                        <label>
                                        You must:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                immediately notify Hapifyr of any unauthorised use or access to your 
                                                Account, or the unauthorised disclosure or use of your Account’s login 
                                                credentials;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                completely sign out of your Account at the end of every session;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                maintain control of your Account at all times including not allowing others to 
                                                use your Account, or by transferring or selling your account or any of its 
                                                content to another person;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                comply with this Agreement and all applicable laws and regulations;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                only supply and submit correct, accurate, and true information when using the Website and Services; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(f).</h4>
                                                <label>
                                                promptly provide and cooperate with our reasonable requests including 
                                                requests for information in order to provide the Services.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.5.</h4>
                                        <label>
                                        Hapifyr may, but is not obliged to, at any time request a form of identification to 
                                        verify your identity and/or your compliance with this Agreement.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.6.</h4>
                                        <label>
                                        Hapifyr reserves the right in its sole discretion to remove, or request you to edit or 
                                        remove, your Content that violates this Agreement or applicable law or infringes a 
                                        person's Intellectual Property rights or for any other reason it deems is necessary 
                                        or is otherwise unlawful or inappropriate in its absolute discretion.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.7.</h4>
                                        <label>
                                        Hapifyr may record and store information relating to your use of the Website or 
                                        Services, including any complaint made against you. Hapifyr reserves the right to 
                                        disclose any information (including your Personal Information) as necessary to 
                                        satisfy any applicable law, legal requirement, police investigation of a crime that 
                                        may have been committed by you or for which you may be an accessory, or 
                                        request from a Government authority. For further information regarding Hapifyr's 
                                        use of your personal Information, please see Hapifyr’s Privacy Policy at
                                        <Link to="https://Hapifyr.com/terms-and-conditions" className={genStyles.LinkTagDirect}>https://Hapifyr.com/terms-and-conditions</Link>.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.8.</h4>
                                        <label>
                                        You must not:

                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                use the Website or Services for any illegal or immoral purposes including 
                                                pretending that you are or represent someone else, or impersonate another person;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                copy, download, store or otherwise disseminate any Content (whether 
                                                provided by Hapifyr, another User or a third party) on the Website or 
                                                otherwise made available via the Services including on third party sites or for 
                                                other business purposes without Hapifyr's prior permission;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                attempt to gain access to the Services or related systems or networks other 
                                                than via the public-facing Website, or in any other manner which is not 
                                                permitted by this Agreement;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                post, transmit, or otherwise make available through or in connection with the 
                                                Website or Services any virus, spyware or other harmful computer code, files, 
                                                scripts agents or programs;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                restrict or inhibit any other person or entity from using the Services;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(f).</h4>
                                                <label>
                                                reverse engineer, decompile, dissemble, frame, or mirror any portion of the 
                                                Website or Services, or otherwise incorporate any portion of the Website or 
                                                Services into any product or service including using any robot, spider, site 
                                                search/retrieval application or other manual or automatic device to retrieve, 
                                                index, “scrape,” “data mine” or otherwise gather the Website or Services 
                                                content or data, reproduce or circumvent the navigational structure or 
                                                presentation of the Services or systematically download and/or store 
                                                Services content or data;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(g).</h4>
                                                <label>
                                                conduct any port scanning, denial-of-service attack, security testing, or 
                                                otherwise search for or exploit any vulnerability that exists on the Website or 
                                                any of its associated servers or network infrastructure, without our express 
                                                prior written consent;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(h).</h4>
                                                <label>
                                                share any Confidential Information, trade secrets or any other Intellectual 
                                                Property belonging to Hapifyr with any Third Party; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(i).</h4>
                                                <label>
                                                imply any affiliation or endorsement of you, or any organization, by us except 
                                                with our express written consent.
                                                </label>
                                            </div>
                                           
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>7.9.</h4>
                                        <label>
                                        You agree you remain responsible and liable for:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                all activity occurring under your Account, including preventing unauthorised 
                                                access to, or use of, your Account;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                obtaining, maintaining, and paying for any hardware, telecommunications, 
                                                Internet and other services or equipment needed to use the Services, attend 
                                                or administer Workshops or otherwise provide or use Workshop Content (as 
                                                the case may be); and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                the provision, maintenance, truth, accuracy, quality, security, and protection 
                                                (including back-ups) of any Content provided by you when using the Website.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="ninth">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 8. Confidential Information </h4></Accordion.Header>
                                    <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>8.1.</h4>
                                        <label>
                                        You agree to keep the Confidential Information of Hapifyr and any other User 
                                        confidential and to only use or disclose such information:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                for the purposes and to the extent necessary to use the Services, if the 
                                                Confidential Information belongs or pertains to Hapifyr;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                for the purposes of providing the Workshop or Workshop Content to a User, if 
                                                the Confidential Information belongs or pertains to a User;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                in accordance with the prior written consent of Hapifyr or the User to whom 
                                                the Confidential Information belongs or pertains; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                to comply with any lawful order of a court or tribunal of competent jurisdiction, 
                                                or lawful direction or warrant issued by a government regulator or law 
                                                enforcement agency, in which case you must provide written notice to Hapifyr 
                                                of the order, direction, or warrant as soon as you become aware of the 
                                                obligation (if it is lawful for you to do so).
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>8.2.</h4>
                                        <label>
                                        The obligations of confidentiality in clause 8.1 will not apply to information which:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                is generally available in the public domain except where such availability is as 
                                                a result of a breach of these Terms or any other confidential obligation;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                was already lawfully known, received, or in your possession prior to the 
                                                disclosure of the information to you by Hapifyr or a User;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                is required to be disclosed by an applicable law or court order; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                was independently developed by you.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="tenth">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 9. Intellectual Property Rights </h4></Accordion.Header>
                                <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.1.</h4>
                                        <label>
                                        Nothing in this Agreement constitutes a transfer of any of Hapifyr's Intellectual 
                                        Property rights to you. You acknowledge and agree that, as between you and 
                                        Hapifyr, and subject to the Intellectual Property rights owned by you or a Third 
                                        Party in your Content, Hapifyr owns all Intellectual Property rights existing in or 
                                        otherwise created as part of providing the Website and Services (including any 
                                        modification, developments or enhancements to such Intellectual Property).
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.2.</h4>
                                        <label>
                                        By using the Services and the Website, you grant Hapifyr an unrestricted, 
                                        perpetual, non-exclusive, royalty-free, irrevocable, worldwide, and transferable 
                                        right and licence to use, modify, copy, distribute, or incorporate your Content (with 
                                        a right to sublicense on the same terms to our affiliates, employees, contractors 
                                        and other third parties that may provide services to us) for any purpose in 
                                        connection with providing the Website and Services under this Agreement 
                                        (including, without limitation, reproducing, changing, modifying, adapting and 
                                        communicating your Content to the public as required to provide the Website and 
                                        Services and for the general promotion of the Website and Services).
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.3.</h4>
                                        <label>
                                        You represent and warrant to Hapifyr that you have all necessary rights to grant 
                                        the licences and consents set out in clause 9.2 and that Hapifyr's use of your 
                                        Content as contemplated in this Agreement shall not infringe the Intellectual 
                                        Property or Moral Rights of any person.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.4.</h4>
                                        <label>
                                        All Hosts represent and warrant that they have the right or have obtained the 
                                        necessary permission to utilise, supply or sell any goods, material, content, or 
                                        other proprietary material used for the purposes of providing their Workshops 
                                        including Workshop Content. Hapifyr will not be held responsible or liable for a 
                                        Host’s infringement or violation of any other person's proprietary rights including 
                                        Intellectual Property rights (and Moral Rights).

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.5.</h4>
                                        <label>
                                        You must obtain express written permission from Hapifyr if you wish to copy, 
                                        reproduce, publish, exploit, or otherwise disseminate any aspect of the Website, 
                                        Services or other Intellectual Property owned by Hapifyr or another User or Third Party.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.6.</h4>
                                        <label>
                                        In addition to clause 9.5, if you wish to copy, reproduce, publish, exploit, or 
                                        otherwise disseminate any Workshop or Workshop Content of another User, you 
                                        must liaise and obtain consent from that User. Your licence or transfer of 
                                        Intellectual Property rights to or from that User shall be a matter between you and 
                                        the User, save that such licence or transfer shall not limit or cease any licence and 
                                        rights granted to Hapifyr under clause 9.2.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>9.7.</h4>
                                        <label>
                                        All Intellectual Property rights in a Third-Party Service remains with and is provided 
                                        on the terms of that Third Party Service provider.
                                        </label>
                                    </div>

                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="eleventh">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 10. Term, Termination and Suspension </h4></Accordion.Header>
                                <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.1.</h4>
                                        <label>
                                        This Agreement will continue in force until such time that it is varied or replaced.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.2.</h4>
                                        <label>
                                        Subject to any other termination rights agreed between you and Hapifyr with 
                                        respect to the Services that may apply:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                you or Hapifyr may terminate this Agreement at any time by giving written 
                                                notice to the other, or by using the Account cancellation feature provided on 
                                                the Website (if any); and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                Hapifyr may limit or suspend any User from using their Account or the 
                                                Services at any time and without prior notice.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.3.</h4>
                                        <label>
                                        Hapifyr reserves the right to change or discontinue any Service or feature on the 
                                        Website in whole or in part at any time in its sole discretion.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.4.</h4>
                                        <label>
                                        Hapifyr reserves the right to change pricing for any chargeable Service or feature 
                                        on the Website at any time, and will provide notice in accordance with this 
                                        Agreement where such price is varied.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.5.</h4>
                                        <label>
                                        Termination or suspension of this Agreement does not affect any Workshop 
                                        Contract that has been formed between Users.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.6.</h4>
                                        <label>
                                        If your Account, the Services, or this Agreement are terminated or suspended for 
                                        any reason then you may not without Hapifyr's consent (in its absolute discretion) 
                                        create any further accounts with Hapifyr and we may terminate any other accounts 
                                        you operate.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.7.</h4>
                                        <label>
                                        Upon termination of this Agreement:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                you must stop using your Account, the Services, and our Confidential Information;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                you must immediately pay any unpaid Services Fees; and
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                any other terms which by their nature should continue to apply, will survive 
                                                any termination or expiration of this Agreement.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>10.8.</h4>
                                        <label>
                                        You acknowledge and agree that we are not responsible or liable for any damages, 
                                        liabilities, losses incurred by you as a result of termination, suspension, or 
                                        modification of this Agreement, the Services, or your Account by Hapifyr on the 
                                        terms of this Agreement, including in relation to loss or damage to your Content 
                                        and Hapifyr is not obliged to provide access to, return, or destroy your Content.
                                        </label>
                                    </div>
                                    
                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="twelth">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 11. Limitation of Liability </h4></Accordion.Header>
                                <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.1.</h4>
                                        <label>
                                        You will be responsible for and will indemnify Hapifyr against all demand, claim, 
                                        loss, damage, cost, or expense (including legal costs and expenses on a lawyer-and-client basis) arising from your breach of any provision of this Agreement. For 
                                        clarity and to the extent permitted by law, your indemnity in favour of Hapifyr 
                                        extends to consequential and indirect damages suffered by Hapifyr where:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                you breach your obligations or infringe Hapifyr’s rights under clauses 7.3
                                                (obligations regarding your Content), 8 (confidentiality), 9 (intellectual 
                                                property); or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                you breach any law when using the Website or Services, or are grossly 
                                                negligent in the production or delivery of Workshops or Workshop Content, or 
                                                cause personal injury or death to any User or Third Party relying upon your 
                                                Workshop or Workshop Content.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.2.</h4>
                                        <label>
                                        Hapifyr reserves the right, but has no obligation, to intervene in disputes between 
                                        Users of the Website. You agree to hold Hapifyr harmless in connection with any 
                                        dispute or claim that you make against any other User.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.3.</h4>
                                        <label>
                                        Without limitation to the foregoing, you agree that, except for liability in relation to a 
                                        breach of any Non-excludable Condition, Hapifyr’s total maximum aggregate 
                                        liability, if any, arising out of any kind of legal claim or action under this Agreement, 
                                        or in any way connected to the Services or the Website, to you (whether in 
                                        contract, tort (including negligence), equity, statute or otherwise) will be limited to 
                                        the greater of:

                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                    AU$50.00; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                the total Services Fees received by Hapifyr from you during the 12 months 
                                                immediately prior to Hapifyr’s liability arising.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.4.</h4>
                                        <label>
                                        Except for liability in relation to any Non-excludable Condition, the Website and 
                                        Services is provided on an "as is" basis, and without any warranty or condition, 
                                        express or implied. To the extent permitted by law, Hapifyr specifically disclaims 
                                        any implied warranties of title, merchantability, fitness for purpose (whether 
                                        purpose is expressly made known or not), quality, and non-infringement.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.5.</h4>
                                        <label>
                                        Hapifyr's liability to you for a breach of any Non-excludable Condition (other than a 
                                        Non-excludable Condition that by law cannot be limited) is limited, at our option to 
                                        any one of resupplying the Services or refunding you the Services Fee paid in 
                                        respect of which the breach occurred, or supplying again or paying the cost of 
                                        supplying again, services in respect of which the breach occurred.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.6.</h4>
                                        <label>
                                        Except for liability in relation to breach of any Non-excludable Condition, Hapifyr 
                                        excludes all liability for loss of revenue, savings, business, opportunity, reputation, 
                                        data or any other special, consequential or indirect loss arising out of or in 
                                        connection with this Agreement or your use of the Services or Website including 
                                        that arising out of or in any way connected with any transaction between you and 
                                        any Third Party Service provider.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>11.7.</h4>
                                        <label>
                                        Except for liability in relation to breach of Non-excludable Condition, to the extent 
                                        permitted by law, Hapifyr specifically disclaims all liability for any loss or damage 
                                        (actual, special, direct, indirect and consequential) of every kind and nature, known 
                                        and unknown, suspected and unsuspected, disclosed and undisclosed (including, 
                                        without limitation, loss or damage relating to any inaccuracy of information or 
                                        content provided, or the lack of fitness for purpose of any goods or service 
                                        supplied), arising out of or in any way connected with any transaction between you 
                                        and another Third Party including another User.
                                        </label>
                                    </div>

                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="thirteen">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 12. Disclaimer of Warranties </h4></Accordion.Header>
                                <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>12.1.</h4>
                                        <label>
                                        You acknowledge and agree the Website and Services are provided over the 
                                        internet and that Hapifyr makes no representation or warranty:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                that your access to the Website (including any Third-Party applications or 
                                                payment platforms on the Website) will be timely, secure, uninterrupted, or free from defects;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                that any information supplied on the Website or via the Services by another 
                                                User will be accurate, up to date, complete, or useful; or
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                that the Website and Services (including the server which stores and 
                                                transmits the Website and Services to you) are free from viruses or other 
                                                harmful components, however we will use reasonable commercial efforts to 
                                                enable you to use the Website and Services in accordance with this 
                                                Agreement and to protect the security and integrity of the Website and 
                                                Services.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="fourteen">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 13. Assumption of Risk </h4></Accordion.Header>
                                <Accordion.Body className={genStyles.TermsAccordianBody}>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>13.1.</h4>
                                        <label>
                                        You agree and understand that you assume all risks in using the Website or 
                                        Services, including without limitation any and all of the risks associated with any 
                                        online or offline interactions with other Users (including disputes and reviews or 
                                        comments made by other Users), Workshops, Workshop Content, the Workshop 
                                        Contract and all risk for any damage to your computer system, property or loss of 
                                        data.
                                        </label>
                                    </div>
                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="fifteen">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 14. Gift Cards </h4></Accordion.Header>
                                <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>14.1.</h4>
                                        <label>
                                        Users may purchase gift cards in the form of Stored Value or otherwise be 
                                        provided credit in the form of Stored Value. Stored Value:
                                        
                                        <hr className={genStyles.TermslineHR}/>
                                        <hr className={genStyles.TermslineHR}/>
                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(a).</h4>
                                                <label>
                                                can be used by the credited User to purchase Workshops or Workshop 
                                                Content via the Website;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(b).</h4>
                                                <label>
                                                are not refundable or redeemable for cash (other than Stored Value equalling the accumulated
                                                Host Funds and Referral Incentives that have not been paid to you in monetary form);
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(c).</h4>
                                                <label>
                                                cannot be replaced, exchanged, or reloaded, or transferred to another card or 
                                                Account or any Third Party;
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(d).</h4>
                                                <label>
                                                are valid for 3 years from the date on which the Stored Value is purchased 
                                                (or any longer period prescribed by law);
                                                </label>
                                            </div>

                                            <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                                <h4 className={genStyles.TermsTagHeading}>(e).</h4>
                                                <label>
                                                must not be reproduced, copied, distributed, or published directly or indirectly 
                                                in any form or by any means for use by an entity other than the credited User 
                                                without Hapifyr's prior written permission.
                                                </label>
                                            </div>

                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>14.2.</h4>
                                        <label>
                                        The User credited with Stored Value is solely responsible for the security of any 
                                        Stored Value. Save for the Non-excludable Conditions, Hapifyr will have no liability 
                                        for any loss or damage to the Stored Value and does not have any obligation to 
                                        replace Stored Value.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>14.3.</h4>
                                        <label>
                                        Hapifyr will not accept, and may refuse or cancel, any Stored Value, which it 
                                        reasonably determines in its discretion, has been used in breach of this Agreement 
                                        or has been forged, tampered with, or is otherwise fraudulent and Hapifyr reserves 
                                        the right to refer any suspected fraudulent activity to relevant law enforcement 
                                        authorities. In particular, Stored Value, such as promotional coupons, vouchers, 
                                        codes, gift cards distributed or circulated without our approval, for example on an 
                                        internet message board or on a "bargains" website, are not valid for use and may 
                                        be refused or cancelled.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>14.4.</h4>
                                        <label>
                                        Hapifyr is entitled to any value of Stored Value which is not redeemed before the 
                                        Stored Value expires or is cancelled by Hapifyr.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>14.5.</h4>
                                        <label>
                                        Users acknowledge and agree that use of Stored Value to purchase Workshops or 
                                        Workshop Content via the Website does not in any way affect, limit, diminish or 
                                        otherwise vary or change the roles, responsibilities and liabilities of Hapifyr and 
                                        other Users (whether as a Buyer or Host) as set out in this Agreement.
                                        </label>
                                    </div>

                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="sixteen">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 15. Third Party Services </h4></Accordion.Header>
                                <Accordion.Body className={genStyles.TermsAccordianBody}>
                                    
                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>15.1.</h4>
                                        <label>
                                        Hapifyr may from time to time include, feature or otherwise make available Third-Party Services via the Website or Services. Such Third-Party Services are not 
                                        provided by Hapifyr and may be featured or otherwise made available via the 
                                        Website or Services as a convenience to our Users who may find the Third-Party 
                                        Services of interest or of use.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>15.2.</h4>
                                        <label>
                                        Third Party Services are provided to Users pursuant to the Third-Party Service 
                                        provider's terms and conditions (including their privacy policy).
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>15.3.</h4>
                                        <label>
                                        If a User uses a Third-Party Service, or otherwise engages with any Third-Party 
                                        Service provider, then such agreement will be directly between the User and that 
                                        Third Party provider. You remain responsible for ensuring you review, comply with 
                                        and agree to such Third-Party Service provider's terms.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>15.4.</h4>
                                        <label>
                                        Hapifyr makes no representation or warranty as to the Third-Party Services. 
                                        However, to help us continue to improve the Website or Services, Users may 
                                        inform Hapifyr of their Third-Party Service experience.
                                        </label>
                                    </div>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>15.5.</h4>
                                        <label>
                                        To the extent permitted by law, Hapifyr specifically disclaims all liability for any loss 
                                        or damage incurred by you in any manner due to the performance or non-performance of such Third Party Service and disputes with any Third Party Service 
                                        provider must proceed pursuant to any dispute resolution process set out in the 
                                        terms of service of the Third Party Service provider.
                                        </label>
                                    </div>

                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="seventeen">
                        <Accordion defaultActiveKey="0" className={genStyles.TermsAccordian}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={genStyles.TermsAccordianHeader} ><h4 className={genStyles.ContentHeading}> 16. Disputes </h4></Accordion.Header>
                                <Accordion.Body className={genStyles.TermsAccordianBody}>

                                    <div className={`d-flex align-items-baseline ${genStyles.TermsParaBody}`}>
                                        <h4 className={genStyles.TermsTagHeading}>16.1.</h4>
                                        <label>
                                        f a dispute arises under this Agreement, the parties will attempt to resolve the 
                                        dispute in good faith, prior to commencing any form of litigation or legal 
                                        proceedings.
                                        </label>
                                    </div>
                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </Tab.Pane>

                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

        </div>
      </main>


  );
}

export default TermsandConditions;
