import { useState } from 'react';
import Header from '../../Navbar/Header';
import { useParams } from 'react-router-dom';
import NewInvitation from './NewInvitation';
import UpdateInvitation from './UpdateInvitation';
// import ViewWatchParty from '../WatchParty/ViewWatchParty';
import { Container } from 'react-bootstrap';
import inivteStyles from './invite.module.css';


const CreateInvitation = () => {

  const [loginStatus] = useState('loggedIn');
  const { invitationType } = useParams();

  return (
    <Container fluid className="noPadding MainpartSetup viewportHt">
        <div className={inivteStyles.CreateInviteDashboard}>
            
            <Header loginStatus={loginStatus} />
            
            {invitationType === 'new-invitation' && <NewInvitation />}
            {invitationType === 'update-invitation' && <UpdateInvitation />}
      
        </div>
    </Container>

  );
}

export default CreateInvitation;
