import { Link } from 'react-router-dom';
import {Row, Col, Container, Nav, Navbar, Button } from "react-bootstrap";
import headerStyles from './header.module.css';



const TransparentHeader = () => {
  

  return (
    <Navbar className={`navbarborder ${headerStyles.NavHeader}`} expand="md" fixed="top">
      <Container fluid className={headerStyles.authContainer}>
        <Row className={`w-100 align-items-center ${headerStyles.authHeaderrow}`}>
          <Col xs="auto">
            <Navbar.Brand href="/" className="p-0 m-0">
              <img src="/Content/Images/Svgs/logo_background.svg" alt="Logo" className={headerStyles.logo} />
            </Navbar.Brand>
          </Col>
          <Col className="d-flex justify-content-end">
            <Navbar.Toggle aria-controls="basic-navbar-nav" className={headerStyles.customToggler} />
          </Col>
        </Row>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`navPaddingZero ${headerStyles.HeaderNavLinks} mt-2`}>
            <Link to="/info/about-us">
              <Button variant="white" size="md" className={headerStyles.authHeaderrowBtns}>
                About Us
              </Button>
            </Link>
            <Link to="/signup/mandatory-details">
              <Button variant="white" size="md" className={headerStyles.authHeaderrowBtns}>
                Sign Up
              </Button>
            </Link>
            <Link to="/signin/form">
              <Button variant="white" size="md" className={headerStyles.authHeaderrowBtns}>
                Sign In
              </Button>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};


export default TransparentHeader;