import { Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsBook, BsFillHddRackFill, BsCollectionPlay } from "react-icons/bs";
import styles from './base.module.css';
import { useParams } from 'react-router-dom';



const SellActions = ({ userWorkshopsCount, actionType }) => {

    let { type } = useParams();
    
    if(type === null || type === undefined){
        type = actionType;
    }

  return (
    <Container className='noPadding'>
    <Row className={`boxBody`}>
        
        <Row className={`mt-1 ${styles.ActionHeaderdiv}`}>
            <h5 className={styles.ActionHeader}>Host Actions</h5>
        </Row>
       
        <Row className={styles.SellerActionsBox}>
            <Row>
            {type !== "manage-workshops" && type !== "education-hub" && type !== "unpublished-workshops" ? (
                <Link to="/seller/dashboard">
                <Row className={`mb-2 ${styles.ActionClickRows} ${styles.activeAction}`}>
                    <button className={`btn ${styles.ActionClickBtns} ${styles.ActionClickTextActive}`}>
                        <BsFillHddRackFill style={{backgroundColor: 'darkgray'}} />&nbsp;&nbsp;Dashboard
                    </button>
                </Row>
                </Link>
            ) : (
                <Link to="/seller/dashboard">
                <Row className={`mb-2 ${styles.ActionClickRows}`}>
                    <button className={`btn ${styles.ActionClickBtns}`}>
                        <BsFillHddRackFill style={{backgroundColor: 'darkgray'}} />&nbsp;&nbsp;Dashboard
                    </button>
                </Row>
                </Link>
            )}
            </Row>
         
            <Row>
            {type === "manage-workshops" ? (
                <Link to="/workshops/manage-workshops">
                <Row className={`mb-2 ${styles.ActionClickRowsActive} ${styles.activeAction}`}>
                    <button className={`btn d-flex align-items-center justify-content-between ${styles.ActionClickBtnsActive}`}>
                        <div className={`d-flex align-items-center ${styles.ActionClickTextActive}`}>
                            <BsCollectionPlay />&nbsp; My Workshops &nbsp;
                        </div>
                        <div className={styles.ActionClickBadgeRowActive}>
                            <span className={styles.ActionClickBadgeActive}>{userWorkshopsCount}</span>
                        </div>
                    </button>
                </Row>
                </Link>
            ) : (
                <Link to="/workshops/manage-workshops">
                    <Row className={`mb-2 ${styles.ActionClickRows}`}>
                        <button className={`btn d-flex align-items-center justify-content-between ${styles.ActionClickBtns}`}>
                            <div className="d-flex align-items-center">
                                <BsCollectionPlay style={{backgroundColor: 'darkgray'}} />&nbsp; My Workshops &nbsp;
                            </div>
                            <div className={styles.ActionClickBadgeRow}>
                                <span className={styles.ActionClickBadge}>{userWorkshopsCount}</span>
                            </div>
                        </button>
                    </Row>
                </Link>
            )}
            </Row>

            <Row>
            {type === "unpublished-workshops" ? (
                <Link to="/seller/unpublished-workshops">
                    <Row className={`mb-2 ${styles.ActionClickRowsActive} ${styles.activeAction}`}>
                        <button className={`btn d-flex align-items-center justify-content-between ${styles.ActionClickBtnsActive}`}>
                            <div className={`d-flex align-items-center ${styles.ActionClickTextActive}`}>
                                <BsCollectionPlay />&nbsp; Unpublished Workshops &nbsp;
                            </div>
                        </button>
                    </Row>
                </Link>
            ) : (
                <Link to="/seller/unpublished-workshops">
                    <Row className={`mb-2 ${styles.ActionClickRows}`}>
                        <button className={`btn d-flex align-items-center justify-content-between ${styles.ActionClickBtns}`}>
                            <div className="d-flex align-items-center">
                                <BsCollectionPlay style={{backgroundColor: 'darkgray'}} />&nbsp; Unpublished Workshops &nbsp;
                            </div>
                        </button>
                    </Row>
                </Link>
            )}
            </Row>

            <Row>
            {type === "education-hub" ? (
                <Link to="/seller/education-hub">
                <Row className={`mb-2 ${styles.ActionClickRows} ${styles.activeAction}`}>
                    <button className={`btn ${styles.ActionClickBtns} ${styles.ActionClickTextActive}`}>
                        <BsBook style={{backgroundColor: 'darkgray'}} />&nbsp;&nbsp;Education Hub
                    </button>
                </Row>
                </Link>
            ) : (
                <Link to="/seller/education-hub">
                <Row className={`mb-2 ${styles.ActionClickRows}`}>
                    <button className={`btn ${styles.ActionClickBtns}`}>
                        <BsBook style={{backgroundColor: 'darkgray'}} />&nbsp;&nbsp;Education Hub
                    </button>
                </Row>
                </Link>
            )}
            </Row>

        </Row>    

    </Row>
    </Container>
  );
};

export default SellActions;
