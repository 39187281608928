import { useState } from 'react';
import Header from '../../Navbar/Header';
import { useParams } from 'react-router-dom';
import ViewWatchParty from './ViewWatchParty';
import StartWatchParty from './StartWatchParty';
import { Container } from 'react-bootstrap';
import inivteStyles from './watchParty.module.css';


const WatchPartySection = () => {


  const [loginStatus] = useState('loggedIn');
  const { partyType, invitationId } = useParams();

  // console.log('Watch::', partyType);

  return (
    <Container fluid className="noPadding MainpartSetup viewportHt">
        <div className={inivteStyles.watchPartySection}>
            
            {partyType !== 'live' && (
                <Header loginStatus={loginStatus} />
            )}

            {(partyType === 'join' || partyType === 'start') && <StartWatchParty invitationId={invitationId} userType={partyType} />}
            {partyType === 'live' && <ViewWatchParty invitationId={invitationId} userType={partyType} />}
      
        </div>
    </Container>

  );
}

export default WatchPartySection;
