import React, { useState } from 'react';
import MessagingServices from '../../../Services/MessagingServices';
import { Image, Row, Spinner, Container } from 'react-bootstrap';
import filterStyles from './leftComponent.module.css';

const UserSearchFilter = ({ filterWithUserId }) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [debounceTimer, setDebounceTimer] = useState(null);

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setSearchResults([]);
    setErrorMessage('');

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    if (value.trim().length > 2) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        searchUsers(value);
      }, 500);
      setDebounceTimer(timer);
    } else {
      setIsLoading(false);
    }
  };

  const searchUsers = async (query) => {
    try {
      const response = await MessagingServices.searchMessagingUsers(query, 'all');
      if (response.statusCode === 200 && response.data?.length > 0) {
        setSearchResults(response.data);
      } else {
        setSearchResults([]);
        setErrorMessage('No Users found with the given search');
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred while searching for users. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserIdFilter = (userId, username) => {
    setErrorMessage('');
    setSearchValue(username);   // Set selected user name in the input field
    setSearchResults([]);        // Clear search results after selection
    filterWithUserId(userId);  // Pass user ID to parent component
  };

  const clearSearch = () => {
    setSearchValue('');
    setSearchResults([]);
    setErrorMessage('');
    setIsLoading(false);
  };

  return (
    <Container className='noPadding'>
      <Row>
        <div className="position-relative w-100">
          <div className="position-relative">
            <input
              type="text"
              value={searchValue}
              placeholder="Search User..."
              onChange={handleSearchInputChange}
              className={`form-control ${filterStyles.userSearchfilterInput}`}
              style={{ paddingRight: '2rem' }}  // Add padding for clear button
            />
            {searchValue && (
              <button onClick={clearSearch} className={`btn-clear ${filterStyles.clearButton}`}>
                ✕
              </button>
            )}
          </div>

          {isLoading && (
            <div className="search-dropdown text-center">
              <Spinner animation="grow" />
            </div>
          )}

          {searchResults.length > 0 ? (
            <div className="search-dropdown">
              <ul className="list-group">
                {searchResults.map((user) => (
                  <li
                    key={user._id}
                    className={`list-group-item search-item ${filterStyles.UserDatalist}`}
                    onClick={() => handleUserIdFilter(user._id, user.username)}
                  >
                    <div className={`mb-2 mt-2 ${filterStyles.UserDetailsDiv}`}>
                      <Image
                        src={user.image_url ? user.image_url : '/Content/Images/empty_user.jpg'}
                        roundedCircle
                        alt="Profile"
                        className={`roundedUser mr-3 ${filterStyles.UserIcon}`}
                      />
                      <div className={filterStyles.UserBiodataDiv}>
                        <h6 className={filterStyles.UserNametag}>{user.name.split(' ')[0]}</h6>
                        <label className={filterStyles.UserDescriptionTag}>{user.username}</label>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            errorMessage && (
              <div className="search-dropdown text-center">
                <label className={filterStyles.nousersfoundFont}>{errorMessage}</label>
              </div>
            )
          )}
        </div>
      </Row>
    </Container>
  );
};

export default UserSearchFilter;
