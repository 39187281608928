import { Link } from 'react-router-dom';
import { Row, Col, Image, Container, ButtonGroup, Button } from 'react-bootstrap';
import Tooltip from '../../BaseComponents/Tooltip';
import inviteStyles from './invite.module.css';

const InvitedInvitations = ({ invitations, handleFollowUser, handleInvitation }) => {
     
  const getStatusLabel = (invitation) => {
    // if (invitation.isPurchased !== "true") return "Buy First to join Watch Party";

    const now = new Date();
    const startTime = new Date(`${invitation.startDate}T${invitation.startTime}`);
    // const endTime = new Date(`${invitation.endDate}T${invitation.endTime}`);

    // if (now > endTime) return "Invitation Expired";
    if (now >= startTime) return "Join Now";

    const timeDiffMs = startTime - now;
    const timeDiffMinutes = Math.floor(timeDiffMs / (1000 * 60));
    
    if (timeDiffMinutes <= 5) return "Starts in 5 mins";
    if (timeDiffMinutes <= 30) return "Starts in 30 mins";
    if (timeDiffMinutes <= 60) return "Starts in 1 hour";

    return `Starts in ${Math.ceil(timeDiffMinutes / 60)} hours`;
  };


  return (
    <Container className={`noPadding`}>
        {invitations && invitations.length > 0 ? (
          invitations.map((invitation, index) => (
            <div key={index}>

                <Row className={`mt-2 mb-2 ${inviteStyles.createInvirationButtonRow}`} >
                    <Col xs="auto">
                    {/* invitation.isPurchased === "true" // add this if isPurchased key updated*/}
                        {getStatusLabel(invitation) === "Join Now" ? (
                        // <Link to={`/watch-party/join/${invitation.invitationId}`}>
                            <label className={inviteStyles.joinButtonStyle}>&nbsp; Join Party &nbsp;</label>
                        // </Link>
                        ) : (
                        <span className={inviteStyles.invitationOptionText}>{getStatusLabel(invitation)}</span>
                        )}
                    </Col>
                </Row>
         
                <Row className={`mb-5 ${inviteStyles.invitationsContainer}`} >
                    <Row className={`${inviteStyles.invitationsRow}`}>

                        <div className={`${inviteStyles.invitationAdmindiv} ${inviteStyles.invitationContainer}`}>
                            <div className="followUsersetupDiv mb-2">
                                <Link to={`/seller/public-profile/${invitation.postedByUserId}`} className={inviteStyles.invitationLink}>
                                    <div className={`${inviteStyles.createInviteIcondiv} ${inviteStyles.invitationContent}`}>
                                        <Image
                                            src={invitation.postedByUserImg || '/Content/Images/empty_user.jpg'}
                                            className={`${inviteStyles.invitationsIconImg} ${inviteStyles.invitationImage}`}
                                        />
                                        <div className="invitation-text">
                                            <label className={inviteStyles.createInviteUserInvite}>
                                                {invitation.postedBy}&nbsp;
                                                <span className={inviteStyles.invitationInfo}>
                                                    invited you to join the group watch party on <span className={inviteStyles.createInviteUserInvite}>{invitation.startDate} <span className={inviteStyles.invitationInfo}>at</span> {invitation.startTime}.</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className={inviteStyles.invitationsJoinButtonsdiv}>
                            <ButtonGroup className="me-2" aria-label="First group">
                                {(!invitation.rejectedInvitation && !invitation.acceptedInvitation) ? (
                                    <>
                                        <Button className={`${inviteStyles.invitedJoinButtons} ${inviteStyles.invitedAccept}`} onClick={() => handleInvitation(invitation.invitationId, true)} >Accept</Button> |
                                        <Button className={`${inviteStyles.invitedJoinButtons} ${inviteStyles.invitedReject}`} onClick={() => handleInvitation(invitation.invitationId, true)} >Reject</Button>
                                    </>
                                ) : invitation.acceptedInvitation && !invitation.rejectedInvitation ? (
                                    <Button className={`${inviteStyles.invitedJoinButtons} ${inviteStyles.acceptedButton}`}>Accepted</Button>
                                ) : (
                                    <Button className={`${inviteStyles.invitedJoinButtons} ${inviteStyles.rejectedButton}`}>Rejected</Button>
                                )}
                            </ButtonGroup>
                        </div>
                        
                        <div className={inviteStyles.invitationsContainerdiv}>
                            <Link to={`/experiencehub/experience/${invitation.feedId}`} >
                                {/* <Link to={`/live/${invitation.invitationId}`} > */}
                                    <Image
                                        src={invitation.cover || '/Content/Images/General/no_thumbnail.png'}
                                        alt="PW_thumbnail"
                                        fluid
                                        roundedCircle
                                        className={inviteStyles.invitationsThumbnail}
                                    />
                                </Link>
                            <div className={inviteStyles.invitationsContent}>
                                <Image
                                    src="/Content/Images/icons/document-text.png"
                                    style={{color: 'white'}}
                                    className={inviteStyles.invitationsDescTextImgSize}
                                />
                                &nbsp;
                                <p className={inviteStyles.invitationsContentTextColorFont} title={invitation.title} >{invitation.title}</p>
                            </div>

                            <div className={inviteStyles.invitationsContentSide}>

                                <div className={inviteStyles.invitationsUserProfilediv}>
                                    <Link to={`/product_details/${invitation.feedId}`}>
                                    <Tooltip content="Go to Workshop">
                                        <Image src={invitation.cover || '/Content/Images/General/no_thumbnail.png'} alt="content" roundedCircle className={inviteStyles.invitationsWorkshopThumbnail} />
                                    </Tooltip>
                                    </Link>
                                </div>

                                <div className={inviteStyles.invitationsContentdiv}>
                                    <div className='followUsersetupDiv mb-2' >
                                        <Tooltip content={invitation.isFollowing ? `Unfollow ${invitation.postedBy}` : `Follow ${invitation.postedBy}`} >
                                            <Link to={`/seller/public-profile/${invitation.postedByUserId}`}>
                                                <div className={inviteStyles.invitationsIconwithCountsdiv}>
                                                    <div className={inviteStyles.invitationsIconFollowAdddiv}>
                                                    <Image
                                                        src={invitation.postedByUserImg ? invitation.postedByUserImg : '/Content/Images/empty_user.jpg'} 
                                                        className={inviteStyles.invitationsIconFollowImg} 
                                                    />
                                                    {!invitation.contentOwner && (
                                                    <div onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        handleFollowUser(invitation.postedByUserId);
                                                        }}
                                                        className={inviteStyles.invitationsIconFollowAddIconContainer}
                                                    >
                                                        <Image 
                                                            src={invitation.isFollowing ? "/Content/Images/icons/icons-cancel.png" : "/Content/Images/icons/icons-plus.png"} 
                                                            className={inviteStyles.invitationsIconFollowAddIcon} 
                                                        />
                                                    </div>
                                                    )}
                                                    </div>
                                                </div>
                                            </Link>
                                        </Tooltip>
                                    </div>
                                    
                                    <div className={inviteStyles.invitationsIconwithCountsdiv}>
                                        <Tooltip content="Rating">
                                            <Image src="/Content/Images/icons/icons-rating.png" className={inviteStyles.invitationsIconImg} />
                                        </Tooltip>
                                        <label className={inviteStyles.invitationsUserContentFont}>{invitation.ratings ? (invitation.ratings).toFixed(0) : 0}</label>
                                    </div>
                                    <div className={inviteStyles.invitationsIconwithCountsdiv}>
                                        <Tooltip content="Sales">
                                            <Image src="/Content/Images/icons/totalsalesCount.png" className={inviteStyles.invitationsIconImg} />
                                        </Tooltip>
                                        <label className={inviteStyles.invitationsUserContentFont}>{invitation.totalSold ?? 0}</label>
                                    </div>
                                    <div className={inviteStyles.invitationsIconwithCountsdiv}>
                                        <Tooltip content="Price">
                                            <Image src="/Content/Images/icons/icons-dollar-coin.png" className={inviteStyles.invitationsIconImg} />
                                        </Tooltip>
                                        <label className={inviteStyles.invitationsUserContentFont}>{invitation.currency ?? '$'}&nbsp;{invitation.price ?? 0}</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Row>
                </Row>

            </div>
          ))
        ) : (
          <div className={inviteStyles.noInvitationsContainer}>
            You have not purchased any workshops.
          </div>
        )}
    </Container>
  );
};

export default InvitedInvitations;
