import axiosAPI from "./axiosAPI";


const hubServices = {

  saveBuyerReviewPost: async (form) => {
    try {
      await axiosAPI.validateToken();
      const accessToken = localStorage.getItem('accessToken');
      const token = `Bearer ${accessToken}`;
     const response = await axiosAPI.axiosAuth.post(`/feedback/review`, form,  {
        headers: {
          'Authorization': token, // Include the "Authorization" header
          'Content-Type': 'multipart/form-data',
        },
      });
      //console.log("saveBuyerReviewPost: ", response);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },


  fetchPurchasedExperiences: async () => {
    try {
      await axiosAPI.validateToken();
      const accessToken = localStorage.getItem('accessToken');
      const token = `Bearer ${accessToken}`;
      const response = await axiosAPI.axiosAuth.get(`/experiences/items`, {
        headers: {
          'Authorization': token, // Include the "Authorization" header
          'Content-Type': 'application/json',
        },
      });

      //console.log("fetchPurchasedExperiences: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },


  fetchExpiredExperiences: async () => {
    try {
      await axiosAPI.validateToken();
      const accessToken = localStorage.getItem('accessToken');
      const token = `Bearer ${accessToken}`;
      const response = await axiosAPI.axiosAuth.get(`/experiences/expired-items`, {
        headers: {
          'Authorization': token, // Include the "Authorization" header
          'Content-Type': 'application/json',
        },
      });

      //console.log("fetchExpiredExperiences: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  
  fetchGroupWatchWorkshopsList: async () => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.get(`/groupwatch/list`);

      //console.log("fetchExpiredExperiences: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  createNewWorkshopParty: async (workshopId, partyTitle, partyStartDate, partyStartTime, partyEndDate, partyEndTime, timeZone, attendeesList) => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.post(`/groupwatch/event`, {
        feedId: workshopId,
        title: partyTitle,
        startDate: partyStartDate,
        endDate: partyEndDate,
        startTime: partyStartTime,
        endTime: partyEndTime,
        tz: timeZone.label,
        participants: attendeesList,
        },
      );
      //console.log("Response::createNewWorkshopParty : ", response.data);

      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  updateWorkshopPartyInvitation: async (invitationId, invitationTitle, invitationStartDate, invitationStartTime, invitationEndDate, invitationEndTime, timeZone, attendeesList) => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.patch(`/groupwatch/event`, {
        invitationId: invitationId,
        title: invitationTitle,
        startDate: invitationStartDate,
        endDate: invitationEndDate,
        startTime: invitationStartTime,
        endTime: invitationEndTime,
        tz: timeZone,
        // participants: attendeesList,
        },
      );

      //console.log("updateWorkshopPartyInvitation: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },


  addParticipantsToInvitationById: async (invitationId, userId) => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.post(`/groupwatch/invite`, {
        invitationId: invitationId,
        userId: userId,
        },
      );
      //console.log("addParticipantsToInvitationById: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },


  fetchInvitations: async (type) => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.get(`/groupwatch/event?type=${type}`);

      //console.log("fetchInvitations: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  fetchAttendeesbyId: async (invitationId) => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.get(`/groupwatch/invite?invitationId=${invitationId}`);

      //console.log("fetchAttendeesbyId::", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  fetchInvitationDetailsById: async (id) => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.get(`/groupwatch/invitations/${id}`);

      //console.log("fetchInvitationDetailsById: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  approvalInvitation: async (invitationId, approvalType) => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.put(`/groupwatch/invitations/${invitationId}`, {
        accept: approvalType,
      });

      //console.log("approvalInvitation: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  startGroupWatch: async (invitationId) => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.get(`/groupwatch/start/${invitationId}`);

      //console.log("startGroupWatch::", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  joinGroupWatch: async (invitationId) => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.get(`/groupwatch/join/${invitationId}`);

      //console.log("startGroupWatch::", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

};


export default hubServices;