// import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import filterStyles from './leftComponent.module.css';
import UserSearchFilter from './UserSearchFilter';


const Filters = ({ onFilterChange, filters }) => {
  
  const handleCategoryChange = (e) => {
    const updatedFilters = { ...filters, category: e.target.value };
    onFilterChange(updatedFilters);
  };

  const handleWorkshopChange = (e) => {
    const updatedFilters = { ...filters, workshop: e.target.value };
    onFilterChange(updatedFilters);
  };

  const handleUserChange = (userId) => {
    const updatedFilters = { ...filters, user: userId };  // Use userId directly
    onFilterChange(updatedFilters);
  };

  const handlePriceChange = (e) => {
    const updatedFilters = { ...filters, price: e.target.value };
    onFilterChange(updatedFilters);
  };

  const handleRatingChange = (e) => {
    const updatedFilters = { ...filters, rating: e.target.value };
    onFilterChange(updatedFilters);
  };

  // const handleBoastsChange = (e) => {
  //   const updatedFilters = { ...filters, boasts: e.target.value };
  //   onFilterChange(updatedFilters);
  // };

  const handleLengthChange = (e) => {
    const updatedFilters = { ...filters, length: e.target.value };
    onFilterChange(updatedFilters);
  };

  const handleForSaleChange = (e) => {
    const updatedFilters = { ...filters, forSale: e.target.value };
    onFilterChange(updatedFilters);
  };

  const handleReviewCountChange = (e) => {
    const updatedFilters = { ...filters, reviewCount: e.target.value };
    onFilterChange(updatedFilters);
  };


  return (
    <Container className={filterStyles.filterBoxBody}>
        
        <h5 className={`${filterStyles.filtersHeader}`}> Filters </h5>

        <Row className={filterStyles.homefiltersrow}>
          <Col xs="auto">
            <select className={`form-select ${filterStyles.cateoryfiltersBox}`} 
              // value={filters.category}
              value={filters?.category || ""}  // Default to an empty string if category is undefined
              onChange={handleCategoryChange}
            >
              <option value="" disabled>Category</option>
              <option value="Arts and Crafts">Arts and Crafts</option>
              <option value="Baking and Cooking">Baking and Cooking</option>
              <option value="Brewing and Distilling">Brewing and Distilling</option>
              <option value="Photography, Film and Digital Media">Photography, Film and Digital Media</option>
              <option value="Performing Arts">Performing Arts</option>
              <option value="Literary Arts">Literary Arts</option>
              <option value="Design">Design</option>
            </select>
          </Col>

          <Col xs="auto">
            <select className={`form-select ${filterStyles.filtersBoxes}`} 
              value={filters?.workshop || ""}
              onChange={handleWorkshopChange}
            >
              <option value="" disabled>Feed Type</option>
              <option value="workshop">Workshop</option>
              <option value="boast">Boast</option>
            </select>
          </Col>
        </Row>

        <Row className={filterStyles.homefiltersrow}>

          <Col xs="auto">
            <select className={`form-select ${filterStyles.filtersBoxes}`} 
              value={filters?.price || ""}
              onChange={handlePriceChange}
            >
              <option value="" disabled>Price</option>
              <option value="0-20">$0-$20</option>
              <option value="21-50">$21-$50</option>
              <option value="51-75">$51-$75</option>
              <option value="76-99">$76-$99</option>
              <option value="100-">$100+</option>
            </select>
          </Col>

          <Col xs="auto">
            <select className={`form-select ${filterStyles.filtersBoxes}`} 
              value={filters?.length || ""}
              onChange={handleLengthChange}
            >
              <option value="" disabled>Length</option>
              <option value="15">0-15 mins</option>
              <option value="30">16-30 mins</option>
              <option value="45">31-45 mins</option>
              <option value="60">46-60 mins</option>
              <option value="90">61-90 mins</option>
              <option value="120">91-120 mins</option>
              <option value="1000-">120 mins+</option>
            </select>
          </Col>

          <Col xs="auto">
            <select className={`form-select ${filterStyles.filtersBoxes}`} 
              value={filters?.rating || ""}
              onChange={handleRatingChange}
            >
              <option value="" disabled>Rating</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </Col>

        </Row>

        {/* <Row className={filterStyles.homefiltersrow}>

           <Col xs="auto">
            <select className={`form-select ${filterStyles.filtersBoxes}`} 
              value={filters.host}
              onChange={handleHostChange}
            >
              <option value="" disabled>Host</option>
              <option value="host">Host</option>
              <option value="seller">Seller</option>
            </select>
          </Col> 

           <Col xs="auto">
            <select className={`form-select ${filterStyles.filtersBoxes}`} 
              value={filters.boasts}
              onChange={handleBoastsChange}
            >
              <option value="" disabled>Boasts</option>
              <option value="Boasts">Boasts</option>
              <option value="Products">Products</option>
            </select>
          </Col> 

        </Row> */}

        <Row className={filterStyles.homefiltersrow}>

        <Col xs="auto">
            <select className={`form-select ${filterStyles.filtersBoxes}`} 
              value={filters?.reviewCount || ""}
              onChange={handleReviewCountChange}
            >
              <option value="" disabled>Review Count</option>
              <option value="high-low">High-Low</option>
              <option value="low-high">Low-High</option>
            </select>
          </Col>

          <Col xs="auto">
            <select className={`form-select ${filterStyles.filtersBoxes}`} 
              value={filters?.forSale || ""}
              onChange={handleForSaleChange}
            >
              <option value="" disabled>My Workshops</option>
              <option value="forSale">For sale</option>
              <option value="purchased">Purchased</option>
            </select>
          </Col>

        </Row>

        <Row className={filterStyles.homefiltersrow}>

          <Col xs="auto">
            <UserSearchFilter filterWithUserId={handleUserChange} />
          </Col>

          <Col xs="auto"></Col>

        </Row>


        <br />
    </Container>

  );
};

export default Filters;