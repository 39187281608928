import React, { useState, useEffect, useContext } from "react";
import ReactPlayer from 'react-player';
import { Container, Row, Button, Toast } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import partyStyles from './watchParty.module.css';
import hubServices from "../../../Services/hubServices.js";
import { Carousel } from 'react-responsive-carousel';
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import AuthContext from "../../Context/AuthContext.js";

const StartWatchParty = ({ invitationId }) => {
    
    // console.log('startWatchParty:: ', invitationId);
    const [invitationDetails, setInvitationDetails] = useState([]);
    const { setIsLoading } = useContext(AuthContext);
    const [showDescription, setShowDescription] = useState(true);
    const [showMaterial, setShowMaterial] = useState(true);
    // const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const startWatchParty = async () => {
            try {
                // setErrorMessage("");
                setIsLoading(true);
                const response = await hubServices.startGroupWatch(invitationId);
                if (response && response.statusCode === 200) {
                    setInvitationDetails(response.data || []);
                }
            } catch (error) {
                console.error("API Error:", error);
                // setErrorMessage("An error occurred while fetching data.");
            } finally {
                setIsLoading(false);
            }
        };
        startWatchParty();
    }, [invitationId, setIsLoading]);

    const toggleShowDescription = () => setShowDescription(!showDescription);
    const toggleShowMaterial = () => setShowMaterial(!showMaterial);

    const renderArrowPrev = (onClickHandler, hasPrev, label) => (
        hasPrev && (
            <button type="button" onClick={onClickHandler} title={label} className={`${partyStyles.arrow} ${partyStyles.arrowLeft}`}>
                &#9664;
            </button>
        )
    );

    const renderArrowNext = (onClickHandler, hasNext, label) => (
        hasNext && (
            <button type="button" onClick={onClickHandler} title={label} className={`${partyStyles.arrow} ${partyStyles.arrowRight}`}>
                &#9654;
            </button>
        )
    );

    const renderContent = (item, index) => {
        
        if (!item.contentUrl) {
            return (
                <img
                    src="/Content/Images/General/no_thumbnail.png"
                    alt="No Data Found"
                    style={{ border: '2px solid #F2F2F2' }}
                    className={partyStyles.inviteItemReactplayer}
                />
            );
        }

        if (item.contentUrl.includes('.png') || item.contentUrl.includes('.jpg') || item.contentUrl.includes('.jpeg') || item.contentUrl.includes('.gif')) {
          return (
            <img
              src={item.contentUrl}
              alt={`Feed ${index}`}
              className={partyStyles.inviteItemReactplayer}
            />
          );
        } else {
          return (
            <div>
              <ReactPlayer
                className={partyStyles.inviteItemReactplayer}
                url={item.contentUrl}
                controls={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload', // Disable download option
                    },
                  },
                }}
              />
              {/* Inline style to hide carousel-status class */}
              <style>
                {`
                  .carousel-status {
                    display: none !important;
                  }
                `}
              </style>
            </div>
          );
        }
      };


    
    return (
        <Container className={`MainpartSetup ${partyStyles.startPartyContainer}`}>
            <Row className="boxBody">
                <div>
                    {invitationDetails && Object.keys(invitationDetails).length > 0 ? (
                        <div key={invitationDetails.invitationId} className={partyStyles.inviteItemContentDiv}>
                                    <div className={partyStyles.inviteItemProfileDiv}>
                                        <div className={partyStyles.inviteItemProfileLeft}>
                                            <div>
                                                <Link to={`/seller/public-profile/${invitationDetails?.sellerId}`}>
                                                    <img
                                                        src={invitationDetails?.sellerProfileImg || "/Content/Images/empty_user.jpg"}
                                                        alt="workshop_user"
                                                        className={partyStyles.inviteItemProfileImg}
                                                    />
                                                </Link>
                                            </div>
                                            <div>
                                                <div className={partyStyles.inviteItemSellername}>{invitationDetails.sellerName}</div>
                                                <div className={partyStyles.inviteItemSellerDesc}>{invitationDetails.sellerBio}</div>
                                            </div>
                                        </div>
                                        <div className={partyStyles.inviteItemProfileRight}>
                                            <Link to={`/watch-party/live/${invitationId}`}>
                                                <button className={partyStyles.inviteItemWatchPartyBtn}>Watch Party Live</button>
                                            </Link>
                                        </div>
                                    </div>

                                    <Row className={partyStyles.inviteItemTitleFlex}>
                                        <div className={partyStyles.inviteItemTitle}>{invitationDetails?.caption}</div>
                                    </Row>

                                    <div className={partyStyles.inviteItemVideoContent}>
                                        {invitationDetails.content.length > 1 ? (
                                        <Carousel
                                            showThumbs={false}
                                            showIndicators={false}
                                            renderArrowPrev={renderArrowPrev}
                                            renderArrowNext={renderArrowNext}
                                            className={partyStyles.customCarousel}
                                        >
                                            {invitationDetails.content.map((item, index) => (
                                            <div key={index} className={partyStyles.carouselItem}>
                                                {renderContent(item, index)}
                                            </div>
                                            ))}
                                        </Carousel>
                                        ) : (
                                        renderContent(invitationDetails.content[0], 0)
                                        )}
                                    </div>

                                    <div className={partyStyles.inviteItemDescContent}>
                                        <div className={partyStyles.toggleDropdowndiv}>
                                            <div className={partyStyles.inviteItemDescTitle}>Description</div>
                                            <Button onClick={toggleShowDescription} className={partyStyles.toggleDropdownClick}>
                                                <FaAngleDown />
                                            </Button>
                                        </div>
                                        <Toast show={showDescription} onClose={toggleShowDescription} className={partyStyles.toggleToastpartyStyles}>
                                            <div className={partyStyles.inviteItemDescText}>{invitationDetails?.desc}</div>
                                        </Toast>
                                    </div>

                                    <div className={partyStyles.inviteItemExtraMaterialContent}>
                                        <div className={partyStyles.toggleDropdowndiv}>
                                            <div className={partyStyles.inviteItemDescTitle}>Material required for Workshop</div>
                                            <Button onClick={toggleShowMaterial} className={partyStyles.toggleDropdownClick}>
                                                <FaAngleDown />
                                            </Button>
                                        </div>
                                        <Toast show={showMaterial} onClose={toggleShowMaterial} className={partyStyles.toggleToastpartyStyles}>
                                            <div className={partyStyles.inviteItemDescText}>{invitationDetails.extraInfo}</div>
                                        </Toast>
                                    </div>
                        </div>
                    ) : (
                        <div className={partyStyles.inviteItemProductData}>No invitation/Product details found.</div>
                    )}
                </div>
            </Row>
        </Container>
    );
};

export default StartWatchParty;
